var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "candidate-email-share",
      attrs: {
        width: "540px",
        title: "推荐给他人",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          ref: "candidateemailsharetemplate",
          attrs: { id: "candidate-email-share-template" },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.emailLoading,
                  expression: "emailLoading",
                },
              ],
              staticClass: "candidate-email-share",
            },
            [
              _c("p", [
                _vm._v("您可以把"),
                _c(
                  "a",
                  { attrs: { href: _vm.previewLinkUrl, target: "_blank" } },
                  [
                    _c("span", {
                      staticClass: "resume-real-name",
                      domProps: { textContent: _vm._s(_vm.realName) },
                    }),
                  ]
                ),
                _vm._v("的简历以页面形式推荐给hr"),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "col-sm-2" }, [
                  _c("label", { attrs: { for: "emails" } }, [
                    _vm._v("邮箱地址"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-sm-10 text-area" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "email-select",
                        attrs: {
                          multiple: "",
                          filterable: "",
                          "allow-create": "",
                          "default-first-option": "",
                          placeholder: "多个邮箱以；隔开",
                        },
                        model: {
                          value: _vm.emailArr,
                          callback: function ($$v) {
                            _vm.emailArr = $$v
                          },
                          expression: "emailArr",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: item },
                        })
                      }),
                      1
                    ),
                    _c(
                      "span",
                      {
                        ref: "emailError",
                        staticClass: "text-danger",
                        attrs: { id: "emailError" },
                      },
                      [_vm._v(_vm._s(_vm.emailErrorMsg))]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "col-sm-2" }, [
                  _c("label", { attrs: { for: "remark" } }, [
                    _vm._v("推荐理由"),
                  ]),
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.remark,
                        expression: "remark",
                      },
                    ],
                    ref: "textarearemark",
                    staticClass: "form-control",
                    attrs: {
                      name: "remark",
                      placeholder: "你希望他给您反馈吗？留个言说点什么吧",
                      rows: "5",
                    },
                    domProps: { value: _vm.remark },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.remark = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "span",
                    {
                      ref: "remarkError",
                      staticClass: "text-danger",
                      attrs: { id: "remarkError" },
                    },
                    [_vm._v(_vm._s(_vm.remarkErrorMsg))]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.hide } }, [_vm._v("取消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }