var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "candidate-communication-list",
    },
    [
      _vm.list.length == 0 && !_vm.loading
        ? _c("div", { staticClass: "empty-data" }, [
            _c("span", { staticClass: "empty-img" }),
            _c("p", [_vm._v("暂无数据")]),
          ])
        : _vm._e(),
      _vm.list.length > 0
        ? _c(
            "div",
            { staticClass: "communication-list" },
            _vm._l(_vm.list, function (item) {
              return _c(
                "div",
                { key: item.communicationId, staticClass: "recommend-hd" },
                [
                  _c("div", { staticClass: "col-3 logo-container" }, [
                    _c("div", { staticClass: "logo" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "logo-hd",
                            { "img-loaded": item.employerLogoUrl },
                          ],
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.employerLogoUrl,
                                expression: "item.employerLogoUrl",
                              },
                            ],
                            staticClass: "logo-img",
                            attrs: {
                              alt: "职位",
                              src: _vm._f("logo")(item.employerLogoUrl),
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-7" }, [
                    _c("div", { staticClass: "job-title" }, [
                      _c("h4", { staticClass: "recommend-job" }, [
                        _c("span", { staticClass: "company" }, [
                          _vm._v(_vm._s(_vm._f("square")(item.firmName))),
                        ]),
                        _c("a", {
                          class: [{ tdn: item.isFromCloud }, "job-name-color"],
                          attrs: {
                            href: "/#/Job/" + item.jobId,
                            target: "_blank",
                          },
                          domProps: { textContent: _vm._s(item.jobName) },
                        }),
                        _c("span", { staticClass: "city" }, [
                          _vm._v(_vm._s(_vm._f("square")(item.location))),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "job-info" }, [
                      _c(
                        "span",
                        { staticClass: "salary" },
                        [
                          item.salary == "0K-0K"
                            ? [
                                _vm._v(
                                  "\n                            月薪：面议\n                        "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("tpl")(item.salary, "月薪：%p")
                                    ) +
                                    "\n                        "
                                ),
                              ],
                        ],
                        2
                      ),
                      _vm._v(
                        "\n                    佣金：\n                    "
                      ),
                      _c(
                        "span",
                        { staticClass: "commission" },
                        [
                          item.salary == "0K-0K"
                            ? [
                                _vm._v(
                                  "\n                            按比例 " +
                                    _vm._s(
                                      _vm.filterCommisionValue(
                                        item.commisionValue
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.commisionValue) +
                                    "\n                        "
                                ),
                              ],
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "job-user" },
                      [
                        _c("avatar", {
                          staticClass: "user-avatar",
                          attrs: {
                            src: _vm._f("avatar")(item.headhunterAvatarUrl),
                            userId: item.headhunterId,
                            enableCard: true,
                            enableLink: true,
                          },
                        }),
                        _c("span", { staticClass: "date" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("tpl")(
                                _vm._f("datetime")(item.createDate),
                                "%p 添加"
                              )
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-2" }, [
                    _c("div", { staticClass: "job-status" }, [
                      item.communicationStatus == "Unprocessed"
                        ? _c("div", { staticClass: "job-unprocessed" }, [
                            _vm._v("未处理"),
                          ])
                        : _vm._e(),
                      item.communicationStatus == "Removed"
                        ? _c("div", { staticClass: "job-removed" }, [
                            _vm._v("已移出"),
                          ])
                        : _vm._e(),
                      item.communicationStatus == "Recommended"
                        ? _c("div", { staticClass: "job-recommended" }, [
                            _vm._v("已推荐"),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  item.communicationStatus == "Unprocessed" &&
                  item.headhunterId == _vm.userId
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-3 pull-right communication-operate-list",
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: item.removeLoading,
                                  expression: "item.removeLoading",
                                },
                              ],
                              staticClass: "btn-clear-out",
                              on: {
                                click: function ($event) {
                                  return _vm.onConmunicationJobClear(
                                    item.communicationId,
                                    item
                                  )
                                },
                              },
                            },
                            [_vm._v("移出")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: item.recommendLoading,
                                  expression: "item.recommendLoading",
                                },
                              ],
                              staticClass: "btn-recommend",
                              on: {
                                click: function ($event) {
                                  return _vm.onConmunicationJobRecommend(
                                    item.jobId,
                                    item
                                  )
                                },
                              },
                            },
                            [_vm._v("推荐")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.list.length > 0 && _vm.communicationCount > _vm.list.length
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.bottomLoading,
                  expression: "bottomLoading",
                },
              ],
              staticClass: "load-more",
              on: { click: _vm.handleLoadMore },
            },
            [
              _vm._v("\n        加载更多\n        "),
              _c("font-icon", { attrs: { href: "#icon-spread-up" } }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }