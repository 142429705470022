var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "recommendation-status-tag",
      attrs: { href: _vm.href, target: "_blank" },
    },
    [_c("el-tag", { attrs: { type: _vm.theme } }, [_vm._v(_vm._s(_vm.text))])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }