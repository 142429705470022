<template>
    <a :href="href" target="_blank" class="recommendation-status-tag">
        <el-tag 
            :type="theme"
        >{{text}}</el-tag>
    </a>
</template>

<script>
    import recommendationStatus from '#/js/config/recommendation.json';
    import invoiceSubFlowStatus from '#/js/config/invoiceSubFlowStatus.js';
    // 根据状态码或者标签英文名查找状态配置
    function findRecommendationStatus (label) {
        if (typeof label == 'number') {
            let target = null;
            Object.keys(recommendationStatus).forEach(key => {
                if(recommendationStatus[key].code === label) {
                    target = recommendationStatus[key];
                }
            })
            return target;
        } else {
            return JSON.parse(JSON.stringify(recommendationStatus[label.toLowerCase()]));
        }
    }

    export default {
        name: "recommendDationStatusTag",
        data() {
            return {
            }
        },
        computed: {
            status() {
                //  [Display(Name = "发票开票中")]
                // InvoiceCreated = 24,
                // [Display(Name = "发票已回款")]
                // FundReceived = 26,
                if(['InvoiceCreated', 'FundReceived'].includes(this.label)) {
                    return {
                        "text": invoiceSubFlowStatus[this.flowStepName],
                        "theme": "info"
                    };
                }
                const currentStatus = findRecommendationStatus(this.label) || {};
                if(this.label == 'Interview' && this.interviewConfirmedStatus != 1 && this.interviewConfirmedStatus != 2) {
                    currentStatus.attachment = '待确认';
                }
                return currentStatus;
            },
            text() {
                return this.status ? this.status.text + (this.status.attachment || '') : '';
            },
            theme() {
                return this.status.theme || '';
            }
        },
        props: {
            href: {
                type:String,
                default: 'javascript:void(0);'
            },
            label: String | Number,
            interviewConfirmedStatus: Number,
            flowStepName: {
                type: String,
                default: '',
            }
        }
    }
</script>

<style scoped lang="scss">
.recommendation-status-tag{
    .el-tag {
        min-width: 110px;
        padding: 4px 4px;
        line-height: 24px;
        border: none;
        box-sizing: border-box;
        text-align: center;
        background-color: #F8F8F8;
        color: #B9B9B9;
    }
    .el-tag--info{
        border-color: #3399ff;;
        background-color: #EFF7FD;
        color: #62A8EA;
    }
    .el-tag--success{
        border-color: $primary;
        background-color: #EDF9F6;
        color: #38BC9C;
    }
    .el-tag--warning{
        border-color: #ff7200;
        background-color: #FEF6EF;
        color: #F2A654;
    }
    .el-tag--primary{
        border-color: $primary;
    }
}

</style>
