<template>
    <div class="candidateDetail candidate-detail-vue">
        <div class="site-main site-main-init">
            <div class="candidate-wrap ko-before candidate-then">
                <candidateBaseInfoStore
                    ref="candidateBaseInfoStore"
                    @get-candidate-detail="getCandidateDetail"
                    @get-exist-id="getExistId"
                    @handel-tab-refresh="handelTabRefresh"
                    @import-confirm="handleImportConfirm"
                    @candidate-unlock="handleCandidateUnlock"
                    @candidate-detail-refresh="refreshdetail"
                    @candidate-new-city-expectations-refresh="getNewCityExpectations"
                    @operationEvent-update-success='operationEventUpdateSuccess'
                    @candidate-search-record-refresh='RecordListRefresh'
                    :newCityExpectationsInfo="newCityExpectationsInfo"
                    :candidateDetailData="candidateDetailData"
                    :candidate-id="candidateDetailData.id"
                    :is-firm-resume="candidateDetailData.isFirm"
                    :resume-id="candidateDetailData.tbdResumeId"
                    :completion-percent="completionPercent"
                    :existed-resume-id="existedResumeId"
                    :shareData="shareData">
                </candidateBaseInfoStore>
                <div class="candidate-content">
                    <!-- <div class="pull-left"> -->
                    <div class="candidate-detail-left">
                        <div class="type-tab" :class="{'has-unprocessed-item' : hasUnprocessedItem && candidateDetailData.isFirm}">
                            <div class="tab visible">
                                <ul class="selection-list">
                                    <li class="selection-item visible" 
                                        v-for="item in candidateDetailData.isFirm ? nodeList : personalNodeList" 
                                        v-show="!candidateDetailData.isFromAuthorization || (candidateDetailData.isFromAuthorization && item.value != 4 && item.value != 5)"
                                        :key="item.value" 
                                        @click="routernavigate(item)" 
                                        :class="{'active':item.value == activeValue}"
                                    >
                                        <div class="tab-wrapper">
                                            <span>{{item.text}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <candidate-resume
                            ref="candidateResume"
                            :activeValue="activeValue"
                            v-show="activeValue == 1 || activeValue == 4"
                            @candidate-detail-refresh="refreshdetail"
                            id="candidate-resume"
                            :shareData="shareData"
                            :candidateDetailData="candidateDetailData"
                            :candidate-id="candidateDetailData.id"
                            :is-firm="candidateDetailData.isFirm"
                            :resume-id="candidateDetailData.tbdResumeId"
                        >
                        </candidate-resume>

                        <candidate-communicaiton-list
                            ref="candidateCommunicationList"
                            id="candidate-communicaiton-list"
                            :candidate-id="candidateDetailData.id"
                            v-show="activeValue == 2 && candidateDetailData.isFirm"
                        >
                        </candidate-communicaiton-list>

                        <candidate-recommendation-info
                            ref="candidateRecommendationInfo"
                            id="candidate-recommendation-info"
                            @candidate-search-record-refresh='RecordListRefresh'
                            :candidate-id="candidateDetailData.id"
                            v-show="activeValue == 3 && candidateDetailData.isFirm"
                            @router-navigate="routernavigate"
                            :resume-id="candidateDetailData.tbdResumeId"
                            :shareData="shareData"
                        >
                        </candidate-recommendation-info>

                        <candidate-timeline
                            v-show="activeValue == 5"
                            ref="candidateTimeline"
                            :candidate-real-name="candidateDetailData && candidateDetailData.realName"
                            id="candidate-timeline" :candidate-id="candidateDetailData.id"
                            :isFirm="candidateDetailData.isFirm"
                        >
                        </candidate-timeline>

                        <candidate-attachments
                            v-show="activeValue == 6"
                            ref="attachments"
                            :candidate-id="candidateDetailData.id"
                            :resume-id="candidateDetailData.tbdResumeId"
                        >
                        </candidate-attachments>

                    </div>
                    <div class="candidate-detail-right">
                        
                        <candidate-search-record
                            ref="candidateSearchRecord" 
                            id="candidate-search-record"
                            @inquire-edit="hadleInquireEdit"
                            :candidate-id="candidateDetailData.id"
                            :isFirm="candidateDetailData.isFirm"
                            :shareData="shareData"
                        ></candidate-search-record>
                        <!-- <candidate-recommend
                            v-if="isCFuser"
                            ref="candidateRecommend"
                            :candidate-id="candidateDetailData.id"
                            v-show="candidateDetailData.isFirm"
                        >
                        </candidate-recommend> -->
                    </div>
                </div>
            </div>
        </div>

        <el-button
            class="to-top-button"
            @click="pageToTop"
            v-show="isShowToTopButton"
        >
            <font-icon class="to-top-icon" href="#icon-toTop"></font-icon>
        </el-button>
        <el-dialog
            title="提示"
            :visible.sync="aliDialogVisible"
            width="420px"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <p class="ali-resume-dialog-text">
                该简历的当前公司可能是A厂，系统已删除。<br />如有疑问请联系内控
                <a href="mailto:neikong@careerfocus.com.cn">neikong@careerfocus.com.cn</a>。
            </p>
        </el-dialog>

        <footer-container></footer-container>
    </div>
</template>

<script>
    import FooterContainer from '@/hr/component/footer.vue';
    import CandidateBaseInfoStore from './layout/candidate-base-info-store/index.vue';
    import CandidateSearchRecord from './layout/candidate-search-record/index.vue';
    // import CandidateRecommend from './component/candidateRecommend';
    import CandidateAttachments from './component/candidateAttachments.vue';

    import CandidateResume from './layout/candidateResume.vue'; // Done
    import CandidateRecommendationInfo from './layout/candidateRecommendationInfo.vue';
    import CandidateTimeline from './component/candidate-timeline';
    import CandidateCommunicaitonList from './layout/candidateCommunicaitonList.vue'; 
    
    import { candidate as candidateDetailConfig } from '#/js/config/api.json';
    import talentPoolService from '#/js/service/talentPoolService.js';
    // import _candidateArticle from '@/component/page/candidate-article';
    // let CandidateArticle = _candidateArticle.constructor;
    import { mapActions, mapGetters } from 'vuex';

    export default {
        componentName: "candidateDetail",
        components: {
            FooterContainer,
            CandidateBaseInfoStore,
            CandidateResume,
            CandidateCommunicaitonList,
            CandidateRecommendationInfo,
            CandidateTimeline,
            CandidateSearchRecord,
            // CandidateRecommend,
            CandidateAttachments
        },
        computed:{
            isAdministrator() {
                return this.$store.state.user.userInfo.isAdministrator || false;
            },
            isBusinessPermission() {
                return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('Talent');
            },
            isCandidateDetailPermission() {
                return this.isAdministrator || this.isBusinessPermission;
            },
            isCFuser(){
                return this.$store.state.user.userInfo.isCFUser;
            },
            shareData() {
                let candidateDetail = this.candidateDetailData
                return {
                    resumeId: candidateDetail.tbdResumeId,
                    candidateName: candidateDetail.realName,
                    title: candidateDetail.title,
                    company: candidateDetail.company,
                    cityName: candidateDetail.cityName,
                    yearOfExperience: candidateDetail.yearOfExperience,
                    degree: candidateDetail.degree,
                    school: candidateDetail.school
                }
            }
        },
        data() {
            return {
                nodeList: [
                    {
                        text: '简历',
                        url: '#/candidateDetail/'+this.$route.params.resumeId,
                        value: 1
                    },
                    {
                        text: '待沟通',
                        url: '#/candidateDetail/'+this.$route.params.resumeId+'/communicationList',
                        value: 2
                    },
                    {
                        text: '推荐',
                        url: '#/candidateDetail/'+this.$route.params.resumeId+'/recommendationList',
                        value: 3
                    },
                    {
                        text: '讨论',
                        url: '#/candidateDetail/'+this.$route.params.resumeId+'/comments',
                        value: 4
                    },
                    {
                        text: '日志',
                        url: '#/candidateDetail/'+this.$route.params.resumeId+'/timeline',
                        value: 5
                    },
                    {
                        text: '附件',
                        url: '#/candidateDetail/'+this.$route.params.resumeId+'/attachments',
                        value: 6
                    }
                ],
                personalNodeList:[
                    {
                        text: '简历',
                        url: '#/candidateDetail/'+this.$route.params.resumeId,
                        value: 1
                    },
                    {
                        text: '日志',
                        url: '#/candidateDetail/'+this.$route.params.resumeId+'/timeline',
                        value: 5
                    },
                    {
                        text: '附件',
                        url: '#/candidateDetail/'+this.$route.params.resumeId+'/attachments',
                        value: 6
                    }
                ],
                candidateDetailData: {},
                originCandidateDetailData: {}, //未做高亮处理的简历详情数据
                candidateArticle: null,
                currentPath: this.$route.path,
                communicationDoll: false,
                hasUnprocessedItem: false,
                firstLoad: true,
                activeValue: 1,
                addCompanyLoading: false,
                addTrackingListLoading: false,
                existedCandidateId: "",
                existedResumeId: "",
                completionPercent: '',
                isShowToTopButton: false,
                aliDialogVisible: false,

                newCityExpectationsInfo: {},
            }
        },
        watch: {
            
        },
        mounted() {
            if(this.$store.state.user.userInfo.isAdministrator!=undefined && !this.isCandidateDetailPermission){
                // console.log('test---------------');
                shortTips("权限不足，请联系管理员")
                setTimeout(() => {
                    location.href = "/Headhunting/#/";
                }, 2000)
                return;
            }

            $('.ko-before').not('.ko-before-not-in').removeClass('ko-before');

            $("#modalRed .modal-close").click(function (e) {
                $("#modalRed").removeClass("active");
                $("#modalRed").hide();
            })

            let self = this;
            let resumeId = this.$route.params.resumeId;
            let rootURL = '/candidateDetail/'+resumeId
            let recommendationid = this.$route.params.recommendationid;
            this.candidateDetail = this.$refs.candidateResume;
            this.candidateSearchRecord = this.$refs.candidateSearchRecord;
            this.candidateDetailComments = this.candidateDetail.$refs.comments;
            this.candidateResumeDetail = this.candidateDetail.candidateResumeDetail;
            this.candidateRecommendationInfo = this.$refs.candidateRecommendationInfo;
            this.candidateTimeline = this.$refs.candidateTimeline;
            this.candidateBaseInfoStore = this.$refs.candidateBaseInfoStore;
            this.candidateCommunicationList = this.$refs.candidateCommunicationList;
            this.attachments = this.$refs.attachments;
            this.getCandidateDetail().then((res)=>{
                this.candidateSearchRecord.initExtraMethod();
                this.recordView();
            });
            
            window.addEventListener('hashchange',function(){
                if(window.location.hash=='#'+self.currentPath){
                    self.toggleNavigator(self.currentPath,rootURL,recommendationid);
                }
            });
            window.addEventListener('scroll', function() {
                if(document.documentElement.scrollTop >= (window.innerHeight / 2)) {
                    self.isShowToTopButton = true;
                } else {
                    self.isShowToTopButton = false;
                }
            })

            this.$on('refreshCandidateInquiry', this.RecordListRefresh);
        },
        beforeRouteEnter(to, from, next) {
            let currentURL = to.path;
            let resumeId = to.params.resumeId;
            let rootURL = '/candidateDetail/'+resumeId;
            let recommendationid = to.params.recommendationid;
            next(vm => {
                vm.toggleNavigator(currentURL,rootURL,recommendationid);
            })

        },
        beforeRouteUpdate (to, from, next) {
            let currentURL = to.path;
            let resumeId = to.params.resumeId;
            let rootURL = '/candidateDetail/'+resumeId;
            let recommendationid = to.params.recommendationid;
            this.toggleNavigator(currentURL,rootURL,recommendationid);
        },
        methods:{
            firstLoadInit(data){
                this.getUnprocessedNumber();
                this.getLockStatusAsync(data.id);
                // if(this.isCFuser) {
                //     this.$refs.candidateRecommend.init(data.id);
                // }
                this.firstLoad = false;
            },
            beforeRoute(currentURL,rootURL,recommendationid){
                switch(currentURL){
                    case rootURL:
                        break;
                    case rootURL+'/communicationList':
                        this.candidateCommunicationList.init(this.candidateDetailData.id)
                        break;
                    case rootURL+'/recommendationList':
                        // this.candidateRecommendationInfo.viewType = 'list';
                        this.candidateRecommendationInfo.$refs.candidateRecommendationRecord.recordInit(this.candidateDetailData.id);
                        break;
                    case rootURL+'/comments':
                        this.candidateDetailComments.refresh(this.candidateDetailData.id);
                        break;
                    case rootURL+'/timeline':
                        this.candidateTimeline.moreBtn = false;
                        if(this.candidateTimeline.items.length){
                            this.candidateTimeline.refresh()
                        }else{
                            this.candidateTimeline.init(this.candidateDetailData.tbdResumeId);
                        }
                        break;
                    case rootURL+'/recommendation/'+recommendationid:
                        // this.candidateRecommendationInfo.viewType = 'detail';
                        this.candidateRecommendationInfo.refresh(recommendationid);
                        break;
                    case rootURL+'/attachments':
                        this.attachments.$refs.attachmentList.init(this.candidateDetailData.tbdResumeId);
                        break
                };
            },
            toggleNavigator(currentURL,rootURL,recommendationid) {
                if(!this.firstLoad) {
                    this.routertitle();
                }
                switch(currentURL){
                    case rootURL:
                        this.activeValue = 1;
                        this.updateServer(false);
                        break;
                    case rootURL+'/communicationList':
                        this.activeValue = 2;
                        this.updateServer(true);
                        break;
                    case rootURL+'/recommendationList':
                        this.activeValue = 3;
                        this.$nextTick(() => {
                            this.candidateRecommendationInfo && this.candidateRecommendationInfo.toggleState('list');    
                        });
                        this.updateServer(true);
                        break;
                    case rootURL+'/comments':
                        this.activeValue = 4;
                        this.updateServer(true);
                        break;
                    case rootURL+'/timeline':
                        this.activeValue = 5;
                        this.updateServer(true);
                        break;
                    case rootURL+'/recommendation/'+recommendationid:
                        this.activeValue = 3;
                        this.$nextTick(() => {
                            this.candidateRecommendationInfo && this.candidateRecommendationInfo.toggleState('detail');
                        });
                        this.updateServer(true);
                        break;
                    case rootURL+'/attachments':
                        this.activeValue = 6;
                        this.updateServer(true);
                        break;
                };
            },
            routernavigate(vm){
                let resumeId = this.$route.params.resumeId;
                let rootURL = '#/candidateDetail/'+resumeId
                let recommendationid = this.$route.params.recommendationid 
                if(vm.recommendationid){
                    recommendationid = vm.recommendationid
                }
                location.href = vm.url;
                this.beforeRoute(vm.url,rootURL,recommendationid);
            },
            getNewCityExpectations(resumeId) {
                let parmas = {
                    resumeId: resumeId
                };
                _request({
                    url: '/openapi/Candidate/GetNewCityExpectations',
                    method: 'POST',
                    data: parmas,
                    baseURL: "LbdOpenApi",
                }).then(res => {
                    console.log(res);
                    this.newCityExpectationsInfo = res;
                }).catch( err => {
                    console.log(err);
                });
            },
            getCandidateDetail() {
                return new Promise((resolve,reject)=>{
                    // let url = placeholder(candidateDetailConfig.candidate_detail,this.$route.params.resumeId);
                    const url = candidateDetailConfig.candidate_detail.replace(/%p/, this.$route.params.resumeId);
                    this.candidateBaseInfoStore.detailLoading = true;
                    this.candidateResumeDetail.loading = true;
                    let resumeId = this.$route.params.resumeId;
                    let rootURL = '/candidateDetail/'+resumeId
                    let recommendationid = this.$route.params.recommendationid;
                    console.log(url)
                    _request({
                        url: url,
                        method: "GET",
                        throwBusinessError: true
                    }).then(res => {
                        console.log(res)
                        if(res.isFromCloud){
                            location.href='#/cloudCandidate/'+res.tbdResumeId;
                            return
                        }
                        this.originCandidateDetailData = JSON.parse(JSON.stringify(res));

                        let keywords = _getQueryString_candidateDetail('keywords'),
                            keywordReg = "";
                            console.log(keywords)
                        if(keywords) {
                            let keywordList = keywords.split(",");
                            keywordList.forEach(item => {
                                keywordReg = item.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
                                keywordReg = new RegExp(keywordReg, 'ig');
                                if(res.content) {
                                    res.content = res.content.replace(keywordReg, `<span class="text-warning">${item}</span>`);
                                }
                                if(res.educationExperiences && res.educationExperiences.length > 0) {
                                    for(let i = 0; i < res.educationExperiences.length; i++) {
                                        res.educationExperiences[i].major = res.educationExperiences[i].major.replace(keywordReg, `<span class="text-warning">${item}</span>`);
                                        res.educationExperiences[i].school = res.educationExperiences[i].school.replace(keywordReg, `<span class="text-warning">${item}</span>`);
                                    }
                                }
                                if(res.intentions && res.intentions.length > 0) {
                                    for(let i = 0; i < res.intentions.length; i++) {
                                        res.intentions[i].title = res.intentions[i].title.replace(keywordReg, `<span class="text-warning">${item}</span>`);
                                    }
                                }
                                if(res.projectExperiences && res.projectExperiences.length > 0) {
                                    for(let i = 0; i < res.projectExperiences.length; i++) {
                                        res.projectExperiences[i].projectName = res.projectExperiences[i].projectName.replace(keywordReg, `<span class="text-warning">${item}</span>`);
                                        res.projectExperiences[i].projectRole = res.projectExperiences[i].projectRole ? res.projectExperiences[i].projectRole.replace(keywordReg, `<span class="text-warning">${item}</span>`) : "";
                                        res.projectExperiences[i].description = res.projectExperiences[i].description ? res.projectExperiences[i].description.replace(keywordReg, `<span class="text-warning">${item}</span>`) : "";
                                        res.projectExperiences[i].responsibility = res.projectExperiences[i].responsibility ? res.projectExperiences[i].responsibility.replace(keywordReg, `<span class="text-warning">${item}</span>`) : "";
                                    }
                                }
                                if(res.workExperiences && res.workExperiences.length > 0) {
                                    for(let i = 0; i < res.workExperiences.length; i++) {
                                        res.workExperiences[i].title = res.workExperiences[i].title.replace(keywordReg, `<span class="text-warning">${item}</span>`);
                                        res.workExperiences[i].company = res.workExperiences[i].company.replace(keywordReg, `<span class="text-warning">${item}</span>`);
                                        res.workExperiences[i].description = res.workExperiences[i].description ? res.workExperiences[i].description.replace(keywordReg, `<span class="text-warning">${item}</span>`) : "";
                                    }
                                }
                                if(res.selfEvaluation) {
                                    res.selfEvaluation = res.selfEvaluation.replace(keywordReg, `<span class="text-warning">${item}</span>`);
                                }
                            });
                        }
                        this.candidateDetailData = res;
                        let _setcandidatedetail={
                            isFromAuthorization:this.candidateDetailData.isFromAuthorization,
                            candidateFirmId:this.candidateDetailData.firmId,
                            tbdResumeId:this.candidateDetailData.tbdResumeId,
                            isBindWechat:this.candidateDetailData.isBindWechat,
                            isFromShare:this.candidateDetailData.isFromShare,
                            proportion:this.candidateDetailData.proportion || 0,
                            sourceFirmName:this.candidateDetailData.authorizationSourceFirmName || '',
                        }
                        this.$store.dispatch('setCandidateDetail', _setcandidatedetail);
                        this.candidateDetailTransfer(this.candidateDetailData, this.originCandidateDetailData);
                        // localStorage.setItem('cadidateDetailData', JSON.stringify(res));
                        if(this.firstLoad){
                            this.beforeRoute(this.currentPath,rootURL,recommendationid);
                            // this.candidateResumeDetail.$refs.attachmentList.init(this.candidateDetailData.tbdResumeId);
                            this.setCompletionPercent(this.candidateDetailData.tbdResumeId);
                            this.routertitle();
                            if(this.candidateDetailData.isFirm){
                                this.nodeList[1].text =`待沟通(${this.candidateDetailData.communicationCount})`;
                                this.nodeList[2].text =`推荐(${this.candidateDetailData.recommendationCount})`;
                                this.firstLoadInit(this.candidateDetailData);
                            }
                        }
                        if(!res.isFirm && (res.resumeGrabType == 2 || res.resumeGrabType == 0)) {
                            this.getExistId(() => {});
                        }

                        if(res.isFirm){
                            this.getNewCityExpectations(res.tbdResumeId);
                        }

                        resolve(res);
                    }).catch(err => {
                        if(err.message == 'ResumeHidden') {
                            this.aliDialogVisible = true;
                        }else {
                            shortTips(err.message || '系统异常，请稍后重试');
                        }
                        console.log(err);
                        this.candidateResumeDetail.loading = false;
                        reject(err);
                    }).finally(()=>{
                        this.candidateBaseInfoStore.detailLoading = false;
                        this.candidateResumeDetail.loading = false;
                    });
                })

            },
            routertitle(){
                let firmName = this.$store.state.user.userInfo.firmShortName;
                let title = this.candidateDetailData.realName + '_' + firmName + '_' + '猎必得_最有效的O2O猎头招聘平台';
                document.title = title;
            },
            candidateDetailTransfer(data, originData){
                this.candidateBaseInfoStore.handleOptXhrValue(data);
                this.candidateDetail.updateArticle(data, originData);
                // this.candidateArticle && this.candidateArticle.updateContent(data.content);
            },
            refreshdetail(updateTimeLine){
                this.getCandidateDetail();
                if(updateTimeLine) {
                    this.candidateTimeline.$refs.timelineList.refresh();
                }
            },
            setRecommenderEnv() {
                // let url=placeholder(candidateDetailConfig.url_communication_recommend_total, this.candidateDetailData.id);
                const url = candidateDetailConfig.url_communication_recommend_total.replace(/%p/, this.candidateDetailData.id);
                _request({
                    url: url,
                    method: "GET"
                }).then(res => {
                    this.nodeList[1].text =`待沟通(${res.communicationCount})`;
                    this.nodeList[2].text =`推荐(${res.recommendationCount})`;
                }).catch(err => {
                    console.log(err);
                });
            },
            getUnprocessedNumber(){
                const url = candidateDetailConfig.url_communication_unprocessed_number.replace(/%p/, this.candidateDetailData.id);
                _request({
                    url: url,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then((res) => {
                    if(res>0){
                        this.hasUnprocessedItem = true;
                    }else{
                        this.hasUnprocessedItem = false;
                    }
                }).catch((err) => {
                    console.log(err);
                })
            },
            updateServer(canSetCloud) {
                this.$store.dispatch('setcanSetCloud', canSetCloud);
            },
            handelTabRefresh(){
                this.setRecommenderEnv();
                this.candidateCommunicationList.refresh();
            },
            hadleInquireEdit(val){
                val.resumeId = this.candidateDetailData.tbdResumeId;
                this.$refs.candidateBaseInfoStore.$refs.inquiryDialog.edit(val);
            },
            RecordListRefresh(){
                this.candidateSearchRecord.refresh();
            },
            operationEventUpdateSuccess(vm, data){
                switch (vm) {
                    case 'Wechat':
                        this.candidateDetailData.wechat = data.contact;
                        this.candidateDetailData.wechatStatus = data.isValid ? 3 : 4;
                        this.originCandidateDetailData.wechat = data.contact;
                        this.originCandidateDetailData.wechatStatus = data.isValid ? 3 : 4;
                        this.candidateDetailTransfer(this.candidateDetailData, this.originCandidateDetailData);
                        break;
                    case 'Email':
                        this.candidateDetailData.email = data.contact;
                        this.candidateDetailData.emailStatus = data.isValid ? 3 : 4;
                        this.originCandidateDetailData.email = data.contact;
                        this.originCandidateDetailData.emailStatus = data.isValid ? 3 : 4;
                        this.candidateDetailTransfer(this.candidateDetailData, this.originCandidateDetailData);
                        break;
                    case 'Mobile':
                        this.candidateDetailData.mobile = data.contact;
                        this.candidateDetailData.mobileStatus = data.isValid ? 3 : 4;
                        this.originCandidateDetailData.mobile = data.contact;
                        this.originCandidateDetailData.mobileStatus = data.isValid ? 3 : 4;
                        this.candidateDetailTransfer(this.candidateDetailData, this.originCandidateDetailData);
                        break;
                    default:
                        break;
                }
            },
            handleCandidateUnlock(param) {
                this.getLockStatusAsync(this.candidateDetailData.id);
                this.getCandidateDetail();
            },
            handleImportConfirm(){
                this.firstLoad = true;
                this.getCandidateDetail();
            },
            ...mapActions([
                'getLockStatusAsync'
            ]),
            getExistId(cd) {
                if(!this.existedCandidateId && !this.existedResumeId) {
                    talentPoolService.getMoreResume({
                        talentId: this.candidateDetailData.talentId,
                    }).then((res) =>{
                        if(res) {
                            let resumeListItem = res.find((item) => {
                                if(item.isFirmResume) {
                                    return item;
                                }
                            });
                            this.existedCandidateId = resumeListItem.candidateId;
                            this.existedResumeId = resumeListItem.resumeId;
                            cd();
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                } else {
                    cd();
                }
            },
            setCompletionPercent(id){
                _request({
                    url: `/Resume/${id}/Completion`,
                    method: 'GET'
                }).then((res) => {
                    this.completionPercent = res;
                }).catch((err) => {
                    console.log(err);
                })
            },
            recordView(){
                let resumeId = this.candidateDetailData.tbdResumeId;
                _request({
                    url: `/ResumeDetail/${resumeId}/ViewRecord`,
                    method: 'POST'
                }).then((res) => {
                }).catch((err) => {
                    console.log(err);
                })
            },
            pageToTop() {
                document.documentElement.scrollTop = 0;
            }
        }
    }

</script>

<style lang="scss">
    @import "./tab.scss";

    @media screen and (max-width: 1400px) {
         .candidate-detail-left {
            width: calc(100% - 310px) !important;
            .resume-module .item-header {
                width: 625px !important;
            }
            .candidate-resume-work-form {
                padding: 30px 10px !important;
            }
            // .col-15 {
            //     width: 690px !important;
            // }
            .col-14 {
                width: 660px !important;
            }
        }
        .candidate-detail-right {
            width: 290px !important;
            .record-title {
                max-width: 200px !important;
            }
        }
    }
    @media screen and (min-width: 1400px) and (max-width: 1680px) {
         .candidate-detail-left {
            width: calc(100% - 410px) !important;
        }
        .candidate-detail-right {
            width: 390px !important;
        }
    }
    @media screen and (min-width: 1680px) and (max-width: 1800px) {
         .candidate-detail-left {
            width: calc(100% - 440px) !important;
        }
        .candidate-detail-right {
            width: 420px !important;
        }
    }

    .candidateDetail.candidate-detail-vue{
        height: 100%;
        overflow-y: auto;
        padding: 20px;
        .candidate-wrap{
            // width: 1200px;
            // margin: -10px auto 0;

            padding-bottom: 56px;
            &::after {
                content: '';
                display: block;
                clear: both;
            }

            .candidate-content {
                min-height: calc(100vh - 400px);
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                .candidate-detail-left {
                    width: calc(100% - 480px);
                    background-color: #fff;
                    border-radius: 8px;
                }
                .candidate-detail-right {
                    margin-left: 20px;
                    width: 460px;
                    // background-color: #fff;
                    // border-radius: 8px;
                }
            }

            .type-tab{
                // background-color: #fff;
                // width: 830px;
                // border: 1px solid #ddd;
                border-bottom: 1px solid #EEEEEE;
                // margin-top: 20px;
                margin-bottom: 15px;
                .tab{
                    border-bottom: none;
                    .selection-item{
                        bottom: 0px;
                        width: auto;
                        min-width: 46px;
                        font-size: 16px;
                        border-bottom: none;
                        background-color:#fff;
                        border-radius: 8px;
                        padding: 0 15px 0;
                        &:before {
                            display: none;
                        }
                        &:nth-of-type(1){
                            padding-left: 30px;
                        }
                        .tab-wrapper{
                            // height: 40px;
                            // line-height: 40px;
                            height: 70px;
                            line-height: 70px;
                            padding: 0;
                        }
                    }
                    .active{
                        .tab-wrapper {
                            color:#38bc9c;
                            border: none;
                            // border-bottom: 2px solid #38bc9c;
                            border-bottom: 4px solid #38bc9c;
                        }
        
                        &:before {
                            display: none;
                        }
                    }
        
                }
        
                &.has-unprocessed-item .selection-list{
                    li:nth-of-type(2)>.tab-wrapper{
                        &:before{
                            content: '●';
                            color: red;
                            width:8px;
                            height:8px;
                        }
                    }
                }
        
            }
            .operate-list {
                width: 350px;
                .info-right {
                    .btn-hollow {
                        width: 174px;
                        height: 61.7px;
                        line-height: 60px;
                        border: 1px solid #ddd;
                        border-bottom: none;
                        border-right: none;
                        background-color: #fff;
                        padding: 0;
                        color: #666;
                        &:hover {
                            border-color: #38bc9c;
                            background-color: #38BC9D;
                            border-bottom-color: #38BC9D;
                            border-right-color: #38BC9D;
                            > span {
                                color: #fff;
                            }
                        }
                        &:nth-of-type(5),&:nth-of-type(6) {
                            border-bottom: 1px solid #ddd;
                        }
                        &:nth-of-type(2n){
                            border-right: 1px solid #ddd;
                        }
                        &.btn-resume-grab {
                            color: #4A90E2;
                        }
                        &.btn-hollow-disable{
                            background-color: #eee;
                            color: #999;
                            cursor: not-allowed;
                            &:hover{
                                background-color: #eee;
                                border-color: #ddd;
                                > span {
                                    color: #999;
                                }
                            }
                        }
                    }

                    .btn-list-authorization .btn-hollow {
                        &:nth-of-type(4) {
                            border-bottom: 1px solid #ddd;
                        }
                        &:nth-of-type(5) {
                            border-right: 1px solid #ddd;
                        }
                    }
                }
            }
            .header-bar{
                top: 0;
                left: 0;
                // position: fixed;
                // z-index: 99;
                width: 845px;
                // background: #ffffff;
                // box-shadow: 0 1px 4px 0 rgba(189,189,189,0.50);
                height: 48px;

                .bar-menu{
                    // width: 1200px;
                    margin: 0 auto;
                    height: 48px;
                    line-height: 48px;
                    >a{
                        display: inline-block;
                        // line-height: 48px;
                        font-size: 14px;
                        color: #9b9b9b;
                        float: left;
                        // margin-right: 95px;
                    }
                    .bar-time{
                        float: right;
                    }
                }
            }
            .header-bar-height{
                height: 46px;
            }

            textarea{
                resize: none;
            }
            
            .candidate-resume-comments{
                .comments-wrap{
                    // background: #fff;
                    // border: 1px solid #ddd;
                    border-top: none;
                }

                .title{
                    background: #f4f7f7;
                    margin-top: 0;
                }

                form,.comments{
                    padding: 20px;
                }
            }

            .candidate-resume-detail,
            .candidate-communication-list,
            .candidate-recommendation-record,
            .candidate-timeline{
                // width: 830px;
                padding: 0;
                margin: 0;
            }

            .candidate-recommendation-detail{
                .show-more,
                .ko-form{
                    &.active{
                        display: block;
                    }
                }

                .candidate-article{
                    .title{
                        margin-bottom: 12px;
                        line-height: 19px;

                        .btn-edit-wrap {
                            display: inline-block;
                        }
                    }

                    .document-list{
                        margin-bottom: 30px;
                    }

                }
                .file-uploader{
                    color: #b9b9b9;

                    .fa{
                        color: #b9b9b9;
                    }
                }
            }
            .file-uploader{
                .ko-form{
                    display: block;
                }
            }
            .candidate-article,
            .comments,
            .candidate-search-record,
            .candidate-communication-list,
            .candidate-recommendation-timeline,
            .candidate-recommendation-record,
            .candidate-detail,
            .comments-wrap,
            .candidate-recommendation-reader-list,
            .candidate-timeline,
            .candidate-recommend{
                .title {
                    font-size: 18px;
                    color: #333333;
                    padding: 10px 0;
                    text-indent: 18px;
                    margin-bottom: 20px;
                    position: relative;

                    &:before{
                        display: block;
                        content: "";
                        background-color: #00BD9C;
                        width: 8px;
                        height: 1em;
                        position: absolute;
                    }
                }

                .title{
                    .btn{
                        background-color: #fff;
                        border: none;
                        padding: 0;
                        color: #B9B9B9;

                        &:focus,
                        &:active,
                        &:hover{
                            background-color: #fff;
                            box-shadow: none;
                            outline: none;
                        }
                    }
                }
            }

            .comments-wrap,
            .candidate-recommendation-reader-list,
            .candidate-recommendation-timeline{
                .title {
                    border-bottom: none;
                    margin-bottom: 0;
                }
            }

            .candidate-recommendation-timeline{
                .show-more{
                    display: block;
                }
            }
            .order-item:first-child {
                border-top: none;
            }

            .card{
                display: block;
            }

            .candidate-search-record,.candidate-recommend{
                .title {
                    position: relative;
                    border-bottom: none !important;
                    margin-bottom: 0;
                }
            }

            .file-uploader {
                font-size: 14px;
                color: #00BD9C;

                .fa {
                    color: #00BD9C;
                    vertical-align: text-bottom;
                }
            }

            .resume-progress{
                padding: 0;
                margin-bottom: 0;
            }

        }
    }

    .text-warning {
        color: #FC7859;
    }
    .to-top-button {
        width: 40px;
        height: 40px;
        line-height: 40px;
        padding: 0 !important;
        min-width: unset;
        position: fixed;
        z-index: 1;
        right: 10px;
        bottom: 56px;
        background-color: #999 !important;
        &:hover {
            background-color: #666 !important;
        }
        .to-top-icon {
            width: 28px;
            height: 24px;
        }
    }
    .ali-resume-dialog-text {
        font-size: 14px;
        color: #666;

        a {
            color: $primary;
        }
    }
</style>