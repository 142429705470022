var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "candidate-recommendation-timeline" },
    [
      _c("font-icon", {
        staticClass: "candidate-icon",
        attrs: { href: "#icon-candidate_timeline" },
      }),
      _c("span", { staticClass: "candidate-recommendation-title" }, [
        _vm._v("\r\n        时间线\r\n    "),
      ]),
      _c("div", { staticClass: "detail detail-margin detail-text" }, [
        _c(
          "div",
          { staticClass: "time-line" },
          _vm._l(_vm.recoredsList, function (item, index) {
            return _c("div", { key: "timeline_" + index, staticClass: "row" }, [
              _c("div", { staticClass: "col-xs-3" }, [
                _c(
                  "span",
                  {
                    staticClass: "small",
                    class: {
                      dimission: [17, 37].includes(item.type),
                      obsoleted: item.type == 11,
                    },
                    domProps: { textContent: _vm._s(item.typeText) },
                  },
                  [_vm._v("接受")]
                ),
              ]),
              _c("div", { staticClass: "col-xs-9" }, [
                _c("div", { staticClass: "action" }, [
                  _c("p", [
                    _c(
                      "span",
                      { domProps: { textContent: _vm._s(item.created) } },
                      [_vm._v("2021年06月01日 17:10")]
                    ),
                  ]),
                  item.posterRealName == "系统内置用户"
                    ? _c("span", {
                        staticClass: "text-primary lbd-cursor-pointer",
                        attrs: {
                          "data-id": "a83c0366-a433-47ac-b6e8-2db5b96e67b3",
                        },
                        domProps: { textContent: _vm._s(item.posterRealName) },
                      })
                    : _c(
                        "span",
                        {
                          staticClass: "text-primary lbd-cursor-pointer",
                          attrs: {
                            "data-id": "a83c0366-a433-47ac-b6e8-2db5b96e67b3",
                          },
                          domProps: {
                            textContent: _vm._s(
                              item.posterRealName +
                                (item.posterNickName
                                  ? "@" + item.posterNickName
                                  : "")
                            ),
                          },
                        },
                        [_vm._v("碧秋")]
                      ),
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.genereteHtml(item)) },
                  }),
                ]),
              ]),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }