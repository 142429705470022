<template>
    <div class="candidate-recommendation-header recommendation-header-vue" style="display: flex;">
        <span class=" title">
            <a :href="href" @click="handleRouterNavigate">
                <i v-if="canReturn" class="el-icon-d-arrow-left"></i>
                <span class="title-font-color">全部推荐记录</span>
            </a>
            <span class="recommend-angle">
                <!-- <a :href="jobId ? '/Headhunting/MyCompany.html#/Job/' + jobId + (currentJobType==3 ? `?curRecommendJobType=${currentJobType}` : '') : 'javascript: void(0)'" target="_blank"> -->
                <!-- <a :href="jobId ? '/Headhunting/MyCompany.html#/Job/' + jobId : 'javascript: void(0)'" target="_blank">
                    <span v-text="getJobLabel()" class="title-font-color"></span>
                </a> -->
                <a :href="jobId ? '/#/Job/' + jobId : 'javascript: void(0)'" target="_blank">
                    <span v-text="getJobLabel()" class="title-font-color"></span>
                </a>
            </span>
        </span>
        <span class="recommend-user recommend-user-overflow">
            <avatar
                :userId="callee.id ? callee.id : defaultCreatorId"
                :src="callee.avatarUrl ? callee.avatarUrl : formatAvatarUrl(`/Data/Avatar/${callee.avatarId}`)"
                :enableLink="true"
                :enableName="true"
                :realName="callee.name"
                :nickName="callee.nickname"
                :isRecommendation="true"
                :shareData="shareData"
                :currentJobType="currentJobType"
                size="sm"
            ></avatar>
        </span>
        <span v-if="canCall && !isMyself && !lockStatus.isOnboardLock" class="recommend-dialog">
            <phone-call
                :call-to="0"
                :object-id="callee.id"
                @candidate-search-record-refresh='handelRecordRefresh'
                ref="phoneCall"
                sourceFrom="candidateDetail"
                :tbdResumeId="resumeId"
                :called-mobile="callee.mobile"
            >
                <el-button>拨打</el-button>
            </phone-call>
        </span>
        <span class="recommend-dialog " v-if="!(canCall || !isMyself) && lockStatus.isOnboardLock">
            <el-tooltip placement="bottom" effect="light" popper-class="concat-tooltip">
                <div slot="content">
                    候选人正在入职保证期中，暂不能拨打其电话
                </div>
                <el-button class="free-call">拨打</el-button>
            </el-tooltip>
        </span>
    </div>
</template>

<script>
    // import { updateObservable } from '#/js/common/observable-vue';

    import PhoneCall from '#/component/common/phone-call-new.vue';
    import Avatar from '#/component/common/avatar.vue';

    const VOID_HREF = "javascript:void(0);";

    export default {
        name: "candidateRecommendationHeader",
        components: {
            PhoneCall,
            Avatar,
            // User
        },
        props:{
            currentJobType: Number,
            resumeId: String,
            shareData: Object
        },
        data() {
            return {
                jobId: '',
                canCall: '',
                callee: {
                    name: '',
                    id: '',
                    nickname: '',
                    action: '',
                    avatarUrl: '',
                    mobile: ''
                },
                firmName: '',
                jobName: '',
                location: '',
                canReturn: true,
                userId: this.$store.state.user.userInfo.id,
            }
        },
        computed: {
            href() {
                return this.canReturn ? '#/candidateDetail/'+this.$route.params.resumeId+'/recommendationList' : VOID_HREF;
            },
            isMyself(){
                return this.userId === this.callee.id;
            },
            lockStatus() {
                return this.$store.state.candidateDetail.lockStatus;
            },
            defaultCreatorId(){
                return this.$store.state.user.userInfo.id;
            },
            defaultCreatorAvatar(){
                return this.$store.state.user.userInfo.avatarUrl
            }
        },
        methods: {
            formatAvatarUrl(url) {
                return _host.portal + url;
            },
            
            getJobLabel(){
                return `${this.firmName || ''} - ${this.jobName || ''}[${this.location || ''}]`;
            },
            updateCallee(callee){
                this.callee = callee;
            },
            // refresh(item){
            //     //todo  item是什么
            //     updateObservable(this, item);
            // },
            // update(vm){
            //     //todo  vm是什么
            //     updateObservable(this, vm);
            // },
            handelRecordRefresh(){
                this.$emit('candidate-search-record-refresh');
            },
            handleRouterNavigate(){
                this.$emit('router-navigate',{url:this.href});
            }

        }
    };
</script>

<style lang="scss">
    @import "./style-vue.scss";
.recommendation-header-vue{
    .title .recommend-angle{
        margin-left: 50px;
        display: inline-block;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: bottom;
    }
    .phone-call-test {
        vertical-align: unset;
    }
    .free-call {
        padding: 0 15px;
        height: 28px;
        line-height: 28px;
        border-radius: 500px;
        background-color: $primary;
        color: #fff;
        border: none;
        outline: none;

        .icon-call {
            display: inline-block;
            background-image: url('~@src/assets/images/icon/icon-call.png');
            width: 18px;
            height: 18px;
            vertical-align: middle;
        }
    }
    .recommend-user-overflow {
        margin-left: 20px;
        display: inline-block;
        height: 22px;
        .avatar-container {
            display: inline-block;
            height: 22px;
            .user-name{
                max-width: 120px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    .recommend-dialog{
        margin-left: 20px;
        .el-button{
            height: 19px;
            line-height: 18px;
            padding: 0 7px;
            border-radius: 4px;
            background-color: #38bc9d;
            border: solid 1px #38bc9d;
            color: #fff;
            font-size: 12px;
            min-width: 0px;
            &:hover{
                background-color: #36B495;
                border-color:#36B495;
            }
        } 
    }
}
</style>
