var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "candidateResumeIntentionForm",
      staticClass: "candidate-resume-intention-form col-15",
      attrs: { id: "candidate-resume-intention-form", autocomplete: "off" },
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "Id" },
        domProps: { value: _vm.formData.id },
      }),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          {
            staticClass: "control-label required col-md-12 none-before",
            attrs: { for: "title" },
          },
          [_vm._v("期望职位")]
        ),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.title,
                expression: "formData.title",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "title", name: "title", type: "text", value: "" },
            domProps: { value: _vm.formData.title },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "title", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          {
            staticClass: "control-label required col-md-12 none-before",
            attrs: { for: "locationId" },
          },
          [_vm._v("期望城市")]
        ),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("city-select", {
              ref: "citySelect",
              on: { citySelected: _vm.handleCitySelected },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          {
            staticClass: "control-label required col-md-12 none-before",
            attrs: { for: "startDate" },
          },
          [_vm._v("薪资要求")]
        ),
        _c("div", { staticClass: "col-sm-12 input-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.minSalary,
                expression: "formData.minSalary",
              },
            ],
            staticClass: "form-control col-3",
            attrs: {
              id: "minSalary",
              name: "minSalary",
              type: "text",
              value: "",
            },
            domProps: { value: _vm.formData.minSalary },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "minSalary", $event.target.value)
              },
            },
          }),
          _c("span", { staticClass: "text-inline" }, [_vm._v("K")]),
          _c("div", { staticClass: "v-line" }, [_vm._v("-")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.maxSalary,
                expression: "formData.maxSalary",
              },
            ],
            staticClass: "form-control col-3",
            attrs: {
              id: "maxSalary",
              name: "maxSalary",
              type: "text",
              value: "",
            },
            domProps: { value: _vm.formData.maxSalary },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "maxSalary", $event.target.value)
              },
            },
          }),
          _c("span", { staticClass: "text-inline" }, [_vm._v("K")]),
        ]),
      ]),
      _c("div", { staticClass: "form-group col-14" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("input", {
            staticClass: "btn btn-primary submitBtn btn-background",
            attrs: { type: "button", value: "保存" },
            on: { click: _vm._submitValidate },
          }),
          _c("input", {
            staticClass: "btn btn-default cancelBtn",
            attrs: { type: "button", value: "取消" },
            on: { click: _vm._cancel },
          }),
        ]),
        _c("div", { staticClass: "col-sm-2 pull-right" }, [
          _c("div", { staticClass: "delete-btn", on: { click: _vm._delete } }, [
            _vm._v("删除本条"),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }