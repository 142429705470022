<template>
    <div class="attachment-item" v-loading="loading">
        <div class="item-detail" :class="isRecommend ? 'item-detail-recommend' : ''">
            <!-- <doll name="paperclip"></doll> -->
            <font-icon href="#icon-fujian"></font-icon>
            <span v-if="!data.showBeforeAccept && !isRecommend" class="secret" title="接受简历后，HR才可以看到">密</span>
            <el-tooltip class="item" effect="dark" :content="isRecommend ? '点击下载' :'下载并查看联系方式'" placement="bottom">
                <a @click="downloadFile" :href="!canOperate ? url : 'javascript:;;'" class="document-anchor">
                    <span v-text="data.name" class="document-title"></span>
                    <!-- <span class="document-size">{{ length | humanSize | circle }}</span> -->
                </a>
            </el-tooltip>
        </div>
        <ul v-if="canOperate && !isRecommend" ref="menu" class="menu opr">
            <li v-if="isShowSendOperation" @click="onSend" class="attachment-send">
                发送
            </li>
            <li v-if="isShowPrivacyOperation" @click="updatePrivacy" :class="{secret: data.showBeforeAccept}" class="attachment-privacy">
                <template v-if="!data.showBeforeAccept">
                    <span title="未接受简历时，HR也可以看到">公开</span>
                    <!-- <popover trigger="hover" content="未接受简历时，HR也可以看到"></popover> -->
                </template>
                <template v-if="data.showBeforeAccept">
                    <span title="接受简历后，HR才可以看到">私密</span>
                    <!-- <popover trigger="hover" content="接受简历后，HR才可以看到"></popover> -->
                </template>
            </li>
            <li @click="onRemove" v-show="!isFromAuthorization && data.isOwnered" class="attachment-remove">
                删除
            </li>
        </ul>
        <span v-if="data.created" class="upload-time">上传时间：{{dataFilter(data.created)}}</span>
    </div>
</template>

<script>
    import moment from 'moment';
    import {candidate as candidateUrls} from '#/js/config/api.json';
    
    export default {
        name: "attachmentItem",
        components: {
            // Doll,
            // Popover
        },
        data() {
            return {
                // canOperate: !server.isPublisher
                canOperate: true,
                loading:false,
            }
        },
        props: {
            data: {
                type: Object,
                default: function() {
                    return {}
                }
            },
            send: Function,
            remove: Function,
            download: Function,
            candidateId: String,
            resumeId:String,
            isRecommend: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isShowPrivacyOperation() {
                return !!this.recommendationId;
            },
            isShowSendOperation() {
                return !this.isShowPrivacyOperation && false;
            },
            privacyUrl() {
                // return placeholder(candidateUrls.url_update_recommendation_attachment, this.recommendationId);
                return candidateUrls.url_update_recommendation_attachment.replace(/%p/, this.recommendationId);
            },
            removeUrl() {
                return this.recommendationId ? 
                candidateUrls.url_remove_recommendation_attachment.replace(/%p/, this.recommendationId) : candidateUrls.url_remove_candidate_attachment.replace(/%p/, this.resumeId);
            },
            verificationToken() {
                return this.$store.state.verificationToken.verificationToken;
            },
            isFromAuthorization() {
                return this.$store.state.candidateDetail.candidateInfo.isFromAuthorization || false
            }
        },
        mounted() {
            this.menu = this.$refs.menu;
        },
        methods: {
            dataFilter(val) {
                return moment(val).format('YYYY-MM-DD HH:mm');
            },
            getUrlCandidateId(){
                let result = '';
                let searchURL = window.location.href;
                let startIndex = searchURL.indexOf('candidateDetail');
                let endIndex = searchURL.lastIndexOf('#');
                result = searchURL.substring(startIndex+16, endIndex);
                return result;
            },
            hideMenu(){
                this.menu.visible(false);
            },
            showMenu(){
                this.menu.visible(true);
            },
            updatePrivacy: function (vm, ev) {
                if (this.loading==true) {
                    return;
                }
                this.loading=true;
                _request({
                    url: this.privacyUrl,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        fileId: this.data.id,
                        showBeforeAccept: !this.showBeforeAccept,
                        __RequestVerificationToken: this.verificationToken
                    }
                }).then(res =>{
                    this.toggle('showBeforeAccept');
                }).finally(() => {
                    this.loading=false;
                })
            },

            _remove: function (vm, ev) {
                if (this.loading==true) {
                    return;
                }
                if(this.$parent.attatchmentList.length == 1) {
                    return shortTips("至少保留一份简历附件");
                }

                this.loading=true;
                this.$parent.loading = true;
                _request({
                    url: this.removeUrl,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        attachmentId: this.data.id,
                        resumeId: this.resumeId,
                        __RequestVerificationToken: this.verificationToken
                    }
                }).then(res =>{
                    shortTips("删除成功");
                    this.remove(this.data);
                }).finally(() => {
                    this.loading=false;
                    this.$parent.loading = false;
                })
            },

            // 发送操作，统一由attachment-list进行控制
            onSend: function(){
                this.send(this.data);
            },
            downloadFile: function(){
                this.download(this.data);
            },
            onRemove: function () {
                this._remove();
            }
        },

    };

</script>

<style lang="scss" scoped>
.attachment-item {

    .menu {
        display: inline-block;
        vertical-align: middle;
        margin: 0;

        .attachment-remove{
            margin-left: 6px;
        }

        > li {
            border: 1px solid #ddd;
            background-color: #fff;
            width: 38px;
            height: 20px;
            color: #666;
            font-size: 12px;
            cursor: pointer;
            margin: 0;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.attachment-send{
                border-color: #38BC9D;
                color: #fff;
                background-color: #38BC9D;
            }
            &.attachment-privacy{
                border-color: #38BC9D;
                color: #fff;
                background-color: #38BC9D;
            }
            &.attachment-privacy.secret{
                border-color: #ff6666;
                color: #fff;
                background-color: #ff6666;
            }
            &:hover{
                background-color: #36B495;
                color: #fff;
                border-color: #36B495;
            }
        }

    }

    .item-detail {
        display: inline-block;
        max-width: 510px;
        vertical-align: middle;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.item-detail-recommend {
            max-width: 450px;
        }

        .document-anchor{
            cursor: pointer;
            color: #00bd9c;
        }

        .font-icon {
            width: 14px;
            height: 14px;
            margin-top:-2px;
            margin-right: 4px;
            fill: #666;
        }
    }
    .el-loading-mask{
        .el-loading-spinner{
            margin-top: -15px;
            .circular{
                width: 25px;
                height: 25px;
            }
        }
    }
    .upload-time{
        margin-left: 20px;
        color: #999;
        vertical-align: -2px;
    }
}

/*TODO    新旧组件混合中的临时样式*/
.recommendation-document-list {
    .attachment-lists{
        .order-item {
            float: left;
            display: inline-block;
            width: auto;
        }

        .secret{
            display: initial;
        }
    }

    .attachment-item {
        .item-detail {
            width: auto;
        }

        .menu {
            float: none !important;
            > li {
                border: none;
                padding: 0 4px;
                vertical-align: sub;
                margin: 0;
            }

            .attachment-remove:before {
                content: "X";
                position: absolute;
                width: 25px;
                height: 1em;
                z-index: 11;
                background: #fff;
                cursor: pointer;
            }

        }

    }

    .order{
        min-height: auto !important;
    }
    .pager {
        margin: 0;
    }
}
</style>
