var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "candidate-attachments" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _c(
          "el-upload",
          {
            ref: "el-upload",
            staticClass: "resume-upload",
            attrs: {
              action: _vm.uploadUrl,
              data: _vm.token,
              headers: _vm.fileUploadHeaders,
              "show-file-list": false,
              "with-credentials": true,
              "on-success": _vm.handleUploadSuccess,
              "on-error": _vm.handleUploadError,
              "before-upload": _vm.handleBeforeUpload,
            },
          },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.uploadLoading,
                    expression: "uploadLoading",
                  },
                ],
                staticClass: "upload-content",
              },
              [
                _c("i", { staticClass: "icon-font icon-upload" }),
                _c("span", { staticClass: "upload-content-title" }, [
                  _vm._v("上传内部附件"),
                ]),
              ]
            ),
          ]
        ),
        _c("span", { staticClass: "upload-content-tip" }, [
          _vm._v(
            "注意：上传附件，不会更新简历。如要更新简历，请点击编辑按钮。"
          ),
        ]),
        _c("el-alert", {
          staticClass: "file-tip",
          attrs: {
            title:
              "仅支持上传pdf、doc、docx、txt格式的简历(不支持pdf格式的图片)",
            type: "info",
            "show-icon": "",
            "close-text": "知道了",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "document-list card" },
      [
        _c("attachment-list", {
          ref: "attachmentList",
          attrs: {
            "is-from-authorization": _vm.isFromAuthorization,
            "candidate-id": _vm.candidateId,
            "resume-id": _vm.resumeId,
          },
          on: { "has-attachment": _vm.isHasAttachment },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }