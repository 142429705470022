var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "candidate-timeline row row-overflow" }, [
        _c("div", { staticClass: "timeline-list-box" }, [
          _c("div", { staticClass: "tiemline-header" }, [
            _vm.isFirm
              ? _c(
                  "div",
                  { staticClass: "pull-right check-edit-list" },
                  [
                    _c(
                      "el-dropdown",
                      {
                        attrs: { trigger: "hover", placement: "bottom-start" },
                      },
                      [
                        _c("el-button", { attrs: { type: "primary" } }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.logSwitchBtnText) +
                              "\n                            "
                          ),
                          _c("i", {
                            staticClass:
                              "el-icon-caret-top el-icon--right owner-log-tip-hover",
                          }),
                          _c("i", {
                            staticClass:
                              "el-icon-caret-bottom el-icon--right owner-log-tip",
                          }),
                        ]),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticClass: "timeline-dropdown",
                            attrs: { slot: "dropdown" },
                            slot: "dropdown",
                          },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onFilterLogs(2, "查看编辑日志")
                                  },
                                },
                              },
                              [_vm._v("查看编辑日志")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onFilterLogs(0, "查看全部日志")
                                  },
                                },
                              },
                              [_vm._v("查看全部日志")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isCFUser && _vm.isAdministrator,
                                    expression: "isCFUser && isAdministrator",
                                  },
                                ],
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onFilterLogs(
                                      20,
                                      "简历归属权日志"
                                    )
                                  },
                                },
                              },
                              [_vm._v("简历归属权日志")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isCFUser && _vm.isAdministrator,
                                    expression: "isCFUser && isAdministrator",
                                  },
                                ],
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onFilterLogs(8, "修改复用权益者")
                                  },
                                },
                              },
                              [_vm._v("修改复用权益者")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "order-container",
              class: !_vm.isCFUser ? "timeline-list" : "",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "order-wrapper",
                },
                [
                  _c(
                    "ul",
                    { staticClass: "order-full" },
                    _vm._l(_vm.items, function (item, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "order-item" },
                        [
                          _c("div", { staticClass: "timeline-item" }, [
                            item.isHistorical
                              ? _c(
                                  "div",
                                  { staticClass: "timeline-item-info" },
                                  [
                                    _c("p", [
                                      _c("span", {
                                        staticClass: "log-owner",
                                        domProps: {
                                          textContent: _vm._s(
                                            item.creatorRealName +
                                              "@" +
                                              item.creatorNickName
                                          ),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "text-grey",
                                        domProps: {
                                          textContent: _vm._s(item.created),
                                        },
                                      }),
                                    ]),
                                    item.type !== 2 && item.type !== 16
                                      ? _c("p", { staticClass: "log-detail" }, [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(item.content),
                                            },
                                          }),
                                        ])
                                      : _vm._e(),
                                    item.type === 4
                                      ? _c("p", { staticClass: "log-detail" }, [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(item.content),
                                            },
                                          }),
                                          _c("i", {
                                            staticClass: "fa fa-paperclip",
                                          }),
                                          _c("a", {
                                            staticClass: "attachment",
                                            domProps: {
                                              textContent: _vm._s(
                                                item.paramOne
                                              ),
                                            },
                                          }),
                                        ])
                                      : _vm._e(),
                                    item.type === 10
                                      ? _c("p", { staticClass: "log-detail" }, [
                                          _c("span", [
                                            _vm._v("覆盖更新简历由"),
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-primary",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onResumeVersionClick(
                                                    item,
                                                    _vm.list.items[index + 1],
                                                    0
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 历史版本 ")]
                                          ),
                                          _c("span", [_vm._v("更改为")]),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-primary",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onResumeVersionClick(
                                                    item,
                                                    _vm.list.items[index + 1],
                                                    1
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 新版本 ")]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._l(item.items, function (param, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "log-detail",
                                        },
                                        [
                                          param.historicalType !== 4 &&
                                          param.historicalType !== 10
                                            ? _c(
                                                "p",
                                                { staticClass: "log-detail" },
                                                [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        param.content
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          param.historicalType === 4
                                            ? _c(
                                                "p",
                                                { staticClass: "log-detail" },
                                                [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        param.content
                                                      ),
                                                    },
                                                  }),
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-paperclip",
                                                  }),
                                                  _c("a", {
                                                    staticClass: "attachment",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        param.paramOne
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          param.historicalType === 10 &&
                                          param.isStandardResume !== true
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "log-detail per-text",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("覆盖更新简历由"),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-primary",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onResumeVersionClick(
                                                            param,
                                                            item.items[
                                                              index + 1
                                                            ],
                                                            0
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 历史版本 ")]
                                                  ),
                                                  _c("span", [
                                                    _vm._v("更改为"),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-primary",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onResumeVersionClick(
                                                            param,
                                                            item.items[
                                                              index + 1
                                                            ],
                                                            1
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 新版本 ")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            !item.isHistorical &&
                            _vm.operationLogContent(item, item.type)
                              ? _c(
                                  "div",
                                  { staticClass: "timeline-item-info" },
                                  [
                                    _c("p", [
                                      item.creatorRealName == "系统内置用户"
                                        ? _c("span", {
                                            staticClass: "log-owner",
                                            domProps: {
                                              textContent: _vm._s(
                                                item.creatorRealName
                                              ),
                                            },
                                          })
                                        : _c("span", {
                                            staticClass: "log-owner",
                                            domProps: {
                                              textContent: _vm._s(
                                                item.creatorRealName +
                                                  "@" +
                                                  item.creatorNickName
                                              ),
                                            },
                                          }),
                                      _c("span", {
                                        staticClass: "text-grey",
                                        domProps: {
                                          textContent: _vm._s(item.created),
                                        },
                                      }),
                                    ]),
                                    [
                                      item.type !== 2 && item.type !== 21
                                        ? _c(
                                            "p",
                                            { staticClass: "log-detail" },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.operationLogContent(
                                                      item,
                                                      item.type
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.type === 2
                                        ? _c(
                                            "span",
                                            { staticClass: "log-detail" },
                                            [
                                              item.content.editLog
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "log-detail",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.editType[
                                                              item.content
                                                                .editLog
                                                                .editType
                                                            ]
                                                          ) +
                                                            "，完整度变化：" +
                                                            _vm._s(
                                                              item.content.editLog.oldCompletion.toFixed(
                                                                2
                                                              )
                                                            ) +
                                                            " => " +
                                                            _vm._s(
                                                              item.content.editLog.newCompletion.toFixed(
                                                                2
                                                              )
                                                            ) +
                                                            "，编辑后简历类型：" +
                                                            _vm._s(
                                                              _vm
                                                                .resumeTypeHashMap[
                                                                item.content
                                                                  .editLog
                                                                  .resumeType
                                                              ]
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.content.contentLog
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "log-detail",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "覆盖更新简历由"
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-primary",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onNewResumeVersionClick(
                                                                item.content
                                                                  .contentLog
                                                                  .oldContent,
                                                                item.content
                                                                  .contentLog
                                                                  .oldStandardContent,
                                                                0
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" 历史版本 ")]
                                                      ),
                                                      _c("span", [
                                                        _vm._v("更改为"),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-primary",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onNewResumeVersionClick(
                                                                item.content
                                                                  .contentLog
                                                                  .newContent,
                                                                item.content
                                                                  .contentLog
                                                                  .newStandardContent,
                                                                1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" 新版本 ")]
                                                      ),
                                                      item.content.editLog &&
                                                      item.content.editLog
                                                        .isUploadResume
                                                        ? _c("span", [
                                                            _vm._v("R"),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                item.content.fieldLogs,
                                                function (param, index) {
                                                  return _c(
                                                    "p",
                                                    {
                                                      key: index,
                                                      staticClass: "log-detail",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.fildNameHashMap[
                                                              param.fieldName
                                                            ]
                                                          ) +
                                                            "由 " +
                                                            _vm._s(
                                                              param.oldValue
                                                            ) +
                                                            " 更改为 " +
                                                            _vm._s(
                                                              param.newValue
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      item.type === 21
                                        ? _c(
                                            "p",
                                            { staticClass: "log-detail" },
                                            [
                                              item.content.current
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.creatorRealName
                                                      ) +
                                                        "修改归属权，当前归属者由【" +
                                                        _vm._s(
                                                          item.content.current
                                                            .oldBelonger
                                                        ) +
                                                        "】变为【" +
                                                        _vm._s(
                                                          item.content.current
                                                            .newBelonger
                                                        ) +
                                                        "】，生效日期为" +
                                                        _vm._s(
                                                          item.content.current
                                                            .belongerDate
                                                        ) +
                                                        "，简历类型为" +
                                                        _vm._s(
                                                          _vm.resumeTypeHashMap[
                                                            item.content.current
                                                              .resumeType
                                                          ]
                                                        ) +
                                                        "；"
                                                    ),
                                                    _c("br"),
                                                  ])
                                                : _vm._e(),
                                              item.content.history
                                                ? _c("span", [
                                                    _vm._v(
                                                      "历史归属者由【" +
                                                        _vm._s(
                                                          item.content.history
                                                            .oldBelonger
                                                        ) +
                                                        "】变为【" +
                                                        _vm._s(
                                                          item.content.history
                                                            .newBelonger
                                                        ) +
                                                        "】，生效日期为" +
                                                        _vm._s(
                                                          item.content.history
                                                            .belongerDate
                                                        ) +
                                                        "，简历类型为" +
                                                        _vm._s(
                                                          _vm.resumeTypeHashMap[
                                                            item.content.history
                                                              .resumeType
                                                          ]
                                                        ) +
                                                        "。"
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.listEmpty,
                  expression: "listEmpty",
                },
              ],
              staticClass: "timeline-limited",
            },
            [_vm._v("没有更多数据")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.moreBtn,
                  expression: "moreBtn",
                },
              ],
              staticClass: "timeline-load-more more-mouse",
              on: { click: _vm.loadMore },
            },
            [_vm._v("加载更多")]
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          ref: "modal",
          staticClass: "resume-version-dialog",
          attrs: {
            title: _vm.resumeVersionCaption,
            visible: _vm.versionDialogVisible,
            width: "700px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.versionDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              model: {
                value: _vm.tabFirst,
                callback: function ($$v) {
                  _vm.tabFirst = $$v
                },
                expression: "tabFirst",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: `${_vm.resumeVersionCaption}简历原文`,
                    name: "first",
                  },
                },
                [_vm._v(_vm._s(_vm.resumeVersionContent.now))]
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: `${_vm.resumeVersionCaption}标准简历`,
                    name: "second",
                  },
                },
                [_vm._v(_vm._s(_vm.resumeVersionContent.standard))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.ownerModalLoading,
              expression: "ownerModalLoading",
            },
          ],
          ref: "resumeOwnership",
          attrs: {
            title: _vm.modalTitle,
            visible: _vm.ownerDialogVisible,
            width: "608px",
            "before-close": _vm.ownerHandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.ownerDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "owner-wrap" }, [
            _c("div", { staticClass: "owner-item" }, [
              _c("p", { staticClass: "current-owner" }, [
                _vm._v(
                  "\n                    将当前归属者【" +
                    _vm._s(_vm.defaultBelonger.firstBelongerUserName) +
                    "】修改为"
                ),
                _c("br"),
              ]),
              _c(
                "div",
                { staticClass: "current-info-list" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "popper-class": "current-info-list",
                        filterable: "",
                        placeholder: "请输入，或者选择用户",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleUpdateUserName("firstBelonger")
                        },
                      },
                      model: {
                        value: _vm.firstBelonger.firstBelongerUserName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.firstBelonger,
                            "firstBelongerUserName",
                            $$v
                          )
                        },
                        expression: "firstBelonger.firstBelongerUserName",
                      },
                    },
                    _vm._l(_vm.customerList, function (item) {
                      return _c("el-option", {
                        key: item.userId,
                        attrs: {
                          label: item.realName,
                          value: item.userId
                            ? item.userId + "," + item.realName
                            : "无",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.selectClick.apply(null, arguments)
                          },
                        },
                      })
                    }),
                    1
                  ),
                  _c("el-date-picker", {
                    attrs: { type: "date", placeholder: "归属权生效日期" },
                    model: {
                      value: _vm.firstBelonger.firstBelongerDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.firstBelonger, "firstBelongerDate", $$v)
                      },
                      expression: "firstBelonger.firstBelongerDate",
                    },
                  }),
                  _c(
                    "el-select",
                    {
                      staticClass: "candidate-type",
                      attrs: { placeholder: "简历类型" },
                      model: {
                        value: _vm.firstBelonger.firstBelongerResumeType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.firstBelonger,
                            "firstBelongerResumeType",
                            $$v
                          )
                        },
                        expression: "firstBelonger.firstBelongerResumeType",
                      },
                    },
                    _vm._l(_vm.resumeType, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "owner-item" }, [
              _c("p", { staticClass: "current-owner" }, [
                _vm._v(
                  "\n                    将历史归属者【" +
                    _vm._s(_vm.defaultBelonger.secondBelongerUserName) +
                    "】修改为"
                ),
                _c("br"),
              ]),
              _c(
                "div",
                { staticClass: "current-info-list" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "popper-class": "current-info-list",
                        filterable: "",
                        placeholder: "请输入，或者选择用户",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleUpdateUserName("secondBelonger")
                        },
                      },
                      model: {
                        value: _vm.secondBelonger.secondBelongerUserName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.secondBelonger,
                            "secondBelongerUserName",
                            $$v
                          )
                        },
                        expression: "secondBelonger.secondBelongerUserName",
                      },
                    },
                    _vm._l(_vm.customerList, function (item) {
                      return _c("el-option", {
                        key: item.userId,
                        attrs: {
                          label: item.realName,
                          value: item.userId
                            ? item.userId + "," + item.realName
                            : "无",
                        },
                      })
                    }),
                    1
                  ),
                  _c("el-date-picker", {
                    attrs: { type: "date", placeholder: "归属权生效日期" },
                    model: {
                      value: _vm.secondBelonger.secondBelongerDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.secondBelonger, "secondBelongerDate", $$v)
                      },
                      expression: "secondBelonger.secondBelongerDate",
                    },
                  }),
                  _c(
                    "el-select",
                    {
                      staticClass: "candidate-type",
                      attrs: { placeholder: "简历类型" },
                      model: {
                        value: _vm.secondBelonger.secondBelongerResumeType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.secondBelonger,
                            "secondBelongerResumeType",
                            $$v
                          )
                        },
                        expression: "secondBelonger.secondBelongerResumeType",
                      },
                    },
                    _vm._l(_vm.resumeType, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.ownerDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleChange } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }