var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "candidateResumeProjectForm",
      staticClass: "candidate-resume-project-form col-15",
      attrs: { id: "candidate-resume-project-form", autocomplete: "off" },
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "Id" },
        domProps: { value: _vm.formData.id },
      }),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          {
            staticClass: "control-label required col-md-12 none-before",
            attrs: { for: "projectName" },
          },
          [_vm._v("项目名称")]
        ),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.projectName,
                expression: "formData.projectName",
              },
            ],
            staticClass: "form-control",
            attrs: {
              id: "projectName",
              name: "projectName",
              type: "text",
              value: "",
            },
            domProps: { value: _vm.formData.projectName },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "projectName", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          {
            staticClass: "control-label col-md-12",
            attrs: { for: "projectRole" },
          },
          [_vm._v("项目角色")]
        ),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.projectRole,
                expression: "formData.projectRole",
              },
            ],
            staticClass: "form-control",
            attrs: {
              id: "projectRole",
              name: "projectRole",
              type: "text",
              value: "",
            },
            domProps: { value: _vm.formData.projectRole },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "projectRole", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          {
            staticClass: "control-label col-md-12",
            attrs: { for: "startDate" },
          },
          [_vm._v("开始时间")]
        ),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("el-date-picker", {
              staticClass: "start-date-picker",
              staticStyle: { width: "100%" },
              attrs: {
                type: "month",
                placeholder: "如 2018-08",
                "value-format": "yyyy-MM",
                "picker-options": _vm.startDateOptions,
              },
              model: {
                value: _vm.formData.startDate,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "startDate", $$v)
                },
                expression: "formData.startDate",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          { staticClass: "control-label col-md-12", attrs: { for: "endDate" } },
          [_vm._v("结束时间")]
        ),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("el-date-picker", {
              ref: "endDatePicker",
              staticClass: "end-date-picker",
              staticStyle: { width: "100%" },
              attrs: {
                type: "month",
                placeholder: "如 2018-08",
                "value-format": "yyyy-MM",
                "picker-options": _vm.endDateOptions,
              },
              model: {
                value: _vm.formData.endDate,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "endDate", $$v)
                },
                expression: "formData.endDate",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "form-group col-14" }, [
        _c(
          "label",
          {
            staticClass: "control-label col-md-12",
            attrs: { for: "responsibility" },
          },
          [_vm._v("项目职责")]
        ),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.responsibility,
                expression: "formData.responsibility",
              },
            ],
            staticClass: "form-control",
            attrs: {
              rows: "3",
              id: "responsibility",
              name: "responsibility",
              type: "text",
              value: "",
            },
            domProps: { value: _vm.formData.responsibility },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "responsibility", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "form-group col-14" }, [
        _c(
          "label",
          {
            staticClass: "control-label col-md-12",
            attrs: { for: "description" },
          },
          [_vm._v("项目描述")]
        ),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.description,
                expression: "formData.description",
              },
            ],
            staticClass: "form-control",
            attrs: {
              rows: "3",
              id: "description",
              name: "description",
              type: "text",
              value: "",
            },
            domProps: { value: _vm.formData.description },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "description", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "form-group col-14" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("input", {
            staticClass: "btn btn-primary submitBtn btn-background",
            attrs: { type: "button", value: "保存" },
            on: { click: _vm._submitValidate },
          }),
          _c("input", {
            staticClass: "btn btn-default cancelBtn",
            attrs: { type: "button", value: "取消" },
            on: { click: _vm._cancel },
          }),
        ]),
        _c("div", { staticClass: "col-sm-2 pull-right" }, [
          _c("div", { staticClass: "delete-btn", on: { click: _vm._delete } }, [
            _vm._v("删除本条"),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }