<template>
    <el-dialog
        width="540px"
        title="推荐给他人"
        class="candidate-email-share"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div id="candidate-email-share-template" ref="candidateemailsharetemplate">
            <div class="candidate-email-share" v-loading="emailLoading">
                <p>您可以把<a :href="previewLinkUrl" target="_blank"><span class="resume-real-name" v-text="realName"></span></a>的简历以页面形式推荐给hr</p>
                <div class="form-group">
                    <div class="col-sm-2">
                        <label for="emails">邮箱地址</label>
                    </div>
                    <div class="col-sm-10 text-area">
                        <el-select
                            class="email-select"
                            v-model="emailArr"
                            multiple
                            filterable
                            allow-create
                            default-first-option
                            placeholder="多个邮箱以；隔开"
                        >
                            <el-option
                                v-for="item in options"
                                :key="item"
                                :label="item"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                        <!-- <textarea name="emails" ref="textareaemails" class="form-control" placeholder="多个邮箱以；隔开"></textarea> -->
                        <span id="emailError" class="text-danger" ref="emailError">{{emailErrorMsg}}</span>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-sm-2">
                        <label for="remark">推荐理由</label>
                    </div>
                    <div class="col-sm-10">
                        <textarea v-model="remark" name="remark" ref="textarearemark" class="form-control" placeholder="你希望他给您反馈吗？留个言说点什么吧" rows="5"></textarea>
                        <span id="remarkError" class="text-danger" ref="remarkError">{{remarkErrorMsg}}</span>
                    </div>
                </div>
            </div>
        </div>

        <span slot="footer" class="dialog-footer">
            <el-button @click="hide">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </span>
    </el-dialog> 
</template>
<script>

const mailReg = /^(\w+([-+.'']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*,?)+$/gi;

export default {
    props:{
        recommendationId:{
            type: String,
            default: ''
        },
    },
    data(){
        return{
            dialogVisible: false,
            myrecommendationId: this.recommendationId,
            realName: this.$store.state.user.userInfo.realName,
            emailLoading: false,
            limitEmailCount: 10,
            emailErrorMsg: '',
            emailArr: [],
            remarkErrorMsg: '',
            limitRemarkLength: 1000,
            remark: '',
            options: [],
        }
    },
    watch:{
        recommendationId(val){
            this.myrecommendationId=val;
        }
    },
    computed:{
        emails() {
            return this.emailArr.replace("，", ",").split(',');
        },
        previewLinkUrl() {
            if(this.myrecommendationId){
               return '/Sharing/' + this.myrecommendationId + '/Preview';
            }else{
                return 'javascript:void(0);';
            }
        }
    },
    mounted(){
        // this.content = this.$refs.candidateemailsharetemplate;
        // this.emailError = this.$refs.emailError;
        // this.remarkError = this.$refs.remarkError;
        // this.emails = this.$refs.textareaemails,
        // this.remark = this.$refs.textarearemark;


        
        // ko.applyBindings(this, this.$content.get(0));
        // this._spinner = this.$refs.spinner;

        // let candidateBaseInfo = ko.components.querySelector('candidate-base-info');
        // if(candidateBaseInfo) {
        //     this.realName(candidateBaseInfo.realName());
        //     candidateBaseInfo.realName.subscribe((realName) => {
        //         this.realName(realName);
        //     })
        // }
    },
    methods: {
        show(id, realName){
            if(id){
                this.myrecommendationId=id;
            }
            if(realName) {
                this.realName=realName;
            }
            this.dialogVisible = true;
            // if(this.modal){
            //     this.modal.show();
            // }else{
            //     this.modal = $(window).modalDialog(extend({
            //         content: this.$content,
            //         okCallBack: this.modalOkCallback.bind(this)
            //     }, modalParam));
            // }
        },
        refresh(id){
            this.myrecommendationId=id;
            this.loadSharedEmails();
        },
        handleConfirm(){
            if (this.emailArr.length == 0) {
                this.emailErrorMsg = "邮箱不能为空";
                return;
            }

            for (let i = 0; i < this.emailArr.length; i++) {
                mailReg.lastIndex = 0;
                if (!mailReg.test(this.emailArr[i])) {
                    this.emailErrorMsg = "邮箱格式不正确";
                    return;
                }
            }
            if (this.emailArr.length > this.limitEmailCount) {
                this.emailErrorMsg = `最多只能转发${this.limitEmailCount}个邮箱`;
                return;
            }

            if (this.remark.length > this.limitRemarkLength) {
                this.$remarkErrorMsg = `留言最多只能${this.limitRemarkLength}字`;
                return;
            }
            this.remarkErrorMsg = '';
            this.emailErrorMsg = '';
            this.hide();
            this.sendEmail();
        },
        sendEmail(){
            var self = this;
            _request({
                url: `/Candidate/${this.myrecommendationId}/Share`,
                method: 'POST',
                data: {
                    emails: this.emailArr.join(','),
                    remark: this.remark
                }
            }).then(res => {
                this.emailArr = [];
                this.remark = '';
                shortTips("发送成功");
                this.loadSharedEmails();
                this.$emit('emailshare-success');
            })
        },
        hide(){
            this.dialogVisible = false;
        },
        loadSharedEmails(){
            this.emailLoading = true;
            _request({
                url: `/Candidate/${this.myrecommendationId}/SharedEmails`,
                method: 'GET'
            }).then(res => {
                this.options = res;
            }).finally(() => {
                this.emailLoading = false;
            })
        }
    }
}
</script>

<style lang="scss">

    .candidate-email-share{
        &::after {
            content: '';
            position:relative;
            clear: both;
        }

        .select2-container-multi .select2-choices {
            min-height: 85px;
        }

        .select2-container-multi .select2-choices {
            background-image: none;
            border: 2px solid #e6e6e6 !important;
            border-radius: 5px;
            min-height: 85px;
        }

        .resume-real-name {
            padding: 0 3px;
        }

        .email-select {
            width: 100%;

            .el-select__tags {
                top: 3px;
                transform: none;
            }

            .el-input {
                min-height: 100px;
                border: 1px solid #ddd;
                border-radius: 4px;
            }
            .el-input__inner {
                height: 40px!important;
                border: 0 none;
            }

            .el-input__suffix {
                display: none;
            }
        }
    }
    .text-area{
        .select2-choices{
            min-height: 85px !important;
        }
    }
</style>