<template>
    <div>
        <!-- <div class="candidate-timeline col-16 row row-overflow"> -->
        <div class="candidate-timeline row row-overflow">
            <!-- <h2 class="title">
                <span class="title-text">操作日志</span>
            </h2> -->
            <!-- <div class="timeline-list-box col-16"> -->
            <div class="timeline-list-box">
                <div class="tiemline-header">
                    <!-- <div class="pull-left editor-list" v-if="!isCFUser">
                        <p>简历贡献者：</p>
                        <ul class="clearfix">
                            <li v-for="item in editors">
                                <el-tooltip class="item" effect="dark" content="简历创建者" placement="top">
                                    <i v-if="item.isCreator" class="icon-font icon-huangguan gold"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="重要贡献者" placement="top">
                                    <i v-if="!item.isCreator && item.isImportant" class="icon-font icon-huangguan green"></i>
                                </el-tooltip>
                                <avatar :guid="item.userId" :src="item.avatarUrl" :alt="item.nickName" size="md"></avatar>
                                <span class="user-name" v-text="item.nickName"></span>
                            </li>
                        </ul>
                    </div>
                    <div class="pull-left editor-list editor-height" v-if="isCFUser"></div> -->
                    <div class="pull-right check-edit-list" v-if="isFirm">
                        <el-dropdown trigger="hover" placement="bottom-start">
                            <el-button type="primary">
                                {{logSwitchBtnText}}
                                <i class="el-icon-caret-top el-icon--right owner-log-tip-hover"></i>
                                <i class="el-icon-caret-bottom el-icon--right owner-log-tip"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown" class="timeline-dropdown">
                                <el-dropdown-item @click.native="onFilterLogs(2, '查看编辑日志')">查看编辑日志</el-dropdown-item>
                                <el-dropdown-item @click.native="onFilterLogs(0, '查看全部日志')">查看全部日志</el-dropdown-item>
                                <el-dropdown-item v-show="isCFUser && isAdministrator" @click.native="onFilterLogs(20, '简历归属权日志')">简历归属权日志</el-dropdown-item>
                                <el-dropdown-item v-show="isCFUser && isAdministrator" @click.native="onFilterLogs(8, '修改复用权益者')">修改复用权益者</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>

                <!-- <div class="order-container col-15" :class="!isCFUser ? 'timeline-list' : ''"> -->
                <div class="order-container" :class="!isCFUser ? 'timeline-list' : ''">
                    <div class="order-wrapper" v-loading="loading">
                        <ul class="order-full">
                            <li class="order-item" v-for="(item, index) in items" :key="index">
                                <div class="timeline-item">
                                    <!--<avatar class="timeline-item-photo" :src="item.creatorAvatar" :enable-link="false"></avatar>-->
                                    <div class="timeline-item-info" v-if="item.isHistorical">
                                        <p>
                                            <span class="log-owner" v-text="item.creatorRealName + '@' + item.creatorNickName"></span>
                                            <span class="text-grey" v-text="item.created"></span>
                                        </p>
                                        <p v-if="item.type !== 2 && item.type !== 16" class="log-detail">
                                            <span v-html="item.content"></span>
                                        </p>
                                        <p v-if="item.type === 4" class="log-detail">
                                            <span v-html="item.content"></span>
                                            <i class="fa fa-paperclip"></i>
                                            <a class="attachment" v-text="item.paramOne"></a>
                                        </p>
                                        <p v-if="item.type === 10" class="log-detail">
                                            <span>覆盖更新简历由</span>
                                            <span class="text-primary" @click="onResumeVersionClick(item, list.items[index + 1],0)"> 历史版本 </span>
                                            <span>更改为</span>
                                            <span class="text-primary" @click="onResumeVersionClick(item, list.items[index + 1], 1)"> 新版本 </span>
                                        </p>

                                        <template>
                                            <div class="log-detail" v-for="(param, index) in item.items" :key="index">
                                                <p v-if="param.historicalType !== 4 && param.historicalType !== 10" class="log-detail">
                                                    <span v-html="param.content"></span>
                                                </p>
                                                <p v-if="param.historicalType === 4" class="log-detail">
                                                    <span v-html="param.content"></span>
                                                    <i class="fa fa-paperclip"></i>
                                                    <a class="attachment" v-text="param.paramOne"></a>
                                                </p>
                                                <p v-if="param.historicalType === 10 && param.isStandardResume !== true" class="log-detail per-text">
                                                    <span>覆盖更新简历由</span>
                                                    <span class="text-primary" @click="onResumeVersionClick(param, item.items[index + 1],0)"> 历史版本 </span>
                                                    <span>更改为</span>
                                                    <span class="text-primary" @click="onResumeVersionClick(param, item.items[index + 1], 1)"> 新版本 </span>
                                                </p>
                                            </div>
                                        </template>

                                    </div>

                                    <div class="timeline-item-info" v-if="!item.isHistorical && operationLogContent(item, item.type)">
                                        <p>
                                            <span class="log-owner" v-if="item.creatorRealName == '系统内置用户'" v-text="item.creatorRealName"></span>
                                            <span class="log-owner" v-else v-text="item.creatorRealName + '@' + item.creatorNickName"></span>
                                            <span class="text-grey" v-text="item.created"></span>
                                        </p>

                                        <template>
                                            <p class="log-detail" v-if="item.type !== 2 && item.type !== 21">
                                                <span v-html="operationLogContent(item,item.type)"></span>
                                            </p>

                                            <span class="log-detail" v-if="item.type === 2">
                                                <p class="log-detail" v-if="item.content.editLog">
                                                    <span>{{ editType[item.content.editLog.editType] }}，完整度变化：{{item.content.editLog.oldCompletion.toFixed(2)}} => {{item.content.editLog.newCompletion.toFixed(2)}}，编辑后简历类型：{{resumeTypeHashMap[item.content.editLog.resumeType]}}</span>
                                                </p>
                                                <p class="log-detail" v-if="item.content.contentLog">
                                                    <span>覆盖更新简历由</span>
                                                    <span class="text-primary" @click="onNewResumeVersionClick(item.content.contentLog.oldContent, item.content.contentLog.oldStandardContent, 0)"> 历史版本 </span>
                                                    <span>更改为</span>
                                                    <span class="text-primary" @click="onNewResumeVersionClick(item.content.contentLog.newContent, item.content.contentLog.newStandardContent, 1)"> 新版本 </span>
                                                    <span v-if="item.content.editLog && item.content.editLog.isUploadResume">R</span>
                                                </p>
                                                <p class="log-detail" v-for="(param,index) in item.content.fieldLogs" :key="index">
                                                    <span>{{ fildNameHashMap[param.fieldName] }}由 {{ param.oldValue }} 更改为 {{ param.newValue }}</span>
                                                </p>
                                            </span>

                                            <p class="log-detail" v-if="item.type === 21">
                                                <span v-if="item.content.current">{{ item.creatorRealName }}修改归属权，当前归属者由【{{ item.content.current.oldBelonger }}】变为【{{item.content.current.newBelonger}}】，生效日期为{{ item.content.current.belongerDate }}，简历类型为{{ resumeTypeHashMap[item.content.current.resumeType] }}；<br></span>
                                                <span v-if="item.content.history">历史归属者由【{{ item.content.history.oldBelonger }}】变为【{{ item.content.history.newBelonger }}】，生效日期为{{ item.content.history.belongerDate }}，简历类型为{{ resumeTypeHashMap[item.content.history.resumeType] }}。</span>
                                            </p>
                                        </template>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <order ref="timelineList" class="col-15" :class="{'timeline-list':!isCFUser}">
                </order> -->
                <!-- <div v-show="listEmpty" class="col-15 timeline-limited">没有更多数据</div>
                <div v-show="moreBtn" class="col-15 timeline-load-more more-mouse" @click="loadMore">加载更多</div> -->
                <div v-show="listEmpty" class="timeline-limited">没有更多数据</div>
                <div v-show="moreBtn" class="timeline-load-more more-mouse" @click="loadMore">加载更多</div>
            </div>
        </div>

        <el-dialog
            ref="modal"
            :title="resumeVersionCaption"
            :visible.sync="versionDialogVisible"
            width="700px"
            class="resume-version-dialog"
            :before-close="handleClose">
            <el-tabs v-model="tabFirst" type="border-card">
                <el-tab-pane :label="`${resumeVersionCaption}简历原文`" name="first">{{ resumeVersionContent.now }}</el-tab-pane>
                <el-tab-pane :label="`${resumeVersionCaption}标准简历`" name="second">{{ resumeVersionContent.standard }}</el-tab-pane>
            </el-tabs>
        </el-dialog>
        
        <el-dialog
            ref="resumeOwnership"
            :title="modalTitle"
            :visible.sync="ownerDialogVisible"
            v-loading="ownerModalLoading"
            width="608px"
            :before-close="ownerHandleClose">
            <div class="owner-wrap">
                <div class="owner-item">
                    <p class="current-owner">
                        将当前归属者【{{defaultBelonger.firstBelongerUserName}}】修改为<br />
                    </p>
                    <div class="current-info-list">
                        <el-select popper-class="current-info-list" v-model="firstBelonger.firstBelongerUserName" @change="handleUpdateUserName('firstBelonger')" filterable placeholder="请输入，或者选择用户">
                            <el-option
                                v-for="item in customerList"
                                :key="item.userId"
                                :label="item.realName"
                                :value="item.userId ? item.userId + ',' + item.realName : '无'"
                                @click.native="selectClick">
                            </el-option>
                        </el-select>
                        <el-date-picker
                            v-model="firstBelonger.firstBelongerDate"
                            type="date"
                            placeholder="归属权生效日期">
                        </el-date-picker>
                        <el-select class="candidate-type" v-model="firstBelonger.firstBelongerResumeType" placeholder="简历类型">
                            <el-option
                                v-for="item in resumeType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="owner-item">
                    <p class="current-owner">
                        将历史归属者【{{defaultBelonger.secondBelongerUserName}}】修改为<br />
                    </p>
                    <div class="current-info-list">
                        <el-select popper-class="current-info-list" v-model="secondBelonger.secondBelongerUserName" @change="handleUpdateUserName('secondBelonger')" filterable placeholder="请输入，或者选择用户">
                            <el-option
                                v-for="item in customerList"
                                :key="item.userId"
                                :label="item.realName"
                                :value="item.userId ? item.userId + ',' + item.realName : '无'">
                            </el-option>
                        </el-select>
                        <el-date-picker
                            v-model="secondBelonger.secondBelongerDate"
                            type="date"
                            placeholder="归属权生效日期">
                        </el-date-picker>
                        <el-select class="candidate-type" v-model="secondBelonger.secondBelongerResumeType" placeholder="简历类型">
                            <el-option
                                v-for="item in resumeType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="ownerDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="handleChange">确定</el-button>
            </span>
        </el-dialog>
        
    </div>
</template>

<script>
    import { candidate as candidateDetailConfig } from '#/js/config/api.json';
    
    const resumeTypeMatch = {
        0:'普通简历',
        1:'Profile简历',
        2:'完整简历'
    };

    const keyIndexMap = {
        'realName' : 0,
        'mobile' : 1,
        'email' : 2,
        'location' : 3,
        'title' : 4,
        'company' : 5,
        'yearOfExperience' : 6,
        'school' : 7,
        'degree' : 8,
        'major' : 9,
        'gender' : 10
    };

    export default {
        components: {
            // Modal,
        },
        data() {
            return {
                loading: false,
                ownerModalLoading: false,
                items: [],
                dataArray: [],
                resumeVersionCaption: '',
                resumeVersionContent: {
                    standard: '',
                    now: ''
                },
                tabFirst: 'first',
                filterType: 0,
                editors: [],
                moreBtn: false,
                listEmpty: false,
                modalTitle: '',
                eanbleOwnerHeader: true,
                logSwitchBtnText: '查看全部日志',
                firstBelonger: {
                    firstBelongerDate: '',
                    firstBelongerResumeType: '',
                    firstBelongerUserId: '',
                    firstBelongerUserName: ''
                },
                secondBelonger: {
                    secondBelongerDate: '',
                    secondBelongerResumeType: '',
                    secondBelongerUserId: '',
                    secondBelongerUserName: ''
                },
                defaultBelonger: {
                    firstBelongerUserName: '',
                    secondBelongerUserName: ''
                },
                customerList: [],
                resumeType: [
                    {value: 0, label: '普通简历'},
                    {value: 1, label: 'Profile简历'},
                    {value: 2, label: '完整简历'},
                ],
                editType: {
                    0: '普通编辑',
                    1: '关键信息编辑',
                    2: '完整简历编辑'
                },
                resumeTypeHashMap: {
                    0: '普通简历',
                    1: 'Profile简历',
                    2: '完整简历'
                },
                fildNameHashMap: {
                    realName: '姓名',
                    mobile: '手机号',
                    email: '邮箱',
                    wechat: '微信',
                    location: '城市',
                    title: '职位',
                    company: '公司',
                    yearOfExperience: '工作年限',
                    school: '毕业院校',
                    degree: '学历',
                    major: '专业',
                    gender: '性别',
                },
                belongResonMap: {
                    0: '创建简历',
                    1: '重大更新',
                    2: '完整简历更新',
                    3: '人工修改'
                },
                originListUrl:'',
                filterListUrl: '',
                versionDialogVisible: false,
                ownerDialogVisible: false,
            }
        },
        props: {
            candidateId: String,
            candidateRealName: {
                type: String,
                default: ''
            },
            isFirm: '',
        },
        computed:{
            isCFUser(){
                return this.$store.state.user.userInfo.isCFUser;
            },
            isAdministrator(){
                return this.$store.state.user.userInfo.isAdministrator;
            }
        },
        mounted () {
            this.modal = this.$refs.modal;
            this.ownerModal = this.$refs.resumeOwnership;
            // this.init();
        },
        methods: {
            init(id){
                // this.originListUrl = placeholder(candidateDetailConfig.url_operate_log, id);
                this.originListUrl = candidateDetailConfig.url_operate_log.replace(/%p/, id);
                this.filterListUrl = this.originListUrl;
                this.getListData();
                // this.list.url = this.originListUrl;
                // this.list.fetch();
                this.getCandidateEditors(id);
            },
            getListData() {
                this.loading = true;
                _request({
                    url: this.filterListUrl,
                    method: 'GET'
                }).then(res => {
                    if(res.list && res.list.length > 0) {
                        if(res.list.length < 10) {
                            this.moreBtn = false;
                        } else {
                            this.moreBtn = true;
                        }
                        // this.moreBtn = true;
                        this.listEmpty = false;
                        this.dataArray = res.list || [];
                        this.items = this.dataArray.splice(0,10);
                    }else {
                        this.moreBtn = false;
                        this.listEmpty = true;
                        this.dataArray = [];
                        this.items = [];
                    }
                }).finally(() => {
                    this.loading = false;
                })

            },

            refresh() {
                this.getListData();
            },

            loadMore() {
                this.items = this.items.concat(this.dataArray.splice(0,10));
                if(this.dataArray.length == 0){
                    this.moreBtn = false;
                    this.listEmpty = true;
                }
            },

            getCandidateEditors(id){
                if(!this.isCFUser){
                    _request({
                        url: candidateDetailConfig.url_candidate_contributors.replace(/%p/, id),
                        method: 'GET'
                    }).then(res =>{
                        this.editors = res;
                    })
                }
            },

            onResumeVersionClick(vm,vm2,type,ev) {
                if(!vm2) {
                    vm2 = {
                        paramTwo: '',
                        paramOne: '',
                    }
                }
                if(type === 1){
                    this.resumeVersionCaption = '新版本';
                    this.resumeVersionContent.now = vm.paramTwo;
                    this.resumeVersionContent.standard = vm2.paramTwo;
                }else{
                    this.resumeVersionCaption = '历史版本';
                    this.resumeVersionContent.now = vm.paramOne;
                    this.resumeVersionContent.standard = vm2.paramOne;
                }
                // this.modal.show();
                this.versionDialogVisible = true;
            },
            onNewResumeVersionClick (now,standard,type) {
                this.resumeVersionCaption = type === 1 ? '新版本' : '历史版本';
                this.resumeVersionContent.now = now;
                this.resumeVersionContent.standard = standard;
                // this.modal.show();
                this.versionDialogVisible = true;
            },
            onFilterLogs(type,text) {
                this.logSwitchBtnText = type !== 8 ? text : this.logSwitchBtnText;
                if(type === 2){
                    this.filterListUrl = this.originListUrl + '/' + type;
                }else if(type === 0){
                    this.filterListUrl = this.originListUrl;
                }else if(type === 20) {
                    this.filterListUrl = this.originListUrl + '/' + type;
                }else {
                    this.getCustomers();
                    this.getOwnerInfo().then(() =>{
                        this.modalTitle = `修改 【${this.candidateRealName}】 归属者`;
                        // this.ownerModal.show();
                        this.ownerDialogVisible = true;
                    })
                    return false;
                }

                if(type !== 8) {
                    this.moreBtn = false;
                    this.getListData();
                }

                this.filterType = type;
            },

            getOwnerInfo() {
                return _request({
                    url: candidateDetailConfig.url_resume_owner.replace(/%p/, this.candidateId),
                    method: 'GET'
                }).then(res => {
                    if(!res.firstBelongerUserName) {
                        res.firstBelongerDate = '';
                        res.firstBelongerResumeType = '';
                    }
                    if(!res.secondBelongerUserName) {
                        res.secondBelongerDate = '';
                        res.secondBelongerResumeType = '';
                    }
                    this._assinInfo(this.firstBelonger, res);
                    this._assinInfo(this.secondBelonger, res);
                    this._assinInfo(this.defaultBelonger, res);
                })
            },

            _assinInfo(target, source) {
                Object.keys(target).forEach(item =>{
                    target[item] = source[item];
                })
            },

            getCustomers() {
                _request({
                    url: '/MyFirm/TeamMembers',
                    method: 'GET',
                    data: {
                        take: -1,
                        location: 0,
                        teamId: 0
                    }
                }).then(res =>{
                    this.customerList = [{ userId: '', realName: '' }].concat(res.list);
                })
            },

            handleChange() {
                if(!this.handleValide()){
                    return false;
                }

                let curFirstBelonger = Object.assign({}, this.firstBelonger);
                curFirstBelonger.firstBelongerDate = curFirstBelonger.firstBelongerDate || '0001-01-01T00:00:00';
                curFirstBelonger.firstBelongerResumeType = curFirstBelonger.firstBelongerResumeType || 0;

                let curSecondBelonger = Object.assign({}, this.secondBelonger);
                curSecondBelonger.secondBelongerDate = curSecondBelonger.secondBelongerDate || '0001-01-01T00:00:00';
                curSecondBelonger.secondBelongerResumeType = curSecondBelonger.secondBelongerResumeType || 0;

                this.ownerModalLoading = true;
                _request({
                    url: candidateDetailConfig.url_update_owner,
                    method: 'POST',
                    data: Object.assign({}, curFirstBelonger, curSecondBelonger, {candidateId: this.candidateId})
                }).then(res =>{
                    shortTips('修改成功');
                    // this.ownerModal.hide();
                    this.ownerDialogVisible = false;
                    this.onFilterLogs(20, '简历归属权日志');
                }).finally(() => {
                    this.ownerModalLoading = false;
                })
            },

            handleUpdateUserName(type) {
                if(this[type][type + 'UserName'] === '无') {
                    this[type][type + 'UserName'] = '';
                    this[type][type + 'UserId'] = '';
                    this[type][type + 'Date'] = '';
                    this[type][type + 'ResumeType'] = '';

                    return false;
                }

                let data = this[type][type + 'UserName'].split(',');
                this[type][type + 'UserName'] = data[1];
                this[type][type + 'UserId'] = data[0];
                if(type === 'firstBelonger') {
                    if(this.firstBelonger.firstBelongerDate === '') {
                        this.firstBelonger.firstBelongerDate = new Date().toLocaleDateString();
                    }
                }
                if(type === 'secondBelonger') {
                    if(this.secondBelonger.secondBelongerDate === '') {
                        this.secondBelonger.secondBelongerDate = new Date().toLocaleDateString();
                    }
                }
            },

            handleValide() {
                let first = this.firstBelonger;
                let second = this.secondBelonger;

                if(first.firstBelongerUserId || first.firstBelongerUserName || first.firstBelongerDate !== '' || first.firstBelongerResumeType !== '') {
                    if(first.firstBelongerUserId
                        && first.firstBelongerUserName
                        && first.firstBelongerDate
                        && first.firstBelongerResumeType !== ''){
                    }else {
                        shortTips('当前归属者的用户、归属权、简历类型三者需要填写完整');
                        this.ownerModal.spinner.sleep();
                        return false;
                    }
                }

                if(second.secondBelongerUserId || second.secondBelongerUserName || second.secondBelongerDate !== '' || second.secondBelongerResumeType !== '') {
                    if(second.secondBelongerUserId
                        && second.secondBelongerUserName
                        && second.secondBelongerDate
                        && second.secondBelongerResumeType !== ''){

                    }else {
                        shortTips('历史归属者的用户、归属权、简历类型三者需要填写完整');
                        this.ownerModal.spinner.sleep();
                        return false;
                    }
                }
                return true;
            },

            selectClick(e){
                console.log(1);
                window.event.stopPropagation();
                let el = e.target || e.srcElement;
                $(el).closest('.el-scrollbar__view.el-select-dropdown__list').find('.active').removeClass('active');
                return $(el).closest('.el-select-dropdown__item').addClass('active');
            },
            operationLogContent (item, type){
                let content,status=false;
                if(item.content.constructor === String) {
                    content = item.content = item.content ? JSON.parse(item.content) : {};
                } else {
                    status = true;
                    content = item.content;
                }
                switch (type) {
                    case 1:
                        return `添加了 ${content.realName}`;
                    case 11:
                        return `导入了 ${content.realName} 至企业库`;
                    case 12:
                        return content.type === 1 ? `查看手机号` : (content.type === 2 ? `查看邮箱` : '查看微信');
                    case 13:
                        return `推荐至 ${content.customerName} 的 ${content.jobName}`;
                    case 16:
                        // type=1 推荐附件 2简历附件
                        if(content.type === 1 && item.objectId) {
                            return `${content.type === 0 ? '' : '在推荐记录中'} 上传人才附件<span class="fa fa-paperclip"></span><span class="text-primary" style="cursor: default;" class="document-anchor">${content.fileName}（${content.fileSize}KB）</span>`
                            //  href="${location.origin}/Data/File/${item.objectId}"
                        }
                        return `${content.type === 0 ? '' : '在推荐记录中'} 上传人才附件<span class="fa fa-paperclip"></span><span class="text-primary" style="cursor: default;">${content.fileName}（${content.fileSize}KB）</span>`;
                    case 20:
                        return `${content.newBelonger}${this.belongResonMap[content.belongReson]}，当前归属者为【${content.newBelonger}】，简历类型为${this.resumeTypeHashMap[content.resumeType]}，历史归属者为【${content.oldBelonger || '无'}】。`;
                    case 21:
                        return content.current || content.history;
                    case 22:
                        let html = '';
                        content.map((item,index) => {
                            html = `${html}${index + 1}.【${item.recommendUser}】推荐到【${item.jobName}】的分成比例由【权益者-${item.oldProportion.firstEquityUser || '无'}：${item.oldProportion.firstEquityProportion}%，权益者-${item.oldProportion.secondEquityUser || '无'}：${item.oldProportion.secondEquityProportion}%，推荐人-${item.recommendUser}：${item.oldProportion.recommendProportion}%】变为【权益者-${item.newProportion.firstEquityUser}：${item.newProportion.firstEquityProportion}%，权益者-${item.newProportion.secondEquityUser}：${item.newProportion.secondEquityProportion}%，推荐人-${item.recommendUser}：${item.newProportion.recommendProportion}%】<br>`
                        })
                        return html;
                    case 23:
                        return content.type === 1 ? `标记手机号码失效` : (content.type === 2 ? `标记邮箱失效` : '标记微信失效');
                    case 31:
                        return content.type === 1 ? `激活手机号码` : (content.type === 2 ? `激活邮箱` : '激活微信');
                    case 2:
                        if(content.fieldLogs && !status){
                            content.fieldLogs.sort((item1,item2) => {
                                if(keyIndexMap[item1.fieldName] < keyIndexMap[item2.fieldName]){
                                    status = true;
                                    return  -1;
                                }
                                return 1;
                            })
                        }
                    case 15:
                        return '请求分享';
                    case 28:
                        return content.isCoverSuccess ? "上传重复简历-已覆盖" : "上传重复简历";
                    case 29:
                        return '简历合并';
                    case 32:
                        return `公开了推荐附件<span class="fa fa-paperclip"></span><span class="text-primary" style="cursor: default;" class="document-anchor">${content.fileName}（${content.fileSize}KB）</span>`
                    default:
                        return '';
                }
                return item.content = content;
            },
            handleClose() {
                this.versionDialogVisible = false;
            },
            ownerHandleClose() {
                this.ownerDialogVisible = false;
            }
            
        }
    }
</script>

<style lang="scss">
    @import "./candidate-timeline.scss";
    .timeline-log-text{
        white-space: pre-wrap;
    }
    .more-mouse{
        cursor: pointer;
    }
    .timeline-dropdown{
        &.el-popper[x-placement^="bottom"] {
            margin-top: 5px;
        }

        &.el-dropdown-menu {
            width: 145px;

            .popper__arrow {
                display: none!important;
            }
        }
    }

    .resume-ownership-dialog {

        .owner-wrap {
            padding-left: 20px;

            .owner-item {
                &:first-child {
                    margin-bottom: 30px;
                }

                .current-owner {
                    margin-bottom: 10px;
                }

                .el-select {
                    width: 210px;
                }

                .el-date-editor {
                    width: 160px;
                    margin: 0 10px;
                }

                .candidate-type {
                    width: 134px;
                }
            }
        }
    }

    .current-info-list{
        .el-select {
            width: 210px;
        }
        .el-date-editor {
            margin: 0 10px;
            width: 160px;
        }
        .candidate-type {
            width: 134px;
        }

        .el-select-dropdown__item{

            &.active{
                color: $primary;
                background-color: rgba($primary, .1);
            }

            &:hover,&.hover{
                color: $primary;
                background-color: rgba($primary, .1);
            }
        }
    }

    .candidate-timeline .tiemline-header .editor-height{
        height:60px;
    }

    .resume-version-dialog{
        .el-dialog__body {
            padding-bottom: 30px;
        }
        .el-tabs__content {
            height: 500px;
            padding-top: 0;
            margin-top: 15px;
            overflow-y: scroll;
        }
        .el-tab-pane{
            white-space: pre-line;
        }
    }

    .log-detail{
        white-space: pre-line;

        &.per-text{
            margin: 10px 0 !important;
        }
    }
</style>
