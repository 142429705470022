<template>
    <form ref="candidateResumeWorkForm" class="candidate-resume-work-form col-15" id="candidate-resume-work-form" autocomplete="off" v-loading="loading">
        <input type="hidden" name="Id" v-bind:value="formData.id">

        <div class="form-group col-14 flex-center">
            <!-- <checkbox :checked="formData.isLatestWorkExperience" @on-click='AddWork' :disable='isCheckboxLocked' identity="isLatestWorkExperience" class="label-select label-select-margin"></checkbox> -->
            <el-checkbox 
                v-model="formData.isLatestWorkExperience"
                :disabled="isCheckboxLocked"
                size="medium"
                class="last-experience-checkbox"
            ></el-checkbox>
            <label class="label-select-text">设为最近工作经历</label>
        </div>

        <div class="form-group col-7">
            <label class="control-label required before-none" for="company">公司</label>
            <div class="col-sm-12">
                <customer-select-or-write
                    v-if="formData.company || formData.isAdd"
                    :customerId.sync="formData.customerId"
                    :customerName.sync="formData.company"
                    @finish-callback="finishCallback"
                    />
            </div>
        </div>

        <div class="form-group col-7" v-if="formData.isLatestWorkExperience && formData.customerId && showDepart(formData.tempCustomerId, formData.customerId)">
            <label class="control-label before-none" for="customerDepartmentName">部门</label>
            <div class="col-sm-12">
                <customer-department-select
                    ref="departmentSelect"
                    v-if="formData.isLatestWorkExperience && formData.customerId"
                    :canSrcoll="false"
                    :customerId="formData.customerId"
                    :departmentId="formData.customerDepartmentId"
                    :customerDepartmentName="formData.customerDepartmentName"
                    @update-department="updateDepartment"
                    placeholder='输入完整路径，多级部门用"，"分隔'
                ></customer-department-select> 
            </div>
        </div>

        <div class="form-group col-7">
            <label class="control-label required before-none" for="title">职位</label>
            <div class="col-sm-12">
                <input
                    class="form-control"
                    v-model="formData.title"
                    id="title"
                    name="title"
                    type="text"
                    value="">
            </div>
        </div>

        <div class="form-group col-7">
            <label class="control-label" for="startDate">开始时间</label>
            <div class="col-sm-12">
                <el-date-picker
                    class="start-date-picker"
                    style="width: 100%"
                    v-model="formData.startDate"
                    type="month"
                    placeholder="如 2018-08"
                    value-format="yyyy-MM"
                    :picker-options="startDateOptions"
                >
                </el-date-picker>
                
            </div>
        </div>

        <div class="form-group col-7">
            <label class="control-label" for="endDate">结束时间</label>
            <div class="col-sm-12">
                <el-date-picker
                    ref="endDatePicker"
                    class="end-date-picker"
                    style="width: 100%"
                    v-model="formData.endDate"
                    type="month"
                    placeholder="如 2018-08"
                    value-format="yyyy-MM"
                    :picker-options="endDateOptions"
                >
                </el-date-picker>
            </div>
        </div>

        <div class="form-group col-7">
            <label class="control-label" for="locationId">地区</label>
            <div class="col-sm-12">
                <city-select ref="citySelect" @citySelected="handleCitySelected"></city-select>
            </div>
        </div>

        <div class="form-group col-14">
            <label class="control-label" for="description">工作内容</label>
            <div class="col-sm-12">
            <textarea
                rows="3"
                class="form-control"
                v-model="formData.description"
                id="description"
                name="description"
                type="text"
                value=""></textarea>
            </div>
        </div>

        <div class="form-group col-14">
            <div class="col-sm-6">
                <input class="btn btn-primary submitBtn btn-background" type="button" value="保存" @click="_submitValidate">
                <input type="button" value="取消" class="btn btn-default cancelBtn" @click="_cancel">
            </div>
            <div class="col-sm-2 pull-right">
                <div class="delete-btn" v-show="!isCheckboxLocked" @click="_delete">删除本条</div>
            </div>
        </div>
    </form>
</template>

<script>
    import resumeItemType from '#/js/config/resumeItemType.json';
    import { isNaN } from '#/js/util/tools.js';
    import { min as getMin, max as getMax} from '#/js/util/validators.js';

    import CitySelect from '#/component/common/city-select.vue';
    import CustomerDepartmentSelect from '#/component/common/customer-department-select.vue'
    import CustomerSelectOrWrite from '#/component/common/customer-select-or-write.vue';

    let defaultFormObject = {
        id: '',
        company: '',
        title: '',
        startDate: '',
        endDate: '',
        location: '',
        description: '',
        isLatestWorkExperience: '',
        customerDepartmentName: '',
        customerDepartmentId: '',
        customerId: '',
        tempCustomerId: '',
        currentCompany: ''
    }

    export default {
        components: {
            // Checkbox,
            CitySelect,
            CustomerDepartmentSelect,
            CustomerSelectOrWrite,
        },
        data () {
            let self = this;
            return {
                formData: Object.assign({}, defaultFormObject),
                type: resumeItemType.workExperiences,
                hasSetCityData: false,
                isCheckboxLocked: false,
                loading:false,
                startDateOptions: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    }
                },
                endDateOptions: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    },
                    shortcuts: [{
                        text: '至今',
                        onClick(picker) {
                            self.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                            self.$refs.endDatePicker.handleClose();
                        }
                    }]
                }
            }
        },
        props: {
            canEdit: {
                type: Boolean,
                default: true
            },
            candidateId:String,
            editUrl:String,
            addUrl:String,
        },
        computed: {
            showDepart() {
                return (tempCustomerId, customerId) => {
                    // 有tempId
                    if(!!tempCustomerId) {
                        return customerId == tempCustomerId || this.formData.currentCompany == this.formData.company;
                    } else {
                        return !!customerId;
                    }
                }
            }
        },
        mounted () {
            this.form = this.$refs.candidateResumeWorkForm;
            this.citySelect = this.$refs.citySelect;
            // this.initEvent();
        },
        methods: {
            updateDepartment(department = {}) {
                this.formData.customerDepartmentId = department.id;
                this.formData.customerDepartmentName = department.departmentName;
            },
            finishCallback () {
                this.formData.customerDepartmentId = null;
                this.formData.customerDepartmentName = '';

                this.$nextTick(() => {
                    this.$refs.departmentSelect && this.$refs.departmentSelect.reset();
                });
            },
            AddWork(vm){
                if (vm.$data.active) {
                    this.formData.isLatestWorkExperience=true;
                } else {
                    this.formData.isLatestWorkExperience=false;
                }
            },
            _cancel () {
                this.citySelect.reset();
                this.$emit('resume-work-form-cancel');
                this.isCheckboxLocked=false;
            },

            _delete () {
                this._showConfirmDialog();
            },

            _reset () {
                this.formData = Object.assign({}, defaultFormObject);
                this.citySelect.reset();
                this.formData.isLatestWorkExperience=false;
                this.formData['isAdd'] = true;
                this.isCheckboxLocked=false;
            },

            _refresh () {
                this.$emit('candidate-detail-refresh')
            },

            _submitValidate () {
                let formData = this.formData;
                let isValid = true;

                delete formData.__ko_mapping__;
                formData = this.trimValue(formData);

                for (var key in formData) {
                    let value = formData[key];
                    if (_isDangerousString(value)) {
                        isValid = false;
                        shortTips('您输入的内容包含非法字符，请重新输入');
                        break;
                    }

                    if (!isValid) break;

                    switch (key) {
                        case 'company':
                            if (!(getMin(1, value))) {
                                isValid = false;
                                shortTips('公司不能为空');
                            } else if (!(getMax(128, value))) {
                                isValid = false;
                                shortTips('公司最长支持128个字符');
                            }
                            break;
                        case 'title':
                            if (!(getMin(1, value))) {
                                isValid = false;
                                shortTips('职位不能为空');
                            } else if (!(getMax(128, value))) {
                                isValid = false;
                                shortTips('职位最长支持128个字符');
                            }
                            break;
                        case 'startDate':
                            if (isNaN(new Date(value).getTime())) {
                                isValid = false;
                                shortTips('开始时间日期格式不符');
                            }
                            break;
                        case 'endDate':
                            let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;
                            if(endDateText != '至今') {
                                if (isNaN(new Date(value).getTime())) {
                                    isValid = false;
                                    shortTips('结束时间日期格式不符');
                                } else if (new Date(value).getTime() < new Date(formData.startDate).getTime()) {
                                    isValid = false;
                                    shortTips('结束时间应大于开始时间');
                                }
                            }
                            break;
                        case 'location':
                            if (!value) {
                                formData.location = 0;
                            }
                            break;
                        case 'isLatestWorkExperience':
                            if (!value) {
                                formData.isLatestWorkExperience = false;
                            }
                            break;
                        case 'description':
                            if (!(getMax(8000, value))) {
                                isValid = false;
                                shortTips('工作内容最长支持8000个字符');
                            }
                            break;
                        default:
                            break;
                    }
                }

                if (isValid) {
                    this.loading = true;
                    this._submitCandidateEdit(formData);
                }
            },

            _submitCandidateEdit (formData) {
                let isEdit = formData.id ? true : false;
                let postUrl = isEdit ? this.editUrl : this.addUrl;

                let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;
                let workExperience = {
                    ...formData,
                    customerDepartmentId: formData.customerDepartmentId || 0
                };
                if(endDateText == '至今') {
                    workExperience.endDate = '9999-12-31';
                }
                _request({
                    url: postUrl,
                    method: 'POST',
                    data: {
                        id: this.candidateId,
                        type: this.type,
                        data: {
                            workExperience
                        }    
                    }
                }).then(res => {
                    this.$emit('cache-select-compnay', this.formData.customerId);
                    this._cancel();
                    this._refresh();
                    shortTips(isEdit ? '编辑成功' : '添加成功');
                    this.citySelect.reset();
                }).finally(() => {
                    this.loading = false;
                })
            },

            _showConfirmDialog(){
                this.$emit("show-delete-dialog", this.type, this.formData.id);
            },

            trimValue (obj) {
                let newOjb = {};
                Object.keys(obj).forEach(key => {
                    newOjb[key] = (typeof(obj[key]) == 'string') ? obj[key].trim() : obj[key];
                })
                return newOjb;
            },

            mergeFormData (data) {
                if (data.id && data.isLatestWorkExperience) {
                    this.isCheckboxLocked = true;
                } else {
                    this.isCheckboxLocked = false;
                }

                Object.assign(this.formData, data);
                this.citySelect.setDefaultCity(this.formData.location);


                if(this.formData.endDate.indexOf('9999') > -1) {
                    this.formData.endDate = '';
                    this.$nextTick(() => {
                        this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                    })
                }
                this.formData.endDate = this.formData.endDate =="0001-01-01T00:00:00" ? new Date(): this.formData.endDate;
                this.formData.startDate = this.formData.startDate =="0001-01-01T00:00:00" ? new Date(): this.formData.startDate;


                if(this.formData.tempCustomerId) {
                    this.formData.customerId = this.formData.tempCustomerId;
                }
                if(this.formData.customerId == '0') {
                    this.formData.customerId = null;
                }
                this.$nextTick(() => {
                    if(this.$refs.departmentSelect && this.formData.customerDepartmentId) {
                        this.$refs.departmentSelect.value = this.formData.customerDepartmentId;
                        this.$refs.departmentSelect.editInit({
                            id: this.formData.customerDepartmentId
                        });
                    }
                })
            },

            initEvent () {
                let self = this;
                this.initAllDateRange();
            },

            handleCitySelected(province, city) {
                this.formData.location = city;
            }
        }

    }
</script>

<style lang="scss">
    @import './candidate-resume-work-form.scss';
    .department-tip{
        // display: block;
        font-size: 12px;
        color: #999;
        line-height: 20px;
        margin-top: 4px;
    }

    .candidate-resume-work-form {
        &.col-15, .col-14 {
            width: 100% !important;
        }
        .col-7 {
            width: 50% !important;
        }
    }
</style>
