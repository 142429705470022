<template>
    <div>
        <div class="attachment-lists" v-loading="loading && type !== 'recommend'">
            <div class="empty-data" v-if="attatchmentList.length == 0 && !loading">
                <span class="empty-img"></span>
                <p>暂无数据</p>
            </div>
            <attachment-item
                v-for="item in attatchmentList"
                :key="item.id"
                :data="item"
                :send="send"
                :remove="remove"
                :download="download"
                :candidate-id="candidateId"
                :resume-id="resumeId"
                :isRecommend="type == 'recommend'"
            ></attachment-item>
            <div class="load-more" v-if="attatchmentList.length > 0 && hasMore" v-loading="bottomLoading" @click="handleLoadMore">
                加载更多
                <font-icon href="#icon-spread-up"></font-icon>
            </div>
        </div>
        <!-- <attachment-to-recommends
            :torecommends="torecommends"
            ref="recommends"
            class="recommends"
            :candidate-id="candidateId"
        ></attachment-to-recommends> -->
    </div>
</template>

<script>
    import {candidate as candidateUrl} from '#/js/config/api.json';
    // 子组件引入
    import AttachmentItem from './attachmentItem.vue';
    // import AttachmentToRecommends from './attachment-to-recommends/index';

    const defaultAttachmentsParam = {
        take: 50
    }

    export default {
        components: {
            AttachmentItem,
            // AttachmentToRecommends
        },
        data() {
            return {
                id: '',
                myrecommendationId: this.recommendationId,
                // isPublisher: server.isPublisher,
                // isRecommender: !server.isPublisher,
                isPublisher: false,
                isRecommender: true,
                attatchmentList: [],
                hasMore: false,
                loading: false,
                bottomLoading: false,
                start: 0,
                take: 50
            }
        },
        props: {
            candidateName: String,
            isFromAuthorization: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: ''
            },
            recommendationId:String,
            torecommends:{
                type:Boolean,
                default:true
            },
            candidateId: String,
            resumeId:String,
        },
        computed: {
            userInfo() {
                return this.$store.state.user.userInfo;
            }
        },
        mounted() {
        },
        watch: {
            attatchmentList: function(val, oldVal) {
                console.log(val);
                console.log(oldVal);
            }
        },
        methods: {
            init(id){
                this.id = id;
                if(this.type != 'recommend') {
                    // this.attachments.url = placeholder(candidateUrl.url_candidate_attachment_list, id);
                    this.getListData();
                }
                
                // 简历发送，暂时隐藏
                // this.recommends.init(id)
            },
            getListData(silent) {
                if(silent) {
                    this.start = this.start + this.take;
                }else {
                    this.start = 0;
                }
                if(this.start == 0) {
                    this.loading = true;
                }else {
                    this.bottomLoading = true;
                }
                let url = '';
                let requestParams = {};
                if(this.type != 'recommend') {
                    url = candidateUrl.url_candidate_attachment_list.replace(/%p/, this.id);
                    requestParams = {
                        url: url,
                        method: 'POST',
                        data: {
                            start: this.start,
                            take: this.take
                        }
                    }
                }else {
                    url = candidateUrl.url_recommendation_attachment_list.replace(/%p/, this.myrecommendationId);
                    requestParams = {
                        url: url,
                        method: 'GET',
                        baseURL: "LbdOpenApi",
                        data: {
                            start: this.start,
                            take: this.take
                        }
                    }
                }
                _request(requestParams).then(res => {
                    let list = res || [];
                    if(list && list.length > 0) {
                        list.forEach(item => {
                            item.recommendationId = this.myrecommendationId;
                            item.isFromAuthorization = this.isFromAuthorization;
                            item.type = this.type;
                        })
                    }
                    if(this.start == 0) {
                        this.attatchmentList = list || [];
                    }else {
                        this.attatchmentList = this.attatchmentList.concat(list || []);
                    }
                    if(list.length == this.take) {
                        this.hasMore = true;
                    }
                    let hasAttachment = this.attatchmentList.length > 0 ? true : false;
                    this.$emit('has-attachment', hasAttachment);
                }).finally(() => {
                    this.loading = false;
                    this.bottomLoading = false;
                })
            },
            handleLoadMore() {
                this.getListData(true);
            },
            downloadFile(fileId, fileName){
                let candidateBaseInfoStore = this.$parent.$parent.$refs.candidateBaseInfoStore;
                if(this.type != 'recommend' && candidateBaseInfoStore.emailStatus==2) {
                    this.$message.error('入职保证期阶段不能下载附件');
                    return;
                }
                let url = candidateUrl.url_attachment;
                if(this.type === 'recommend') {
                    if(!this.$parent.isRecommender && this.$parent.isPublisher && (this.$parent.$refs.recommendProcess.recommendProcess.status < 1 || this.$parent.$refs.recommendProcess.recommendProcess.status === 2)) {
                        shortTips("推荐未接受不能下载附件");
                        return;
                    }
                    // url = placeholder(candidateUrl.url_attachment_recommend, fileId);
                    url = candidateUrl.url_attachment_recommend.replace(/%p/, fileId);
                }
                _tracker.track(this.type === 'recommend' ? 'recommendAttachmentDownload' : 'resumeAttachmentDownload', JSON.stringify({
                    fileUrl: url,
                    pageUrl: location.href,
                    resumeId: this.resumeId || '',
                    recommendationId: this.recommendationId || '',
                    fileId: fileId,
                    desc: `${this.userInfo.realName || this.userInfo.nickname} 下载了简历 ${this.candidateName || ''} 的${this.type === 'recommend' ? '推荐' : ''}附件：${fileName}`
                }))
                this.loading = true;
                _request({
                    url: url,
                    method: "GET",
                    data:{resumeId:this.resumeId,fileId:fileId}
                }).then(res => {
                    res = res.replace(/^https?:/, location.protocol);
                    location.href = res;
                    if(this.type != 'recommend') {
                        if(candidateBaseInfoStore.emailStatus==1){
                            candidateBaseInfoStore.applyViewEmail();
                        }
                        if(candidateBaseInfoStore.mobileStatus==1){
                            candidateBaseInfoStore.applyViewMobile();
                        }
                    }
                }).catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                });
            },
            send(attachment) {
                this.recommends.showRecommends(attachment.id);
            },
            remove(attachment) {
                this.attatchmentList = this.attatchmentList.filter(function(item){
                    return item.id !== attachment.id;
                });
            },
            download(attachment) {
                this.downloadFile(this.type == 'recommend' ? attachment.id : attachment.fileId, attachment.name);
            },
            refresh(id){
                if(id){
                    this.myrecommendationId = id;
                }

                this.getListData();
            }
        }
    }
</script>
<style lang="scss" scope>
    .attachment-lists {
        min-height: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .empty-data {
        text-align: center;
        .empty-img {
            display: inline-block;
            background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
            background-size: contain;
            width: 160px;
            height: 160px;
        }
        & > p {
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 0px;
        }
    }
    .load-more {
        height: 28px;
        line-height: 28px;
        min-width: 84px;
        text-align: center;
        font-size: 14px;
        color: #333;
        margin: 30px 0;

        &:hover {
            color: #666;
            background-color: #eee;
            border: 1px solid #f3f3f3;
            cursor: pointer;
        }

        &:active {
            color: #666;
            background-color: #c8c8c8;
            border-color: #b3b3b3;
            cursor: pointer;
        }

        .font-icon {
            width: 14px;
            height: 14px;
            fill: #666;
        }
    }
</style>