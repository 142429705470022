var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "candidate-recommendation-header recommendation-header-vue",
      staticStyle: { display: "flex" },
    },
    [
      _c("span", { staticClass: "title" }, [
        _c(
          "a",
          {
            attrs: { href: _vm.href },
            on: { click: _vm.handleRouterNavigate },
          },
          [
            _vm.canReturn
              ? _c("i", { staticClass: "el-icon-d-arrow-left" })
              : _vm._e(),
            _c("span", { staticClass: "title-font-color" }, [
              _vm._v("全部推荐记录"),
            ]),
          ]
        ),
        _c("span", { staticClass: "recommend-angle" }, [
          _c(
            "a",
            {
              attrs: {
                href: _vm.jobId ? "/#/Job/" + _vm.jobId : "javascript: void(0)",
                target: "_blank",
              },
            },
            [
              _c("span", {
                staticClass: "title-font-color",
                domProps: { textContent: _vm._s(_vm.getJobLabel()) },
              }),
            ]
          ),
        ]),
      ]),
      _c(
        "span",
        { staticClass: "recommend-user recommend-user-overflow" },
        [
          _c("avatar", {
            attrs: {
              userId: _vm.callee.id ? _vm.callee.id : _vm.defaultCreatorId,
              src: _vm.callee.avatarUrl
                ? _vm.callee.avatarUrl
                : _vm.formatAvatarUrl(`/Data/Avatar/${_vm.callee.avatarId}`),
              enableLink: true,
              enableName: true,
              realName: _vm.callee.name,
              nickName: _vm.callee.nickname,
              isRecommendation: true,
              shareData: _vm.shareData,
              currentJobType: _vm.currentJobType,
              size: "sm",
            },
          }),
        ],
        1
      ),
      _vm.canCall && !_vm.isMyself && !_vm.lockStatus.isOnboardLock
        ? _c(
            "span",
            { staticClass: "recommend-dialog" },
            [
              _c(
                "phone-call",
                {
                  ref: "phoneCall",
                  attrs: {
                    "call-to": 0,
                    "object-id": _vm.callee.id,
                    sourceFrom: "candidateDetail",
                    tbdResumeId: _vm.resumeId,
                    "called-mobile": _vm.callee.mobile,
                  },
                  on: {
                    "candidate-search-record-refresh": _vm.handelRecordRefresh,
                  },
                },
                [_c("el-button", [_vm._v("拨打")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !(_vm.canCall || !_vm.isMyself) && _vm.lockStatus.isOnboardLock
        ? _c(
            "span",
            { staticClass: "recommend-dialog" },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    placement: "bottom",
                    effect: "light",
                    "popper-class": "concat-tooltip",
                  },
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(
                      "\n                候选人正在入职保证期中，暂不能拨打其电话\n            "
                    ),
                  ]),
                  _c("el-button", { staticClass: "free-call" }, [
                    _vm._v("拨打"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }