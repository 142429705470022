<template>
    <div class="candidate-recommendation-record">
        <!-- <h2 class="title">
            <span class="title-text">{{ recommendationCount | tpl('共%p次推荐') }}</span>
        </h2> -->
        <div class="empty-data" v-show="list.length == 0 && !loading">
            <span class="empty-img"></span>
            <p>暂无数据</p>
        </div>
        <div v-loading="loading">
            <div v-show="list.length > 0" class="recommendation-list">
                <div class="recommend-hd" v-for="(item, index) in list" :key="index + item.id">
                    <div class="logo-container">
                        <div class="logo">
                            <div class="logo-hd" :class="{'img-loaded' : item.employerLogoUrl}">
                                <img alt="职位" class="logo-img" v-show="item.employerLogoUrl" :src="item.employerLogoUrl | logo">
                            </div>
                            <!-- <div class="logo-bd">
                                <a v-if="canAccess" class="logo-text" :class="{'tdn' : item.isFromCloud}" :href="item.href || 'javascript: void(0);'">
                                </a>
                                <span v-if="!canAccess" class="logo-text" :class="{'tdn' : item.isFromCloud}">
                                </span>
                            </div> -->
                        </div>
                    </div>
                    <div class="info-and-tag-container">
                        <div class="info-container">
                            <div class="job-title">
                                <h4 class="recommend-job">
                                    <span class="company">{{item.firmName | square}}</span>
                                    <a v-if="item.canAccess" v-text="item.jobName" :href="item.href || 'javascript: void(0);'" :class="[{'tdn' : item.isFromCloud},'job-name-color']" @click="handleRouterNavigate(item)">
                                    </a>
                                    <span v-if="!item.canAccess" v-text="item.jobName" :class="[{'tdn' : item.isFromCloud}]" >
                                    </span>
                                    <span class="city">{{item.location | square}}</span>
                                </h4>
                            </div>
                            <div class="job-info">
                                <span class="salary">
                                    <template v-if="item.salary == '0K-0K'">
                                        月薪：面议
                                    </template>
                                    <template v-else>
                                        {{item.salary | tpl('月薪：%p')}}
                                    </template>
                                </span>
                                佣金：
                                <span class="commission">
                                    <template v-if="item.salary == '0K-0K'">
                                        按比例 {{filterCommisionValue(item.commisionValue)}}
                                    </template>
                                    <template v-else>
                                        {{item.commisionValue}}
                                    </template>
                                </span>
                            </div>
                            <div class="job-user">
                                <avatar
                                    :src="item.headhunterAvatarUrl | avatar"
                                    :userId="item.headhunterId"
                                    :alt="item.headhunterRealName"
                                    size="sm"
                                    :shareData="shareData"
                                ></avatar>
                                <span class="date">{{item.recommendDate | datetime | tpl('%p 推荐')}}</span>
                            </div>
                        </div>
                        <div class="tag-container">
                            <div class="job-panel">
                                <recommendation-status-tag 
                                    :interviewConfirmedStatus="item.interviewConfirmedStatus" 
                                    :href="item.recommendationUrl" 
                                    :label="item.recommendStatus"
                                    :flowStepName="item.flowStepName"
                                ></recommendation-status-tag>
                                <span v-if="item.commentsCount" class="comment-count">
                                    <i class="fa fa-comment"></i> <span>{{item.commentsCount}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="load-more" v-if="list.length > 0 && recommendationCount > list.length" v-loading="bottomLoading" @click="handleLoadMore">
            加载更多
            <font-icon href="#icon-spread-up"></font-icon>
        </div>
    </div>
</template>

<script>
    import { candidate as candidateConfig } from '#/js/config/api.json';

    import Avatar from '#/component/common/avatar';
    import RecommendationStatusTag from './recommendation-status-tag/index.vue';

    export default {
        components: {
            Avatar,
            RecommendationStatusTag
        },
        props: {
            recommendation:{
                type: Object,
                default:{}
            },
            shareData: Object
        },
        data() {
            return {
                start: 0,
                take: 10,
                loading: false,
                bottomLoading: false,
                list: [],
                recommendationCount: 0,
                isPublisher: false,
                isRecommender: true,
                canAccess: true,//给定一个值 用于不报错
                resumeId: this.$route.params.resumeId,
                candidateId: ''
            }
        },
        mounted () {
        },
        methods: {
            recordInit(id, silent){
                if(this.loading || this.bottomLoading) {
                    return false;
                }
                this.candidateId = id;
                if(silent) {
                    this.start = this.start + this.take;
                }else {
                    this.start = 0;
                }
                if(this.start == 0) {
                    this.loading = true;
                }else {
                    this.bottomLoading = true;
                }
                _request({
                    url: candidateConfig.url_recommendations_list.replace(/%p/, id),
                    method: 'POST',
                    data: {
                        start: this.start,
                        take: this.take
                    }
                }).then(res => {
                    let list = res.list || [];
                    list.forEach(item => {
                        item.href = `#/candidateDetail/${this.resumeId}/recommendation/%p`.replace(/%p/, item.id);
                    })
                    if(this.start == 0) {
                        this.list = list;
                    }else {
                        this.list = this.list.concat(list);
                    }
                    this.recommendationCount = res.total;
                    this.$emit('list-success', this);
                }).finally(() => {
                    this.loading = false;
                    this.bottomLoading = false;
                })
            },
            handleLoadMore() {
                this.recordInit(this.candidateId, true);
            },
            getRecommendationById(id){
                return this.list.find(function (item) {
                    return item.id === id;
                });
            },
            handleRouterNavigate(val){
                this.$emit('router-navigate',{url:val.href,recommendationid:val.id});
            },
            filterCommisionValue(val) {
                if(!val) return '';
                const percent = val.match(/\(.+\)/g)?.[0] || '';
                return percent.replace(/\(|\)/g, '');
            },
        },
        filters:{
            tpl: function tpl (text, template) {
                // return placeholder(template, text);
                return template.replace(/%p/, text);
            }
        }
    }
</script>

<style lang="scss">
.candidate-recommendation-record > .el-loading-mask .el-loading-spinner {
    top: 50px;
}
</style>
<style scoped lang="scss" scoped>

$panelColor: #b4b4b4;
.candidate-recommendation-record {
    // background: #fff;
    // border: 1px solid #ddd;
    border-top: none;
    margin-bottom: 50px;
    min-height: 150px;

    .recommendation-list {
        padding: 0 30px;
    }

    /deep/ .el-loading-spinner {
        top: 50px;
    }

    .empty-data {
        text-align: center;
        margin: 20px 0;
        .empty-img {
            display: inline-block;
            background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
            background-size: contain;
            width: 160px;
            height: 160px;
        }
        & > p {
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 0px;
        }
    }

    .title{
        background: #f4f7f7;
    }
    .recommend-hd {
        display: flex;
        flex-direction: row;
        padding: 20px 0;
        border-bottom: 1px solid #ececec;

        &:last-child {
            border: 0 none;
        }

        .logo-container {
            flex-shrink: 0;
            width: 145px;
            padding-left: 0;
        }

        .info-and-tag-container {
            flex: 1;
            display: flex;
            align-items: center;
            margin-left: 10px;
        }

        .info-container {
            width: 450px;
        }

        .tag-container {
            width: 150px;
            text-align: right;
        }

        &::after {
            display: block;
            clear: both;
            content: "";
        }
        .recommend-job {
            font-size: 14px;
            margin-bottom: 10px;
        }
        .job-name-color{
            color: #38bc9c;
        }
        .job-name-color:hover{
            color: #34b092;
        }
        .job-panel {
            color: $panelColor;
            margin-top: 30px;

            span {
                padding-right: 5px;

                &:last-child {
                    padding-right: 0;
                }

            }

            .fa {
                vertical-align: text-top;
                margin: 0 0 0 10px;
            }

        }

        .job-panel {
            .comment-count {
                display: inline-block;
                width: 56px;
                text-align: left;
            }

        }

        .recommend-short-desc {
            padding: 12px 0;
        }

    }

    .load-more {
        height: 28px;
        line-height: 28px;
        min-width: 84px;
        text-align: center;
        font-size: 14px;
        color: #333;
        margin: 30px 0;

        &:hover {
            color: #666;
            background-color: #eee;
            border: 1px solid #f3f3f3;
            cursor: pointer;
        }

        &:active {
            color: #666;
            background-color: #c8c8c8;
            border-color: #b3b3b3;
            cursor: pointer;
        }

        .font-icon {
            width: 14px;
            height: 14px;
            fill: #666;
        }
    }

    .logo {
        .logo-hd {
            width: 100%;
            height: 50px;
            line-height: 50px;
            border: solid 1px #e9e9e9;
            background: url("~@src/assets/images/default-company.png") no-repeat center center;
            overflow: hidden;
            display: block;
        }

        .logo-hd.img-loaded {
            background-image: none;
        }

        .logo-img {
            width: 100%;
            vertical-align: middle;
            padding-left: 2.5px;
            padding-right: 2.5px;
        }

        .logo-bd {
            color: $panelColor;
            text-align: center;
            margin-top: 5px;
        }

        .logo-text {
            white-space: nowrap;
        }
    }

    .job-title .city {
        color: $panelColor;
    }

    .job-title .company {
        color: #38bc9c;
        margin-right: 15px;
    }

    .job-info {
        margin-bottom: 10px;

        .commission {
            color: #f39800;
        }

    }

    .job-user .date {
        color: $panelColor;
        font-size: 12px;
        vertical-align: top;
        margin-left: 4px;
        line-height: 24px;
    }
    .job-user .avatar-container {
        display: inline-block;
    }

    > .order-item {
        padding: 10px 0;
    }

}
</style>
<style lang="scss">
.pager {
    text-align: center;
}
</style>
