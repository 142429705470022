<template>
<div class="candidate-recommendation-timeline">
    <font-icon class="candidate-icon" href="#icon-candidate_timeline"></font-icon>
    <span class="candidate-recommendation-title">
        时间线
    </span>

    <!-- <div class="detail detail-margin detail-text" ref="content"></div> -->
    <div class="detail detail-margin detail-text">

        <div class="time-line">
            <div class="row" 
                v-for="(item, index) in recoredsList"
                :key="'timeline_' + index"
                >
                <div class="col-xs-3">
                    <span 
                        class="small"
                        :class="{
                            'dimission': [17,37].includes(item.type),
                            'obsoleted': item.type == 11}"
                        v-text="item.typeText">接受</span>
                </div>
                <div class="col-xs-9">
                    <div class="action">
                        <p><span v-text="item.created">2021年06月01日 17:10</span></p>
                        <span class="text-primary lbd-cursor-pointer" v-if="item.posterRealName == '系统内置用户' "data-id="a83c0366-a433-47ac-b6e8-2db5b96e67b3" v-text="item.posterRealName"></span>
                        <span class="text-primary lbd-cursor-pointer" v-else data-id="a83c0366-a433-47ac-b6e8-2db5b96e67b3" v-text="item.posterRealName + (item.posterNickName ? '@'+item.posterNickName : '')">碧秋</span>
                        <span v-html="genereteHtml(item)"></span>
                        <!-- <span>{{item.typeText}}了候选人</span>
                        <i class="left-arrow"></i> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import { candidate as candidateConfig } from '#/js/config/api.json';
import myService from '#/js/service/myService.js';
import moment from 'moment';

export default {
    props:{
        recommendationId: {
            type: String,
            default:''
        },
        isPublisher: Boolean
    },
    data(){
        return{
            url: '',
            myrecommendationId: this.recommendationId,
            recoredsList: [],
        }
    },
    computed: {
        isAdministrator() {
            return this.$store.state.user.userInfo.isAdministrator || false;
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
    },
    mounted(){
        this.$content = $('.candidate-recommendation-timeline .detail');
    },
    methods: {
        loadcontent() {
            const params = {
                recommendationId: this.myrecommendationId,
                unionId: this.userInfo.unionId,
            };
            _request({
                method: 'POST',
                baseURL: "LbdOpenApi",
                url: this.url,
                data: params,
            })
            .then(res => {
                this.recoredsList = res.items || [];
            });
            
        },
        genereteHtml(item) {
            // text-light 最多四行~~~
            let returnStr;
            switch(item.type) {
                case 1:
                    returnStr = `<span>推荐了候选人</span>
                    <p class="text-light">${item.extensionProperties.recommendReason}</p>`;
                    break;
                case 2:
                case 4:
                    returnStr = `<span>接受了候选人</span>`;
                    break;
                case 3:
                    returnStr = `<span>拒绝了候选人</span>
                    <p class="text-light">${item.extensionProperties.rejectedReason}</p>
                    <p class="text-light">${item.extensionProperties.rejectedDetail}</p>`;
                    break;
                case 28:
                    returnStr = `<span>将候选人推进到面试阶段, 等待 </span>
                            <span class="text-primary lbd-cursor-pointer">${item.extensionProperties.unconfirmedUserName || ''}</span>
                            <span> 确认</span>`;
                    break;
                case 5:
                    returnStr = `<span>确认将候选人推进到面试阶段</span>`;
                    break;
                case 6:
                case 7:
                    returnStr = `<span>${item.type==6 ? '安排': '取消'}了${item.extensionProperties.interviewType}</span>
                            <p><span>面试时间：</span><span>${item.extensionProperties.interviewTime}</span></p>`;
                    if(item.extensionProperties.interviewAddress) {
                        returnStr += `<p>
                                    <span>面试地点：</span>
                                    <span>${item.extensionProperties.interviewAddress}</span>
                                    <span>${item.extensionProperties.includePickUpService ? '': ''}</span>
                                </p>`;
                                // item.extensionProperties.includePickUpService ? '（使用面试专车）': ''
                    }
                    break;
                case 8:
                    returnStr = `<span>反馈了${item.extensionProperties.interviewType}结果(${item.extensionProperties.result})</span>
                            <p>${item.extensionProperties.evaluation}</p>`;
                    break;
                case 9:
                    returnStr = `<span>将候选人推进到已Offer阶段</span>
                            <p><span>当前状态为进入已Offer</span></p>`;
                    if(item.OfferHiredDate) {
                        returnStr += `<p><span>薪酬为：</span><span>${item.OfferMonthSalary}</span><span>k * </span><span>${item.offerMonthSalary}</span></p>
                                <p><span>入职时间：</span><span>${item.offerHiredDate}</span></p>
                                <p><span>备注为：</span><span>${item.offerRemarks} </span></p>`;
                    }
                    break;
                case 41:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                    const typeMap = {
                        41: 'S1全部面试通过', 
                        29: 'S2提交流水等材料', 
                        30: 'S3客户审批offer', 
                        31: 'S4沟通offer细节', 
                        32: 'S5等待接受offer', 
                        33: 'S6双方确认offer'
                    };
                    returnStr = `<span>将候选人推进到Offering中阶段</span>
                            <p><span>当前状态为</span><span>${typeMap[item.type]}</span></p>`;
                    break;
                case 10:
                    returnStr = `<span>将候选人推进到入职阶段</span>`;
                    if(item.onboardDate) {
                        returnStr += `<p><span>入职时间：</span><span>${item.onboardDate}</span></p>
                                <p><span>保证期：</span><span>${parseInt(item.guaranteeDays /30)} 个月</span></p>`;
                    }
                    break;
                case 11:
                    returnStr = `<span>淘汰了候选人</span>
                            <p class="text-light">${item.extensionProperties.obsoletedReason}</p>
                            <p class="text-light">${item.extensionProperties.obsoletedDetail}</p>`;
                    break;
                case 17:
                    returnStr = `<span>将候选人设置为离职状态</span>`;
                    break;
                case 26:
                    returnStr = `<span>恢复了候选人</span>
                            <p class="text-light"><span>候选人当前状态为</span>${item.extensionProperties.lastStatusName}</p>`;
                    break;
                case 18:
                    returnStr = `<span>筛选通过了候选人</span>`;
                    break;
                case 20:
                    returnStr = `<p><span class="amount">${parseInt(item.extensionProperties.amount/100)}元</span>打赏了${item.extensionProperties.recommenderRealName}@${item.extensionProperties.recommenderNickname}</p>
                            <p class="text-light">${item.extensionProperties.remark}</p>`;
                    break;
                
                case 35:
                case 37:
                    const actionsMap = ['创建', '重新申请', '编辑', '填写', '填写', '驳回', '取消', '移交', '确认', '作废'];
                    const paymentMethodMap = ['全款', '首款', '尾款'];
                    const applicationTypeMap = ['', '负数'];
                    const ticketsType = ['开票申请', '开票申请', '开票申请', '开票信息', '回款信息', '开票申请', '开票申请', '开票申请', '开票申请', '开票申请'];
                    // 提交了全款开票申请
                    // 当前状态为开票中

                    returnStr = `<span>${actionsMap[+item.extensionProperties.operationType]}了${paymentMethodMap[+item.extensionProperties.paymentMethodType]}${applicationTypeMap[+item.extensionProperties.invoiceApplicationType]}${ticketsType[+item.extensionProperties.operationType]}</span>
                    <p class="text-light">当前状态为${item.type == 35 ? (item.extensionProperties.recommendStatus == '26' ? '已回款': '开票中'): '离职'}</p>
                    `;
                    break;
                default: 
                    returnStr = '';
            }

        //     [Display(Name = "开票")]
        // InvoiceApplication = 35,
        // [Display(Name = "开票")]
        // InvoiceApplicationOnDimission = 37,
            return returnStr;
        },
        refresh(id){
            if(id){
                this.myrecommendationId = id;
                this.url = candidateConfig.url_recommendation_timeline.replace(/%p/, this.myrecommendationId);
            }
            this.loadcontent();
        },
        refreshOfferEditLog() {
            let offerLogContentNodes = document.querySelectorAll('.offer-edit-log-content');
            myService.getOfferEditLog(this.myrecommendationId).then(res => {
                let innerHTML = "",
                    offerLogItem = "";
                if(res && res.length > 0) {
                    let originInfo = "",
                        updateInfo = "";
                    res.forEach(item => {
                        item.updateTime = moment(item.updateTime).format('YYYY-MM-DD HH:mm:HH');
                        offerLogItem = `<p class="offer-log-item-time">${item.updateTime}</p><p><span class="text-primary">${item.updaterName}</span> <span class='text-black'>编辑Offer</span></p>`;
                        originInfo = "<p class='text-black'>编辑前</p>" + this.transformOfferLog(item.originModel);
                        updateInfo = "<p class='text-black'>编辑后</p>" + this.transformOfferLog(item.updateModel);
                        offerLogItem += originInfo;
                        offerLogItem += updateInfo;
                        innerHTML += `<div class="offer-log-content-item">${offerLogItem}</div>`;
                    });
                    for(let i = 0; i < offerLogContentNodes.length; i++) {
                        offerLogContentNodes[i].innerHTML = innerHTML;
                    }
                }
            }).catch(err => {
                console.log(err);
            });
        },
        transformOfferLog(log) {
            let list = [],
                text = "",
                itemSplitItemText = "",
                itemSplitItems = [];
            Object.keys(log).forEach(item => {
                if(item !== 'performanceSplits' && item !== 'months' && ((item === 'serviceCharge' && log[item] != undefined) || (item !== 'serviceCharge' && log[item]))) {
                    switch(item) {
                        case 'monthPay':
                            text = `<p>签订月薪为：${log.months}*${log.monthPay}k</p>`;
                            break;
                        case 'recruiteTime':
                            text = `<p>入职时间为：${moment(log.recruiteTime).format("YYYY-MM-DD")}</p>`;
                            break;
                        case 'commissionRate':
                            text = `<p>佣金比例为：${log.commissionRate}%</p>`;
                            break;
                        case 'serviceCharge':
                            text = `<p>服务费为：${log.serviceCharge}</p>`;
                            break;
                        case 'remark':
                            text = `<p>备注为：${log.remark}</p>`;
                            break;
                    }
                    list.push(text);
                }
            });
            
            log.performanceSplits.forEach(item =>{
                text = `<p>${item.name}为：`;
                itemSplitItemText = "";
                itemSplitItems = [];
                for(let i = 0; i < item.splitItems.length; i++) {
                    itemSplitItemText = `${item.splitItems[i].realName}，`;
                    if(item.type > 0) {
                        itemSplitItemText += `比例为 ${item.splitItems[i].value}%`;
                    } else {
                        itemSplitItemText += `数值为 ${item.splitItems[i].value}`;
                    }
                    itemSplitItems.push(itemSplitItemText);
                }
                text += itemSplitItems.join("；");
                text += '</p>';
                list.push(text);
            });
            return list.join("");
        }
    }
}
</script>

<style lang="scss" scope>
.candidate-recommendation-timeline{
    .text-black {
        color: #333;
    }
    .text-primary {
        color: $primary;
    }
    .candidate-recommendation-title{
        margin-top: 20px;
        margin-bottom: 10px;
        display: inline-block;
        color: #444;
    }
    .detail-text{
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.50);
        word-break: break-all;
        .text-light{
            word-break: break-all;
            white-space: pre-line;
        }
        .time-line{
            padding: 15px;
            background: #fff;
            .col-xs-3 {
                float: left;
                width: 16%;
                padding: 0;
            }
            .col-xs-9 {
                float: left;
                width: 80%;
                padding: 0 0 0 5px;
            }
            .row {
                margin-left: 0;
                margin-right: 0;
                background: url('~@src/assets/images/border-bg.png') 7.5% 0 no-repeat;
            }

            .col-xs-3 .big {
                color: #fff;
                background: #3091f2;
                border-radius: 35px;
                font-size: 15px;
                width: 60px;
                height: 60px;
                line-height: 60px;
                display: block;
                text-align: center;
                margin: 0 auto;
                margin-bottom: 30px;
            }

            .col-xs-3 .small {
                color: #fff;
                background: #62A8EA;
                border-radius: 50%;
                font-size: 12px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                display: block;
                text-align: center;
                margin: 0 auto;
            }
            .dimission{
                background: #f6393f!important;
            }
            .obsoleted{
                background: #F98F33!important;
            }

            .action {
                margin: 0 0 48px 0;
                position: relative;
                background-color: #fff;
                padding: 0 10px 5px;
            }

            .action > p {
                margin: 0;
                word-break: break-all;

                &:first-child{
                    background-color: #fff;
                    margin: 0 -10px 5px;
                    color: #b9b9b9;
                }
                .offer-edit-btn {
                    margin-left: 10px;
                }

                .offer-edit-log {
                    position: relative;
                    margin-left: 10px;
                    display: inline-block;
                    .offer-edit-log-btn {
                        width: 20px;
                        height: 20px;
                        color: #999;
                    }
                    .offer-edit-log-content {
                        position: absolute;
                        z-index: 999;
                        top: 90%;
                        left: 50%;
                        display: none;
                        padding: 20px;
                        padding-bottom: 0;
                        width: 480px;
                        min-height: 80px;
                        max-height: 590px;
                        overflow-y: auto;
                        background: #fff;
                        color: #999;
                        border-radius: 4px;
                        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                        .offer-log-content-item {
                            margin-bottom: 20px;
                            line-height: 20px;
                            p {
                                margin-bottom: 0;
                            }
                            .offer-log-item-time {
                                margin-bottom: 4px;
                                color: #666;
                            }
                        }
                    }
                    &:hover {
                        .offer-edit-log-btn {
                            color: $primary;
                        }
                        .offer-edit-log-content {
                            display: block;
                        }
                    }
                }
            }

            .Offer .col-xs-3 .small,
            .SubmitOfferDocuments .col-xs-3 .small,
            .ApproveOffer .col-xs-3 .small,
            .CommunicateOffer .col-xs-3 .small,
            .ToAcceptOffer .col-xs-3 .small,
            .ConfirmOffer .col-xs-3 .small
            {
                background: $primary;
            }

            .Onboard .col-xs-3 .small {
                background: $primary;
            }

            .Rejected .col-xs-3 .small,
            .Obsoleted .col-xs-3 .small {
                background: #f6393f;
            }

            .resume-well {
                background-color: #fff;
            }

            .amount{
                display: inline-block;
                line-height: 1;
                padding: 4px;
                text-align: center;
                color: #fff;
                background-color: #ff7200;
                border-radius: 2px;
                margin-right: 6px;
            }

            .show-more-content {
                white-space: normal;
            }
        }
    }
}
</style>