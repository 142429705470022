<template>
    <div class="candidate-communication-list" v-loading="loading">
        <!-- <h2 class="title">
            <span class="title-text">{{ communicationCount | tpl('共%p次加入待沟通') }}</span>
        </h2> -->
        <div class="empty-data" v-if="list.length == 0 && !loading">
            <span class="empty-img"></span>
            <p>暂无数据</p>
        </div>
        <div v-if="list.length > 0" class="communication-list">
            <div class="recommend-hd" v-for="item in list" :key="item.communicationId">
                <div class="col-3 logo-container">
                    <div class="logo">
                        <div :class="['logo-hd', {'img-loaded' : item.employerLogoUrl}]">
                            <img alt="职位" class="logo-img" v-show="item.employerLogoUrl" :src="item.employerLogoUrl | logo">
                        </div>
                    </div>
                </div>
                <div class="col-7">
                    <div class="job-title">
                        <h4 class="recommend-job">
                            <span class="company">{{item.firmName | square}}</span>
                            <!-- <a v-text="item.jobName" :href="'/Headhunting/MyCompany.html#/Job/' + item.jobId" :class="[{'tdn' : item.isFromCloud},'job-name-color']" target="_blank"></a> -->
                            <a v-text="item.jobName" :href="'/#/Job/' + item.jobId" :class="[{'tdn' : item.isFromCloud},'job-name-color']" target="_blank"></a>
                            <span class="city">{{ item.location | square }}</span>
                        </h4>
                    </div>
                    <div class="job-info">
                        <span class="salary">
                            <template v-if="item.salary == '0K-0K'">
                                月薪：面议
                            </template>
                            <template v-else>
                                {{ item.salary | tpl('月薪：%p') }}
                            </template>
                        </span>
                        佣金：
                        <span class="commission">
                            <template v-if="item.salary == '0K-0K'">
                                按比例 {{filterCommisionValue(item.commisionValue)}}
                            </template>
                            <template v-else>
                                {{item.commisionValue}}
                            </template>
                        </span>
                    </div>
                    <div class="job-user">
                        <!-- <avatar 
                            :src="item.headhunterAvatarUrl | avatar" 
                            :id="item.headhunterId" 
                            :alt="item.headhunterRealName" 
                            size="sm" 
                            :enableLink="false"
                        ></avatar> -->
                        <!-- class="user-avatar"
                            size="sm" -->
                        <avatar
                            class="user-avatar"
                            :src="item.headhunterAvatarUrl | avatar"
                            :userId="item.headhunterId"
                            :enableCard="true"
                            :enableLink="true"
                        ></avatar>
                        <span class="date">{{ item.createDate | datetime | tpl('%p 添加') }}</span>
                    </div>
                </div>
                <div class="col-2">
                    <div class="job-status">
                        <div v-if="item.communicationStatus == 'Unprocessed'" class="job-unprocessed">未处理</div>
                        <div v-if="item.communicationStatus == 'Removed'" class="job-removed">已移出</div>
                        <div v-if="item.communicationStatus == 'Recommended'" class="job-recommended">已推荐</div>
                    </div>
                </div>
                <div v-if="item.communicationStatus == 'Unprocessed' && item.headhunterId == userId" class="col-3 pull-right communication-operate-list">
                    <div class="btn-clear-out" v-loading="item.removeLoading" @click="onConmunicationJobClear(item.communicationId, item)">移出</div>
                    <div class="btn-recommend" v-loading="item.recommendLoading" @click="onConmunicationJobRecommend(item.jobId, item)">推荐</div>
                </div>
            </div>
        </div>
        <div class="load-more" v-if="list.length > 0 && communicationCount > list.length" v-loading="bottomLoading" @click="handleLoadMore">
            加载更多
            <font-icon href="#icon-spread-up"></font-icon>
        </div>
    </div>
</template>

<script>
    import Avatar from '#/component/common/avatar.vue';
    import { candidate as candidateConfig, my } from '#/js/config/api.json';
    
    export default {
        components: {
            Avatar
        },
        props: {
            candidateId: String,
        },
        data() {
            return {
                loading: false,
                bottomLoading: false,
                resumeId: '',
                communicationCount: 0,
                isPublisher: false,
                isRecommender: true,
                // userId: this.$store.state.user.userInfo.id,
                start:0,
                take: 10,
                list: []
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            userId() {
                return this.userInfo.id;
            }
        },
        mounted () {
        },
        methods: {
            init(id){
                this.resumeId = id;
                this.getListData();
            },
            getListData(silent) {
                if(silent) {
                    this.start = this.start + this.take;
                }else {
                    this.start = 0;
                }
                if(this.start == 0) {
                    this.loading = true;
                }else {
                    this.bottomLoading = true;
                }
                _request({
                    url: candidateConfig.url_communication_list_outer.replace('%p', this.resumeId || this.candidateId),
                    method: 'POST'
                }).then(res => {
                    let list = res.list || [];
                    list.forEach(item => {
                        item.removeLoading = false;
                        item.recommendLoading = false;
                    })
                    if(this.start == 0) {
                        this.list = list;
                    }else {
                        this.list = this.list.concat(list);
                    }
                    this.communicationCount = res.total;
                }).finally(() => {
                    this.loading = false;
                    this.bottomLoading = false;
                })
            },
            refresh() {
                this.getListData();
            },
            handleLoadMore() {
                this.getListData(true);
            },
            onConmunicationJobClear(id, item){
                if(item.removeLoading) {
                    return false;
                }
                item.removeLoading = true;
                _request({
                    url: candidateConfig.url_delete_communication.replace('%p', id),
                    method: 'GET'
                }).then(res => {
                    shortTips('移出成功');
                    this.refresh();
                }).finally(() => {
                    item.removeLoading = false;
                })
            },

            onConmunicationJobRecommend(id, item){
                if(item.recommendLoading) {
                    return false;
                }
                item.recommendLoading = true;
                let candidateId = this.candidateId,
                    resumeStatusUrl = my.candidate_resume_status.replace("%p", candidateId),
                    jobid = id;
                _request({
                    url: resumeStatusUrl,
                    method: 'POST',
                    throwBusinessError: true
                }).then(res =>{
                    let isEdit = 'False';
                    let url = '/Headhunting/MyCompany.html#/recommendDetail?jobId=' + jobid + '&candidateId=' + candidateId + '&isEdit=' + isEdit;
                    url += '&returnUrl=' + encodeURIComponent(window.location.href);
                    // window.location.href = url;
                    window.open(url);
                }).catch(err => {
                    if(err.hasOwnProperty('succeeded')) {
                        let isEdit = 'True';
                        let url = '/Headhunting/MyCompany.html#/recommendDetail?jobId=' + jobid + '&candidateId=' + candidateId + '&isEdit=' + isEdit;
                        url += '&returnUrl=' + encodeURIComponent(window.location.href);
                        // window.location.href = url;
                        window.open(url);
                    }else {
                        shortTips('系统异常，请稍后重试');
                    }
                }).finally(() => {
                    item.recommendLoading = false;
                })
            },
            filterCommisionValue(val) {
                if(!val) return '';
                const percent = val.match(/\(.+\)/g)?.[0] || '';
                return percent.replace(/\(|\)/g, '');
            },
        }
    }
</script>

<style lang="scss">
.candidate-communication-list > .el-loading-mask .el-loading-spinner {
    top: 50px;
}
</style>
<style lang="scss" scoped>
$panelColor: #b4b4b4;
.candidate-communication-list {
    // background: #fff;
    // border: 1px solid #ddd;
    border-top: none;
    min-height: 150px;
    padding: 0 30px!important;

    .title{
        background: #f4f7f7;
    }
    .order-container{
        padding-right: 30px;
    }

    .empty-data {
        text-align: center;
        margin: 20px 0;
        .empty-img {
            display: inline-block;
            background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
            background-size: contain;
            width: 160px;
            height: 160px;
        }
        & > p {
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 0px;
        }
    }

    .recommend-hd {
        padding: 20px 0;
        border-bottom: 1px solid #ececec;

        &:last-child {
            border: 0 none;
        }

        &::after {
            content: '';
            display: block;
            clear: both;
        }

        .recommend-job {
            font-size: 14px;
            margin-bottom: 10px;
        }
        .job-name-color{
            color: #38bc9c
        }
        .job-name-color:hover{
            color: #34b092;
        }
        .job-status {
            &>div{
                width:110px;
                height:24px;
                font-size:12px;
                text-align: center;
                line-height: 24px;
                margin-top: 30px;
            }
            .job-unprocessed{
                background:#fef1db;
                color:#f69800;
            }
            .job-removed{
                background:#f8f8f8;
                color:#b9b9b9;
            }
            .job-recommended{
                background:#ebf8f5;
                color:#38bc9c;
            }
        }

        .communication-operate-list{
            margin-top: 27px;
            width: auto;
            .btn-clear-out,.btn-recommend{
                background:#fff;
                border-radius:2px;
                width:60px;
                height:30px;
                display: inline-block;
                text-align: center;
                line-height: 30px;
                &:hover{
                    cursor: pointer;
                }
            }
        
            .btn-clear-out{
                border:1px solid #f69800;
                color: #f69800;
                margin-right: 15px;
            }
        
            .btn-recommend{
                border:1px solid #38bc9c;
                color: #38bc9c;
            }
        }

        .logo-container {
            padding-left: 0;
        }

        .recommend-short-desc {
            padding: 12px 0;
        }

    }

    .load-more {
        height: 28px;
        line-height: 28px;
        min-width: 84px;
        text-align: center;
        font-size: 14px;
        color: #333;
        margin: 30px 0;

        &:hover {
            color: #666;
            background-color: #eee;
            border: 1px solid #f3f3f3;
            cursor: pointer;
        }

        &:active {
            color: #666;
            background-color: #c8c8c8;
            border-color: #b3b3b3;
            cursor: pointer;
        }

        .font-icon {
            width: 14px;
            height: 14px;
            fill: #666;
        }
    }

    .logo {
        .logo-hd {
            width: 100%;
            height: 50px;
            line-height: 50px;
            border: solid 1px #e9e9e9;
            background: url("~@src/assets/images/default-company.png") no-repeat center center;
            overflow: hidden;
            display: block;
        }

        .logo-hd.img-loaded {
            background-image: none;
        }

        .logo-img {
            width: 100%;
            vertical-align: middle;
            padding-left: 2.5px;
            padding-right: 2.5px;
        }

        .logo-bd {
            color: $panelColor;
            text-align: center;
            margin-top: 5px;
        }

        .logo-text {
            white-space: nowrap;
        }
    }

    .job-title .city {
        color: $panelColor;
    }

    .job-title .company {
        color: #38bc9c;
        margin-right: 15px;
    }

    .job-info {
        margin-bottom: 10px;

        .commission {
            color: #f39800;
        }

    }

    .job-user .date {
        color: $panelColor;
        font-size: 12px;
        vertical-align: top;
        margin-left: 4px;
        line-height: 24px;
    }
    .job-user{
        .user-avatar{
            display: inline-block;
        }
    }


    > .order-item {
        padding: 10px 0;
    }

}

</style>
