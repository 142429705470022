<template>
    <form ref="candidateResumeEvaluationForm" class="candidate-resume-evaluation-form col-15" id="candidate-resume-evaluation-form" autocomplete="off" v-loading="loading">

        <div class="form-group col-14">
            <label class="control-label col-md-12" for="selfEvaluation">自我评价</label>
            <div class="col-sm-12">
            <textarea
                rows="3"
                class="form-control"
                v-model="formData.selfEvaluation"
                id="selfEvaluation"
                name="selfEvaluation"
                type="text"
                value=""></textarea>
            </div>
        </div>

        <div class="form-group col-14">
            <div class="col-sm-6">
                <input class="btn btn-primary submitBtn btn-background" type="button" value="保存" @click="_submitValidate">
                <input type="button" value="取消" class="btn btn-default cancelBtn" @click="_cancel">
            </div>
        </div>
    </form>
</template>

<script>
    import resumeItemType from '#/js/config/resumeItemType.json';
    import { max as getMax} from '#/js/util/validators.js';

    let defaultFormObj = { "selfEvaluation": "" }

    export default {
        data() {
            return {
                formData: Object.assign({}, defaultFormObj),
                type: resumeItemType.selfEvaluation,
                loading:false,
            }
        },
        props:{
            candidateId:String,
            editUrl:String,
        },
        mounted () {
            this.form = this.$refs.candidateResumeEvaluationForm;
            this.initEvent();
        },
        methods: {

            _cancel(){
                this.$emit('resume-evaluation-form-cancel');
            },

            _reset(){
                this.formData = Object.assign({}, defaultFormObj);
            },

            _refresh(){
                this.$emit('candidate-detail-refresh')
            },

            _submitValidate(){
                let formData = this.formData;
                let isValid = true;

                delete formData.__ko_mapping__;
                formData = this.trimValue(formData);
                for(var key in formData) {
                    let value = formData[key];

                    if (_isDangerousString(value)) {
                        isValid = false;
                        shortTips('您输入的内容包含非法字符，请重新输入');
                        break;
                    }

                    if(!isValid) break;

                    if(!getMax(4000,value)){
                        isValid = false;
                        shortTips('自我评价最长支持4000个字符');
                        break;
                    }
                }

                if(isValid){
                    this.loading=true;
                    this._submitCandidateEdit(formData);
                }
            },

            _submitCandidateEdit(formData){
                let postUrl = this.editUrl;

                _request({
                    url: postUrl,
                    method: 'POST',
                    data: {
                        id:this.candidateId,
                        type:this.type,
                        data:{
                            selfEvaluation:formData
                        }    
                    }
                }).then(res => {
                    this._cancel();
                    this._refresh();
                    shortTips('编辑成功');
                }).finally(() =>{
                    this.loading=false;
                })
            },

            trimValue(obj){
                let newOjb = {};
                Object.keys(obj).forEach(key =>{
                    newOjb[key] = (typeof(obj[key]) == 'string') ? obj[key].trim() : obj[key];
                })
                return newOjb;
            },

            mergeFormData(data) {
                // var formInfo = normalizeFormObject(data);
                // mergeObservable(this.formData, data);
                this.formData.selfEvaluation=data;
            },

            initEvent(){
                let self = this;
            }
        }
    }
</script>

<style scoped lang="scss">
    /*@import '../../../scss/entry/library.scss';*/
    /*@import '../../../scss/entry/common.scss';*/
    /*@import '../../../scss/entry/myCompany.scss';*/
    @import './candidate-resume-evaluation-form.scss';
    .candidate-resume-evaluation-form {
        &.col-15, .col-14 {
            width: 100% !important;
        }
        .col-7 {
            width: 50% !important;
        }
    }
</style>
