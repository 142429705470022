var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "candidate-recommendation-info" },
    [
      _c("candidate-recommendation-record", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.viewType == "list",
            expression: "viewType == 'list'",
          },
        ],
        ref: "candidateRecommendationRecord",
        attrs: {
          recommendationId: _vm.myrecommendationId,
          recommendation: _vm.recommendation,
          shareData: _vm.shareData,
        },
        on: {
          "list-success": _vm.handleListSuccess,
          "router-navigate": _vm.handleRouterNavigate,
        },
      }),
      _c("candidate-recommendation-detail", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.viewType == "detail",
            expression: "viewType == 'detail'",
          },
        ],
        ref: "candidateRecommendationDetail",
        attrs: {
          recommendation: _vm.recommendation,
          recommendationId: _vm.myrecommendationId,
          candidateId: _vm.candidateId,
          "resume-id": _vm.resumeId,
          shareData: _vm.shareData,
        },
        on: {
          "candidate-search-record-refresh": _vm.handelRecordRefresh,
          "router-navigate": _vm.handleRouterNavigate,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }