<template>
    <div class="candidate-search-record candidate-search-record-vue">
        <div class="record-top">
            <h2 class="title">{{isFirm ? '寻访记录' : '我的寻访'}}（{{total}}）</h2>
            <div class="record-dropdown">
                <el-dropdown
                    trigger="click"
                    class="candidate-search-record-dropdown"
                    @command="(val) => handleRecordDropdown(val)"
                    @visible-change="(val) => handleDropdownVisible(val)">

                    <span class="el-dropdown-link">
                        {{recordByText}}
                        <i
                            class="el-icon--right"
                            :class="{
                                'el-icon-arrow-down': !recordByDropdownVisible,
                                'el-icon-arrow-up': recordByDropdownVisible
                            }"
                        ></i>
                    </span>
                    <el-dropdown-menu
                        slot="dropdown"
                        class="candidate-search-record-dropdown-menu">
                        <el-dropdown-item
                            v-for="item in recordByMap"
                            :key="item.value"
                            :command="item.value"
                            :class="{'active': recordByText == item.label}">
                            {{item.label}}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div ref="order" class="order-container" v-loading="loading">
            <ul>
                <li class="order-item" v-for="(item, index) in dataArray" :key="`orderItem_`+index">
                    <div class="record-hd">
                        <avatar
                            :enableCard="true"
                            :enableLink="true"
                            :shareData="shareData"
                            :src="item.creatorAvatar"
                            :userId="item.creatorId"
                        ></avatar>
                        <div class="record-title">
                            <div class="record-info">
                                <span v-text="item.creatorRealName" class="record-user"></span>
                                <span class="record-number">{{ item.creatorNickName ? `@${item.creatorNickName}` : '' }}</span>
                            </div>
                            <div class="record-date">{{ item.updated | datetime }}</div>
                        </div>
                        <div
                            class="record-hide-status"
                            :class="item.hideStatus !== undefined && item.hideStatus === 0 ? 'private' : ''">
                            {{item.hideStatus !== undefined && item.hideStatus === 0 ? '私' : '公'}}
                        </div>
                    </div>
                    <p v-html="item.content" class="record-reason"></p>
                     <!-- v-if="item.audioRecordUrl" -->
                    <div class="audio-record" v-if="item.audioRecordUrl">
                        <!-- :audio-url="item.audioRecordUrl"  -->
                        <!-- :duration="item.audioDuration/60"  -->
                        <!-- audio-url="https://www.ytmp3.cn/down/75727.mp3" 
                            duration="1.75"  -->
                        <record-listen 
                            :audio-url="item.audioRecordUrl"
                            :duration="item.audioDuration/60"
                            ref="record-listen"
                            >
                        </record-listen>
                    </div>
                    <div v-if="item.jobHoppingOccasion || item.employerInfo || item.expectSalary" class="inquire-info">
                        <ul>
                            <li v-if="item.jobHoppingOccasion">跳槽时机：
                                <span v-text="item.jobHoppingOccasion"></span>
                            </li>
                            <li v-if="item.employerInfo">雇主画像：
                                <span v-text="item.employerInfo"></span>
                            </li>
                            <li v-if="item.expectSalary">报酬期望：
                                <span v-text="item.expectSalary"></span>
                            </li>
                        </ul>
                    </div>
                    <p v-show="userID === item.creatorId" class="record-panel">
                        <a v-if="item.isValidInquiry" @click="onEdit(item)" class="record-anchor" href="javascript: void(0);">
                            <i class="fa fa-edit "></i>
                            <span v-text="item.content ? '编辑' : '添加寻访'"></span>
                        </a>
                        <a @click="onRemove(item.id)" class="record-anchor" href="javascript: void(0);">
                            <i class="fa fa-close"></i>
                            <span>删除</span>
                        </a>
                    </p>
                </li>
                <div class="empty" v-if="total == 0">暂无数据</div>
                <div class="record-bottom">
                    <span class="record-show-more" v-if="total>3 && showMore" @click="handleShowMore">加载更多<i class="el-icon-d-arrow-right"></i></span>
                    <span class="record-show-more" v-if="total>3 && !showMore" @click="handlePackUp">收起</span>
                </div>
            </ul>
        </div>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="540px">
            <span>确认删除寻访记录？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" :disabled="removeBtnDisabled" @click="remove">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import { candidate as candidateDetailConfig } from '#/js/config/api.json'
    import Avatar from '#/component/common/avatar.vue';
    // import recordListen from '../../component/record-listen/index';
    import RecordListen from '#/component/common/record-listen.vue';
    export default {
        components: {
            Avatar,
            RecordListen
        },
        data() {
            return {
                total: 0,
                resumeId:this.$route.params.resumeId,
                userID:this.$store.state.user.userInfo.id,
                userFirmShortName:this.$store.state.user.userInfo.userFirmShortName,
                userRealName:this.$store.state.user.userInfo.userRealName,
                dialogVisible:false,
                removeId:'',
                dataArray:[],
                showMore:true,
                loading:false,
                removeBtnDisabled: false,

                isValidInquiry: null, // 全部记录 null，有效寻访 true, 无效寻访 false 
                recordByText: '全部记录',
                recordByDropdownVisible: false,
                recordByMap: [
                    {
                        value: 0,
                        label: "全部记录"
                    }, {
                        value: 1,
                        label: "有效寻访"
                    }, {
                        value: 2,
                        label: "无效寻访"
                    }
                ],
            }
        },
        props: {
            isCloudCandidate: {
                type: Boolean,
                default: false
            },
            candidateId: String,
            isFirm: Boolean,
            shareData: Object
        },
        computed: {
            urlDelete() {
                // return placeholder(candidateDetailConfig.url_search_record_delete, this.resumeId);
                return candidateDetailConfig.url_search_record_delete.replace(/%p/, this.resumeId);
            },
            verificationToken() {
                return this.$store.state.verificationToken.verificationToken;
            }
        },
        mounted () {
            var self = this;
            this.currentTimeCountDown = null;
        },
        methods: {
            handleRecordDropdown(command) {
                // console.log(command);
                if(command == 0) {
                    this.isValidInquiry = null;
                    this.recordByText = '全部记录';
                } else if(command == 1) {
                    this.isValidInquiry = true;
                    this.recordByText = '有效寻访';
                } else if(command == 2) {
                    this.isValidInquiry = false;
                    this.recordByText = '无效寻访';
                }

                this.initExtraMethod();
            },
            handleDropdownVisible(visible) {
                // console.log(visible);
                this.recordByDropdownVisible = visible;
            },

            // searchRecordOperate (item) {
            //     item.currentTime = item.audioDuration;
            // },
            refresh(){
                if(this.showMore){
                    this.initExtraMethod();
                }else{
                    if(this.loading) {
                        return false;
                    }
                    // let url = placeholder(`${candidateDetailConfig.url_search_record}?start=0&take=${this.total+1}`, this.resumeId);
                    // const url = `${candidateDetailConfig.url_search_record}?start=0&take=${this.total+1}`.replace(/%p/, this.resumeId);
                    const url = `${candidateDetailConfig.get_inquiryLogs}`;

                    let params = {
                        start: 0,
                        take: this.total*1 + 1,
                        candidateId: this.resumeId,
                        onlyMyInquiry: false,
                        isValidInquiry: this.isValidInquiry
                    }
                    this.loading = true;
                    _request({
                        url: url,
                        // method: "GET"
                        method: "POST",
                        baseURL: "LbdOpenApi",
                        data: params,
                    }).then(res => {
                        this.dataArray = res.list;
                        this.total = res.total;
                    }).catch(err => {
                        console.log(err);
                    }).finally(()=>{
                        this.loading = false;
                    });
                }
            },
            onEdit(val){
                this.$emit('inquire-edit',val);
            },
            initExtraMethod(){
                if(this.loading) {
                    return false;
                }
                // let url = placeholder(`${candidateDetailConfig.url_search_record}?start=0&take=3`, this.resumeId);
                // const url = `${candidateDetailConfig.url_search_record}?start=0&take=3`.replace(/%p/, this.resumeId);
                const url = `${candidateDetailConfig.get_inquiryLogs}`;
                let params = {
                    start: 0,
                    take: 3,
                    candidateId: this.resumeId,
                    onlyMyInquiry: false,
                    isValidInquiry: this.isValidInquiry
                }
                this.loading = true;
                _request({
                    url: url,
                    // method: "GET"
                    method: "POST",
                    baseURL: "LbdOpenApi",
                    data: params,
                }).then(res => {
                    this.dataArray = res.list;
                    this.total = res.total;
                }).catch(err => {
                    console.log(err);
                }).finally(()=>{
                    this.loading = false;
                    this.showMore = true;
                });
            },

            onRemove(val) {
                this.dialogVisible = true;
                this.removeId = val;
            },
            remove() {
                let id = this.removeId;
                this.removeBtnDisabled = true;
                this.dialogVisible = false;
                setTimeout(() =>{
                    this.removeBtnDisabled = false;
                }, 300)
                _request({
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    url: this.urlDelete,
                    method: "POST",
                    data: {
                            id:id,
                            __RequestVerificationToken:this.verificationToken,
                            candidateId:this.candidateId
                        }
                }).then(res => {
                    this.refresh();
                }).catch(err => {
                    console.log(err);
                });
            },
            handleShowMore(){
                let start = 3,
                    take = this.total - 3;
                // let url = placeholder(`${candidateDetailConfig.url_search_record}?start=${start}&take=${take}`, this.resumeId);
                // const url = `${candidateDetailConfig.url_search_record}?start=${start}&take=${take}`.replace(/%p/, this.resumeId);
                const url = `${candidateDetailConfig.get_inquiryLogs}`;
                let params = {
                    start: start,
                    take: take,
                    candidateId: this.resumeId,
                    onlyMyInquiry: false,
                    isValidInquiry: this.isValidInquiry
                }
                this.loading = true;
                _request({
                    url: url,
                    // method: "GET"
                    method: "POST",
                    baseURL: "LbdOpenApi",
                    data: params,
                }).then(res => {
                    this.dataArray=this.dataArray.concat(res.list);
                    this.total = res.total;
                }).catch(err => {
                    console.log(err);
                }).finally(()=>{
                    this.loading = false;
                    this.showMore = false;
                });
            },
            handlePackUp(){
                this.dataArray.splice(3);
                this.showMore = true;
            }
        }
    }
</script>

<style lang="scss">
    @import "./candidate-search-record.scss";
    .candidate-search-record{
        .el-dialog__wrapper{
            .el-dialog__header{
                background-color: #38bc9c;
                .el-dialog__title{
                    color: #fff;
                }
            }
        }
    }

    .candidate-search-record-dropdown.el-dropdown {
        .el-dropdown-link {
            color: #38BC9D;
        }
    }

    .candidate-search-record-dropdown-menu.el-dropdown-menu {
        margin-top: 0;
        padding: 0;
        .popper__arrow {
            display: none;
        }
        .el-dropdown-menu__item.active {
            background-color: #ebf8f5;
            color: $primary;
        }
    }
</style>
<style lang="scss" scoped>
.record-hd {
    display: flex;
    .record-title {
        max-width: 250px;
        margin-right: 10px;
    }
    .record-hide-status {
        margin-top: 2px;
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        text-align: center;
        color: $primary;
        font-size: 22px;
        border: 2px solid $primary;
        border-radius: 4px;
        line-height: 28px;
        transform: scale(0.5);
        transform-origin: left top;
        &.private {
            border-color: #FA765D;
            color: #FA765D;
        }
    }
}
</style>