var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "candidate-recommendation-record" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.list.length == 0 && !_vm.loading,
            expression: "list.length == 0 && !loading",
          },
        ],
        staticClass: "empty-data",
      },
      [_c("span", { staticClass: "empty-img" }), _c("p", [_vm._v("暂无数据")])]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.list.length > 0,
                expression: "list.length > 0",
              },
            ],
            staticClass: "recommendation-list",
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              { key: index + item.id, staticClass: "recommend-hd" },
              [
                _c("div", { staticClass: "logo-container" }, [
                  _c("div", { staticClass: "logo" }, [
                    _c(
                      "div",
                      {
                        staticClass: "logo-hd",
                        class: { "img-loaded": item.employerLogoUrl },
                      },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.employerLogoUrl,
                              expression: "item.employerLogoUrl",
                            },
                          ],
                          staticClass: "logo-img",
                          attrs: {
                            alt: "职位",
                            src: _vm._f("logo")(item.employerLogoUrl),
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "info-and-tag-container" }, [
                  _c("div", { staticClass: "info-container" }, [
                    _c("div", { staticClass: "job-title" }, [
                      _c("h4", { staticClass: "recommend-job" }, [
                        _c("span", { staticClass: "company" }, [
                          _vm._v(_vm._s(_vm._f("square")(item.firmName))),
                        ]),
                        item.canAccess
                          ? _c("a", {
                              class: [
                                { tdn: item.isFromCloud },
                                "job-name-color",
                              ],
                              attrs: {
                                href: item.href || "javascript: void(0);",
                              },
                              domProps: { textContent: _vm._s(item.jobName) },
                              on: {
                                click: function ($event) {
                                  return _vm.handleRouterNavigate(item)
                                },
                              },
                            })
                          : _vm._e(),
                        !item.canAccess
                          ? _c("span", {
                              class: [{ tdn: item.isFromCloud }],
                              domProps: { textContent: _vm._s(item.jobName) },
                            })
                          : _vm._e(),
                        _c("span", { staticClass: "city" }, [
                          _vm._v(_vm._s(_vm._f("square")(item.location))),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "job-info" }, [
                      _c(
                        "span",
                        { staticClass: "salary" },
                        [
                          item.salary == "0K-0K"
                            ? [
                                _vm._v(
                                  "\n                                    月薪：面议\n                                "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm._f("tpl")(item.salary, "月薪：%p")
                                    ) +
                                    "\n                                "
                                ),
                              ],
                        ],
                        2
                      ),
                      _vm._v(
                        "\n                            佣金：\n                            "
                      ),
                      _c(
                        "span",
                        { staticClass: "commission" },
                        [
                          item.salary == "0K-0K"
                            ? [
                                _vm._v(
                                  "\n                                    按比例 " +
                                    _vm._s(
                                      _vm.filterCommisionValue(
                                        item.commisionValue
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(item.commisionValue) +
                                    "\n                                "
                                ),
                              ],
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "job-user" },
                      [
                        _c("avatar", {
                          attrs: {
                            src: _vm._f("avatar")(item.headhunterAvatarUrl),
                            userId: item.headhunterId,
                            alt: item.headhunterRealName,
                            size: "sm",
                            shareData: _vm.shareData,
                          },
                        }),
                        _c("span", { staticClass: "date" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("tpl")(
                                _vm._f("datetime")(item.recommendDate),
                                "%p 推荐"
                              )
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "tag-container" }, [
                    _c(
                      "div",
                      { staticClass: "job-panel" },
                      [
                        _c("recommendation-status-tag", {
                          attrs: {
                            interviewConfirmedStatus:
                              item.interviewConfirmedStatus,
                            href: item.recommendationUrl,
                            label: item.recommendStatus,
                            flowStepName: item.flowStepName,
                          },
                        }),
                        item.commentsCount
                          ? _c("span", { staticClass: "comment-count" }, [
                              _c("i", { staticClass: "fa fa-comment" }),
                              _c("span", [_vm._v(_vm._s(item.commentsCount))]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ]
    ),
    _vm.list.length > 0 && _vm.recommendationCount > _vm.list.length
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.bottomLoading,
                expression: "bottomLoading",
              },
            ],
            staticClass: "load-more",
            on: { click: _vm.handleLoadMore },
          },
          [
            _vm._v("\n        加载更多\n        "),
            _c("font-icon", { attrs: { href: "#icon-spread-up" } }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }