var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "attachment-item",
    },
    [
      _c(
        "div",
        {
          staticClass: "item-detail",
          class: _vm.isRecommend ? "item-detail-recommend" : "",
        },
        [
          _c("font-icon", { attrs: { href: "#icon-fujian" } }),
          !_vm.data.showBeforeAccept && !_vm.isRecommend
            ? _c(
                "span",
                {
                  staticClass: "secret",
                  attrs: { title: "接受简历后，HR才可以看到" },
                },
                [_vm._v("密")]
              )
            : _vm._e(),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.isRecommend ? "点击下载" : "下载并查看联系方式",
                placement: "bottom",
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "document-anchor",
                  attrs: { href: !_vm.canOperate ? _vm.url : "javascript:;;" },
                  on: { click: _vm.downloadFile },
                },
                [
                  _c("span", {
                    staticClass: "document-title",
                    domProps: { textContent: _vm._s(_vm.data.name) },
                  }),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm.canOperate && !_vm.isRecommend
        ? _c("ul", { ref: "menu", staticClass: "menu opr" }, [
            _vm.isShowSendOperation
              ? _c(
                  "li",
                  { staticClass: "attachment-send", on: { click: _vm.onSend } },
                  [_vm._v("\n            发送\n        ")]
                )
              : _vm._e(),
            _vm.isShowPrivacyOperation
              ? _c(
                  "li",
                  {
                    staticClass: "attachment-privacy",
                    class: { secret: _vm.data.showBeforeAccept },
                    on: { click: _vm.updatePrivacy },
                  },
                  [
                    !_vm.data.showBeforeAccept
                      ? [
                          _c(
                            "span",
                            { attrs: { title: "未接受简历时，HR也可以看到" } },
                            [_vm._v("公开")]
                          ),
                        ]
                      : _vm._e(),
                    _vm.data.showBeforeAccept
                      ? [
                          _c(
                            "span",
                            { attrs: { title: "接受简历后，HR才可以看到" } },
                            [_vm._v("私密")]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isFromAuthorization && _vm.data.isOwnered,
                    expression: "!isFromAuthorization && data.isOwnered",
                  },
                ],
                staticClass: "attachment-remove",
                on: { click: _vm.onRemove },
              },
              [_vm._v("\n            删除\n        ")]
            ),
          ])
        : _vm._e(),
      _vm.data.created
        ? _c("span", { staticClass: "upload-time" }, [
            _vm._v("上传时间：" + _vm._s(_vm.dataFilter(_vm.data.created))),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }