<template>
    <div class="candidate-recommendation-info">
        <candidate-recommendation-record
            v-show="viewType == 'list'"
            ref="candidateRecommendationRecord"
            @list-success="handleListSuccess"
            :recommendationId="myrecommendationId"
            :recommendation="recommendation"
            :shareData="shareData"
            @router-navigate="handleRouterNavigate"
        ></candidate-recommendation-record>

        <candidate-recommendation-detail
            v-show="viewType == 'detail'"
            ref="candidateRecommendationDetail"
            :recommendation="recommendation"
            :recommendationId="myrecommendationId"
            @candidate-search-record-refresh='handelRecordRefresh'
            :candidateId="candidateId"
            @router-navigate="handleRouterNavigate"
            :resume-id="resumeId"
            :shareData="shareData"
        ></candidate-recommendation-detail>
    </div>
</template>

<script>
    import candidateRecommendationRecord from '../component/candidateRecommendationRecord.vue';
    import candidateRecommendationDetail from '../component/candidateRecommendationDetail.vue';

    // TODO 如何减少提前变量的定义
    let defaultRecomendationKeys = ["jobId", "jobName", "firmName", "recommendStatus", "headhunterId", "headhunterAvatarUrl", "headhunterRealName", "headhunterNickname", "recommendDate", "salary", "commisionValue", "guaranteePeriod", "location", "isFromCloud", "orderAmount", "isCooperativeJob", "isCooperativeRecommendation", "recommendFirmLogoUrl", "employerLogoUrl", "isProjectManagerOfJob", "hasProjectManager", "recommendationUrl", "recruitingCount", "id", "candidateId", "realName", "recommendationCount", "recommendStatusStatistic"];
    let defaultRecomendationObj = {};
    defaultRecomendationKeys.forEach(key => {
        defaultRecomendationObj[key] = '';
    })
    defaultRecomendationObj.publisherInfo = {
        avatarUrl: '',
        firmId: '',
        firmShortName: '',
        id: '',
        mobile: '',
        name: '',
        nickname: '',
        postDate: '',
        recommendReason: '',
        title: ''
    }

    export default {
        components: {
            candidateRecommendationRecord,
            candidateRecommendationDetail
        },
        props: {
            recommendationId: String,
            candidateId: String,
            resumeId:String,
            shareData: Object
        },
        data() {
            return {
                viewType: '',
                recommendation: Object.assign({}, defaultRecomendationObj),
                myrecommendationId:this.recommendationId,
            }
        },
        mounted(){
            this.recordList = this.$refs.candidateRecommendationRecord;
            this.recommendationDetail = this.$refs.candidateRecommendationDetail;
        },
        methods: {
            handelRecordRefresh(){
                this.$emit('candidate-search-record-refresh');
            },
            handleListSuccess(val) {
                this.loadRecommendationFromRecordList();
            },
            updateArticle(vm){
                this.recommendationDetail.updateArticle(vm);
            },
            refresh(id){
                // 详情页
                if(id){
                    this.myrecommendationId = id;
                    this.loadRecommendationFromRecordList();
                    this.recommendationDetail.refresh(id);
                }
            },
            toggleState(type){
                this.viewType = type; //type: list, detail
            },
            loadRecommendationFromRecordList(){
                let recommendationinfo =  this.recordList.getRecommendationById(this.myrecommendationId);
                if(recommendationinfo){
                    Object.assign(this.recommendation, JSON.parse(JSON.stringify(recommendationinfo)));
                }
            },
            handleRouterNavigate(val){
                this.$emit('router-navigate',val);
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
