var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "candidate-search-record candidate-search-record-vue" },
    [
      _c("div", { staticClass: "record-top" }, [
        _c("h2", { staticClass: "title" }, [
          _vm._v(
            _vm._s(_vm.isFirm ? "寻访记录" : "我的寻访") +
              "（" +
              _vm._s(_vm.total) +
              "）"
          ),
        ]),
        _c(
          "div",
          { staticClass: "record-dropdown" },
          [
            _c(
              "el-dropdown",
              {
                staticClass: "candidate-search-record-dropdown",
                attrs: { trigger: "click" },
                on: {
                  command: (val) => _vm.handleRecordDropdown(val),
                  "visible-change": (val) => _vm.handleDropdownVisible(val),
                },
              },
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.recordByText) +
                      "\n                    "
                  ),
                  _c("i", {
                    staticClass: "el-icon--right",
                    class: {
                      "el-icon-arrow-down": !_vm.recordByDropdownVisible,
                      "el-icon-arrow-up": _vm.recordByDropdownVisible,
                    },
                  }),
                ]),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "candidate-search-record-dropdown-menu",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  _vm._l(_vm.recordByMap, function (item) {
                    return _c(
                      "el-dropdown-item",
                      {
                        key: item.value,
                        class: { active: _vm.recordByText == item.label },
                        attrs: { command: item.value },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.label) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "order",
          staticClass: "order-container",
        },
        [
          _c(
            "ul",
            [
              _vm._l(_vm.dataArray, function (item, index) {
                return _c(
                  "li",
                  { key: `orderItem_` + index, staticClass: "order-item" },
                  [
                    _c(
                      "div",
                      { staticClass: "record-hd" },
                      [
                        _c("avatar", {
                          attrs: {
                            enableCard: true,
                            enableLink: true,
                            shareData: _vm.shareData,
                            src: item.creatorAvatar,
                            userId: item.creatorId,
                          },
                        }),
                        _c("div", { staticClass: "record-title" }, [
                          _c("div", { staticClass: "record-info" }, [
                            _c("span", {
                              staticClass: "record-user",
                              domProps: {
                                textContent: _vm._s(item.creatorRealName),
                              },
                            }),
                            _c("span", { staticClass: "record-number" }, [
                              _vm._v(
                                _vm._s(
                                  item.creatorNickName
                                    ? `@${item.creatorNickName}`
                                    : ""
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "record-date" }, [
                            _vm._v(_vm._s(_vm._f("datetime")(item.updated))),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "record-hide-status",
                            class:
                              item.hideStatus !== undefined &&
                              item.hideStatus === 0
                                ? "private"
                                : "",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  item.hideStatus !== undefined &&
                                    item.hideStatus === 0
                                    ? "私"
                                    : "公"
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("p", {
                      staticClass: "record-reason",
                      domProps: { innerHTML: _vm._s(item.content) },
                    }),
                    item.audioRecordUrl
                      ? _c(
                          "div",
                          { staticClass: "audio-record" },
                          [
                            _c("record-listen", {
                              ref: "record-listen",
                              refInFor: true,
                              attrs: {
                                "audio-url": item.audioRecordUrl,
                                duration: item.audioDuration / 60,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.jobHoppingOccasion ||
                    item.employerInfo ||
                    item.expectSalary
                      ? _c("div", { staticClass: "inquire-info" }, [
                          _c("ul", [
                            item.jobHoppingOccasion
                              ? _c("li", [
                                  _vm._v(
                                    "跳槽时机：\n                            "
                                  ),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        item.jobHoppingOccasion
                                      ),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            item.employerInfo
                              ? _c("li", [
                                  _vm._v(
                                    "雇主画像：\n                            "
                                  ),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(item.employerInfo),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            item.expectSalary
                              ? _c("li", [
                                  _vm._v(
                                    "报酬期望：\n                            "
                                  ),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(item.expectSalary),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.userID === item.creatorId,
                            expression: "userID === item.creatorId",
                          },
                        ],
                        staticClass: "record-panel",
                      },
                      [
                        item.isValidInquiry
                          ? _c(
                              "a",
                              {
                                staticClass: "record-anchor",
                                attrs: { href: "javascript: void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onEdit(item)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-edit" }),
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      item.content ? "编辑" : "添加寻访"
                                    ),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "a",
                          {
                            staticClass: "record-anchor",
                            attrs: { href: "javascript: void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.onRemove(item.id)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-close" }),
                            _c("span", [_vm._v("删除")]),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              _vm.total == 0
                ? _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")])
                : _vm._e(),
              _c("div", { staticClass: "record-bottom" }, [
                _vm.total > 3 && _vm.showMore
                  ? _c(
                      "span",
                      {
                        staticClass: "record-show-more",
                        on: { click: _vm.handleShowMore },
                      },
                      [
                        _vm._v("加载更多"),
                        _c("i", { staticClass: "el-icon-d-arrow-right" }),
                      ]
                    )
                  : _vm._e(),
                _vm.total > 3 && !_vm.showMore
                  ? _c(
                      "span",
                      {
                        staticClass: "record-show-more",
                        on: { click: _vm.handlePackUp },
                      },
                      [_vm._v("收起")]
                    )
                  : _vm._e(),
              ]),
            ],
            2
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisible, width: "540px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("确认删除寻访记录？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.removeBtnDisabled },
                  on: { click: _vm.remove },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }