var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "candidateDetail candidate-detail-vue" },
    [
      _c("div", { staticClass: "site-main site-main-init" }, [
        _c(
          "div",
          { staticClass: "candidate-wrap ko-before candidate-then" },
          [
            _c("candidateBaseInfoStore", {
              ref: "candidateBaseInfoStore",
              attrs: {
                newCityExpectationsInfo: _vm.newCityExpectationsInfo,
                candidateDetailData: _vm.candidateDetailData,
                "candidate-id": _vm.candidateDetailData.id,
                "is-firm-resume": _vm.candidateDetailData.isFirm,
                "resume-id": _vm.candidateDetailData.tbdResumeId,
                "completion-percent": _vm.completionPercent,
                "existed-resume-id": _vm.existedResumeId,
                shareData: _vm.shareData,
              },
              on: {
                "get-candidate-detail": _vm.getCandidateDetail,
                "get-exist-id": _vm.getExistId,
                "handel-tab-refresh": _vm.handelTabRefresh,
                "import-confirm": _vm.handleImportConfirm,
                "candidate-unlock": _vm.handleCandidateUnlock,
                "candidate-detail-refresh": _vm.refreshdetail,
                "candidate-new-city-expectations-refresh":
                  _vm.getNewCityExpectations,
                "operationEvent-update-success":
                  _vm.operationEventUpdateSuccess,
                "candidate-search-record-refresh": _vm.RecordListRefresh,
              },
            }),
            _c("div", { staticClass: "candidate-content" }, [
              _c(
                "div",
                { staticClass: "candidate-detail-left" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "type-tab",
                      class: {
                        "has-unprocessed-item":
                          _vm.hasUnprocessedItem &&
                          _vm.candidateDetailData.isFirm,
                      },
                    },
                    [
                      _c("div", { staticClass: "tab visible" }, [
                        _c(
                          "ul",
                          { staticClass: "selection-list" },
                          _vm._l(
                            _vm.candidateDetailData.isFirm
                              ? _vm.nodeList
                              : _vm.personalNodeList,
                            function (item) {
                              return _c(
                                "li",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.candidateDetailData
                                          .isFromAuthorization ||
                                        (_vm.candidateDetailData
                                          .isFromAuthorization &&
                                          item.value != 4 &&
                                          item.value != 5),
                                      expression:
                                        "!candidateDetailData.isFromAuthorization || (candidateDetailData.isFromAuthorization && item.value != 4 && item.value != 5)",
                                    },
                                  ],
                                  key: item.value,
                                  staticClass: "selection-item visible",
                                  class: {
                                    active: item.value == _vm.activeValue,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.routernavigate(item)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "tab-wrapper" }, [
                                    _c("span", [_vm._v(_vm._s(item.text))]),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]
                  ),
                  _c("candidate-resume", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeValue == 1 || _vm.activeValue == 4,
                        expression: "activeValue == 1 || activeValue == 4",
                      },
                    ],
                    ref: "candidateResume",
                    attrs: {
                      activeValue: _vm.activeValue,
                      id: "candidate-resume",
                      shareData: _vm.shareData,
                      candidateDetailData: _vm.candidateDetailData,
                      "candidate-id": _vm.candidateDetailData.id,
                      "is-firm": _vm.candidateDetailData.isFirm,
                      "resume-id": _vm.candidateDetailData.tbdResumeId,
                    },
                    on: { "candidate-detail-refresh": _vm.refreshdetail },
                  }),
                  _c("candidate-communicaiton-list", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.activeValue == 2 &&
                          _vm.candidateDetailData.isFirm,
                        expression:
                          "activeValue == 2 && candidateDetailData.isFirm",
                      },
                    ],
                    ref: "candidateCommunicationList",
                    attrs: {
                      id: "candidate-communicaiton-list",
                      "candidate-id": _vm.candidateDetailData.id,
                    },
                  }),
                  _c("candidate-recommendation-info", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.activeValue == 3 &&
                          _vm.candidateDetailData.isFirm,
                        expression:
                          "activeValue == 3 && candidateDetailData.isFirm",
                      },
                    ],
                    ref: "candidateRecommendationInfo",
                    attrs: {
                      id: "candidate-recommendation-info",
                      "candidate-id": _vm.candidateDetailData.id,
                      "resume-id": _vm.candidateDetailData.tbdResumeId,
                      shareData: _vm.shareData,
                    },
                    on: {
                      "candidate-search-record-refresh": _vm.RecordListRefresh,
                      "router-navigate": _vm.routernavigate,
                    },
                  }),
                  _c("candidate-timeline", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeValue == 5,
                        expression: "activeValue == 5",
                      },
                    ],
                    ref: "candidateTimeline",
                    attrs: {
                      "candidate-real-name":
                        _vm.candidateDetailData &&
                        _vm.candidateDetailData.realName,
                      id: "candidate-timeline",
                      "candidate-id": _vm.candidateDetailData.id,
                      isFirm: _vm.candidateDetailData.isFirm,
                    },
                  }),
                  _c("candidate-attachments", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeValue == 6,
                        expression: "activeValue == 6",
                      },
                    ],
                    ref: "attachments",
                    attrs: {
                      "candidate-id": _vm.candidateDetailData.id,
                      "resume-id": _vm.candidateDetailData.tbdResumeId,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "candidate-detail-right" },
                [
                  _c("candidate-search-record", {
                    ref: "candidateSearchRecord",
                    attrs: {
                      id: "candidate-search-record",
                      "candidate-id": _vm.candidateDetailData.id,
                      isFirm: _vm.candidateDetailData.isFirm,
                      shareData: _vm.shareData,
                    },
                    on: { "inquire-edit": _vm.hadleInquireEdit },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowToTopButton,
              expression: "isShowToTopButton",
            },
          ],
          staticClass: "to-top-button",
          on: { click: _vm.pageToTop },
        },
        [
          _c("font-icon", {
            staticClass: "to-top-icon",
            attrs: { href: "#icon-toTop" },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.aliDialogVisible,
            width: "420px",
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.aliDialogVisible = $event
            },
          },
        },
        [
          _c("p", { staticClass: "ali-resume-dialog-text" }, [
            _vm._v("\n            该简历的当前公司可能是A厂，系统已删除。"),
            _c("br"),
            _vm._v("如有疑问请联系内控\n            "),
            _c("a", { attrs: { href: "mailto:neikong@careerfocus.com.cn" } }, [
              _vm._v("neikong@careerfocus.com.cn"),
            ]),
            _vm._v("。\n        "),
          ]),
        ]
      ),
      _c("footer-container"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }