<template>
    <div class="candidate-attachments">
        <div class="title">
            <el-upload ref="el-upload" 
                class="resume-upload" 
                :action="uploadUrl" 
                :data="token" 
                :headers="fileUploadHeaders"
                :show-file-list="false"
                :with-credentials="true"
                :on-success="handleUploadSuccess" 
                :on-error="handleUploadError" 
                :before-upload="handleBeforeUpload"
            >
                <span class="upload-content" v-loading="uploadLoading">
                    <i class="icon-font icon-upload"></i>
                    <span class="upload-content-title">上传内部附件</span>
                </span>
            </el-upload>
            <span class="upload-content-tip">注意：上传附件，不会更新简历。如要更新简历，请点击编辑按钮。</span>

            <el-alert
                title="仅支持上传pdf、doc、docx、txt格式的简历(不支持pdf格式的图片)"
                type="info"
                class="file-tip"
                show-icon
                close-text="知道了">
            </el-alert>
        </div>
        <div class="document-list card">
            <attachment-list 
                ref="attachmentList" 
                :is-from-authorization="isFromAuthorization" 
                :candidate-id="candidateId" 
                :resume-id="resumeId"
                @has-attachment="isHasAttachment">
            </attachment-list>
        </div>
    </div>
</template>

<script>
    import AttachmentList from './attachmentList.vue';
    import { candidate as candidateConfig } from '#/js/config/api.json';
    export default {
        components: {
            AttachmentList,
        },
        props: {
            candidateId:String,
            resumeId:String,
        },
        computed:{
            token(){
                return {__RequestVerificationToken:this.$store.state.verificationToken.verificationToken};
            },
            uploadUrl(){
                return _host.portal + candidateConfig.url_candidate_attachments_add.replace('%p', this.resumeId);
            },
            isFromAuthorization(){
                return this.$store.state.candidateDetail.candidateInfo.isFromAuthorization;
            }
        },
        data(){
            return{
                fileUploadHeaders: {
                    'RequestVerificationToken': this.$store.state.verificationToken.verificationToken,
                    "AuthorizationLBD": _getCookie('access_token'),
                },
                uploadLoading:false,
                hasAttachments:false,
            }
        },
        methods:{
            isHasAttachment(data){
                this.hasAttachments = data;
            },
            handleUploadSuccess(response, file, fileList){
                if(response.succeeded){
                    shortTips('上传附件成功！');
                    this.$refs.attachmentList.refresh();
                }else{
                    shortTips(response.message);
                }
                this.uploadLoading = false;
            },
            handleUploadError(err, file, fileList){
                shortTips('上传附件错误，请刷新页面重新尝试！');
                this.uploadLoading = false;
            },
            handleBeforeUpload(file){
                if (file.size / 1024 / 1024 > 2) {
                    shortTips('上传附件大小不能超过 2MB！');
                    return false;
                }

                let extArr = file.name.split(".");
                let ext = extArr[extArr.length -1];
                if(!["pdf", "doc", "docx", "txt"].includes(ext)) {
                    shortTips("文件类型不符合要求！");
                    return false;
                }
                this.uploadLoading = true;
            },
        },
    }
</script>

<style lang="scss">
.candidate-attachments{
    // background-color: #fff;
    // width: 830px;
    // border: 1px solid #ddd;
    border-top: none;
    padding: 20px 30px;
    min-height: 382px;
    .attachment-item{
        .secret{
            display: none;
        }
    }
    .title{
        margin-bottom: 10px;
        .empty-text{
            font-size: 12px;
            color: #666666;
            margin-right: 10px;
        }
        .resume-upload{
            display: inline-block;
            .el-upload__input{
                display: none;
            }
            .upload-content{
                font-size: 14px;
                color: #00BD9C;
                display: inline-block;
                height: 20px;
                line-height: 20px;
                .icon-upload{
                    font-size: 14px;
                    color: #00BD9C;
                }
                &-title{
                    padding-left: 5px;
                }
            }
        }
        .upload-content-tip{
            display: inline-block;
            line-height: 20px;
            margin-left: 12px;
            font-size: 12px;
            color: #999;
        }

        .file-tip{
            color: #67c23a;
            background: #f0f9eb;
            margin-top: 10px;
        }
    }
}
</style>
